import { useEffect } from 'react';
import { useB2cContainer } from '.';

interface RemoveAttributeProps {
  attribute: string;
  selector: string;
}

const useRemoveAttribute = ({ selector, attribute }: RemoveAttributeProps) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer || !selector || !attribute) return;

    const elements = b2cContainer.querySelectorAll(selector);

    elements.forEach((item) => item.removeAttribute(attribute));
  }, [b2cContainer]);
};

export default useRemoveAttribute;
