import {
  useAddServerErrorStyles,
  useFocusServerErrorScrollToTarget,
  useHelpLinkRemover,
  useMoveHeading,
  usePlaceHolderRemover,
  useRemoveAsteriskFromInputLabels,
  useSubmitButtonLoadingSpinner,
  useTrimLeadingTrailingSpaces,
} from '../../hooks';

const CommonActions = () => {
  useHelpLinkRemover();
  usePlaceHolderRemover();
  useSubmitButtonLoadingSpinner();
  useTrimLeadingTrailingSpaces();
  useMoveHeading();
  useAddServerErrorStyles();
  useFocusServerErrorScrollToTarget();
  useRemoveAsteriskFromInputLabels();

  return <></>;
};

export default CommonActions;
