import { Regex } from '../constants';

type PatternItem = {
  evaluate: (password: string) => boolean;
  control: {
    icon: HTMLElement | null;
    description: HTMLElement | null;
  };
};

type Pattern = {
  charLength: PatternItem;
  upperLowerCase: PatternItem;
  numberOrSpecial: PatternItem;
};

const usePassword = () => {
  const a11yMeetCriteriaSpan = 'Your password meets all necessary criterias.';
  const a11yDoesNotMeetCriteriaSpan =
    'Your password does not meet following criteria;';
  const a11ySpan = document.querySelector('.tfl-password-a11y');

  const Selector = {
    length: 'tfl-password-length',
    cases: 'tfl-password-case',
    numberOrSpecial: 'tfl-password-number-special-character',
  };

  const patterns: Pattern = {
    charLength: {
      evaluate: (value: string) => value.length >= 8,
      control: {
        icon: document.querySelector(`.${Selector.length}`),
        description: document.querySelector(`#${Selector.length}`),
      },
    },
    upperLowerCase: {
      evaluate: (value: string) => {
        return Regex.uppercase.test(value) && Regex.lowercase.test(value);
      },
      control: {
        icon: document.querySelector(`.${Selector.cases}`),
        description: document.querySelector(`#${Selector.cases}`),
      },
    },
    numberOrSpecial: {
      evaluate: (value: string) =>
        Regex.atLeastOneNumber.test(value) || Regex.special.test(value),
      control: {
        icon: document.querySelector(`.${Selector.numberOrSpecial}`),
        description: document.querySelector(`#${Selector.numberOrSpecial}`),
      },
    },
  };

  setInputAriaDescribedByDescription(patterns);

  let classes = {
    default: 'tfl-password-match-icon--default',
    success: 'tfl-password-match-icon--success',
  };

  const success = (classList: DOMTokenList | undefined) => (
    classList?.add(classes.success), classList?.remove(classes.default)
  );

  const reset = (classList: DOMTokenList | undefined): void => (
    classList?.add(classes.default), classList?.remove(classes.success)
  );

  const evaluate = ({ evaluate, control }: PatternItem, value: string) => ({
    result: evaluate(value),
    control,
  });

  const validatePasswordInputEventHandler = (
    target: HTMLInputElement
  ): boolean => {
    const value = target?.value;

    // if use clicks confirm button we set default icon class to ❌ in html5 data attribute
    classes = Object.assign(classes, {
      default: target?.dataset?.defaultIconClass,
    });

    // reset icons to default
    if (!value?.length) {
      Object.values(Selector)
        .map((selector) => document?.querySelector(`.${selector}`)?.classList)
        .forEach(reset);
    }

    // set ✅ / 🔘 / ❌ icons
    const validationDetail = Object.values(patterns)
      .map((pattern) => evaluate(pattern, value))
      .map(
        ({ result, control }) => (
          result
            ? success(control.icon?.classList)
            : reset(control.icon?.classList),
          { result, control }
        )
      );

    // set validation result
    const result =
      validationDetail.find(({ result }) => result === false) === undefined;

    if (!a11ySpan) return result;

    if (result) {
      target?.classList.add('tfl-text-input__input--success');
      updateInputAriaDescribedByDescription(a11yMeetCriteriaSpan);
      return result;
    }

    // password does not meet criteria
    target?.classList.remove('tfl-text-input__input--success');

    a11ySpan.setAttribute('aria-live', 'assertive');

    // Prepare a11y message from validationDetail
    const unmetCriterion = validationDetail
      .filter(({ result }) => !result)
      .map(({ control: { description } }) => description?.textContent)
      .join(' and ')
      .replace(/,([^,]*)$/, ' and $1');

    const validationMessage = `${a11yDoesNotMeetCriteriaSpan} Your password need to be, ${unmetCriterion}`;
    updateInputAriaDescribedByDescription(validationMessage);

    return result;
  };

  return { validate: validatePasswordInputEventHandler };
};
export default usePassword;

function setInputAriaDescribedByDescription(patterns: Pattern) {
  const inputAriaDescribedBySpan = document.querySelector(
    '.tfl-security-description-span'
  );

  const inputAriaDescribedByDescription =
    inputAriaDescribedBySpan?.textContent || '';

  const passcodeCriterionDescription = Object.values(patterns)
    .map(({ control: { description } }) => description?.textContent)
    .join(' and ')
    .replace(/,([^,]*)$/, ' and $1');

  const inputAriaDescribedByDescriptionWithPasscodeCriterion = `${inputAriaDescribedByDescription} Your password needs to be, ${passcodeCriterionDescription}`;

  if (inputAriaDescribedBySpan) {
    inputAriaDescribedBySpan.setAttribute(
      'aria-label',
      inputAriaDescribedByDescriptionWithPasscodeCriterion
    );
  }
}

function updateInputAriaDescribedByDescription(description: string) {
  const inputAriaDescribedBySpan = document.querySelector(
    '.tfl-security-description-span'
  );

  if (!inputAriaDescribedBySpan) return;

  inputAriaDescribedBySpan.setAttribute('aria-label', description);
}
