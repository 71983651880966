import { Route, Routes, useLocation } from 'react-router-dom';
import {
  AuthHeader,
  B2cCard,
  PasswordReset,
  QueryParamRouter,
  Signin,
  Signup,
} from './components';
import './assets/scss/App.scss';
import { CookieConsent, SkipLinks } from '@tfl/component-library';

function App() {
  const { pathname, search } = useLocation();
  const fullPath = `${pathname}${search}`;

  return (
    <>
      <SkipLinks skipToContentLink={`${fullPath}#api`} />
      <AuthHeader />
      <B2cCard />
      <Routes>
        <Route path="/*" element={<QueryParamRouter />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/passwordreset" element={<PasswordReset />} />
      </Routes>
      <CookieConsent cbid={process.env.REACT_APP_COOKIEBOT_ID ?? ''} />
    </>
  );
}

export default App;
