import { useEffect } from 'react';
import { useB2cContainer } from '.';

type Props = {
  selectors: string[];
};

const useRemoveElement = ({ selectors }: Props) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer || !selectors.length) return;

    const targetElements = selectors
      .map((selector) => Array.from(b2cContainer.querySelectorAll(selector)))
      .flat();
    targetElements.forEach((element) => {
      element.remove();
    });
  }, [b2cContainer]);
};

export default useRemoveElement;
