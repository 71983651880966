// https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver#mutation_observer_customize_resize_event_listener_demo
// https://hacks.mozilla.org/2012/05/dom-mutationobserver-reacting-to-dom-changes-without-killing-browser-performance/

// https://github.com/microsoft/TypeScript/issues/33128#issuecomment-526018445
// add missing types
declare global {
  interface Window {
    webkitMutationObserver: MutationObserverType;
    mozMutationObserver: MutationObserverType;
    msMutationObserver: MutationObserverType;
  }
}

interface MutationObserverType {
  new (callback: MutationCallback): MutationObserver;
  prototype: MutationObserver;
}

interface useMutationObserverProps {
  querySelector?: string;
  config?: MutationObserverInit;
  callback: (mutation: MutationRecord) => void;
}

export type DisconnectFunction = (() => void) | undefined;

const useMutationObserver = ({
  querySelector,
  config,
  callback,
}: useMutationObserverProps): MutationObserver | undefined => {
  const MutationObserver =
    window.MutationObserver ||
    window?.webkitMutationObserver ||
    window?.mozMutationObserver ||
    window?.msMutationObserver;

  if (!querySelector) return undefined;

  const targets = document.querySelectorAll(querySelector);

  // Abandon request as none of the element(s) exist in DOM
  if (!targets.length) {
    console.warn(
      `Did not add mutation observer as no element(s) with selector ${querySelector} found in DOM`
    );
    return undefined;
  }

  const defaultConfig: MutationObserverInit = {
    attributes: true,
    childList: false,
    characterData: false,
    subtree: false,
  };

  const newConfig = Object.assign(defaultConfig, config);

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      return newConfig[mutation.type] ? callback(mutation) : undefined;
    });
  });

  targets.forEach((target) => observer.observe(target, newConfig));

  return observer;
};

export default useMutationObserver;
