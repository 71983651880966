import { useEffect } from 'react';
import { useB2cContainer, useMutationObserver } from '.';

const useAddServerErrorStyles = (
  selector = '#claimVerificationServerError'
) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const serverErrorContainer = b2cContainer?.querySelector(
      selector
    ) as HTMLElement;

    if (!serverErrorContainer) return;

    serverErrorContainer?.classList.add(
      'tfl-alert',
      'tfl-alert--error',
      'tfl-server-error'
    );
  }, [b2cContainer]);

  // Remove full stop at the end of server error message
  useEffect(() => {
    if (!b2cContainer) return;

    const serverErrorContainer = b2cContainer?.querySelector(
      selector
    ) as HTMLElement;

    if (!serverErrorContainer) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const errorContainer = target as Element;

      if (attributeName !== 'aria-hidden') return;

      const showingError =
        errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (!showingError) return;

      if (!errorContainer) return;

      const originalErrorMessage = errorContainer.textContent;

      if (!originalErrorMessage) return;

      const fullStopIndex = originalErrorMessage.lastIndexOf('.');

      if (fullStopIndex === -1) return;

      const errorMessageWithoutFullStop = originalErrorMessage.substr(
        0,
        fullStopIndex
      );

      if (!errorMessageWithoutFullStop) return;

      errorContainer.textContent = errorMessageWithoutFullStop;
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: selector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);
};

export default useAddServerErrorStyles;
