import { useEffect } from 'react';
import { useB2cContainer } from '.';

type Props = {
  selectors: string[];
};

const useMoveErrorContainerBelowInput = ({ selectors }: Props) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    if (!selectors?.length) return;

    const errorContainers = selectors
      .map((selector) => Array.from(b2cContainer.querySelectorAll(selector)))
      .flat();

    errorContainers.forEach((container) => {
      const input = container?.parentNode?.querySelector('input');
      const select = container?.parentNode?.querySelector('select');

      const id = input ? `${input.id}` : select ? `${select.id}` : null;

      if (!id) return;

      container?.classList.add(id);
      container?.setAttribute(
        'data-summary-link',
        `.tfl-alert-summary__link.${id}`
      );
    });

    errorContainers.forEach((container) =>
      container?.parentNode?.appendChild(container)
    );
  }, [b2cContainer]);
};

export default useMoveErrorContainerBelowInput;
