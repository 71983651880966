import { useEffect } from 'react';
import { useB2cContainer } from '.';

const useStyleDropdown = () => {
  const [b2cContainer] = useB2cContainer();

  // Add tfl branded styles to dropdown/labels
  useEffect(() => {
    if (!b2cContainer) return;
    const dropdownContainer = Array.from(
      b2cContainer?.querySelectorAll('.DropdownSingleSelect')
    );

    dropdownContainer?.forEach((container) =>
      container?.classList.add('tfl-dropdown')
    );

    const labels = dropdownContainer?.map((container) =>
      container.querySelector('label')
    );
    labels?.forEach((container) =>
      container?.classList.add('tfl-dropdown__label')
    );

    const dropdown = dropdownContainer?.map((container) =>
      container.querySelector('.dropdown_single')
    );

    dropdown?.forEach((container) =>
      container?.classList.add('tfl-dropdown__container')
    );
  }, [b2cContainer]);
};

export default useStyleDropdown;
