import {
  useAddAlertSummary,
  useAddAlertSummaryMutationObserver,
  useAddressStyling,
  useButton,
  useEmptyParagraphRemover,
  useFocusErrorSummaryScrollToTarget,
  useHtml5ValidationRemover,
  useIntroText,
  useMoveButtons,
  useRemoveElement,
  useStyleDropdown,
  useStyleInputs,
} from '../../hooks';

const ProfileEditAddress = () => {
  useIntroText();
  useEmptyParagraphRemover();
  useHtml5ValidationRemover();
  useAddAlertSummary();
  useAddAlertSummaryMutationObserver();
  useStyleInputs();
  useStyleDropdown();
  useMoveButtons('#api > .buttons');
  useButton({
    selector: 'continue',
    type: 'button',
    variant: 'primary',
  });
  useButton({
    selector: 'cancel',
    type: 'button',
    variant: 'secondary',
  });
  useFocusErrorSummaryScrollToTarget({
    buttonSelectors: ['#continue'],
  });
  useRemoveElement({
    selectors: ['#address_SubHeaderText_AddressLookup_label'],
  });
  useRemoveElement({ selectors: ['#address_SubHeaderText_YourAddress_label'] });
  useAddressStyling();

  return <></>;
};

export default ProfileEditAddress;
