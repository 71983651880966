import { useEffect } from 'react';
import {
  ConfirmUpdateContactMobileNumber,
  MfaChallenge,
  MfaSetup,
  ProfileEditConfirmSecurityQuestion,
  ProfileEditPasscode,
  ProfileEditPassword,
  ProfileEditUpdateSecurityQuestion,
  ProfileEditPersonalDetails,
  ProfileEditVerifyEmailAddress,
  ProfileEditMarketingPreferences,
  ProfileEditAddress,
  Signin,
} from '..';
import {
  useB2cContainer,
  useHasMfaChallengeStep,
  useHasMfaSetupStep,
  useHasProfileEditPasswordStep,
  useHasPasscodeStep,
  useHasSignInStep,
  useHasProfileEditConfirmSecurityQuestionStep,
  useHasProfileEditUpdateSecurityQuestionStep,
  useHasProfileEditPersonalDetailsStep,
  useHasProfileEditVerifyEmailAddressStep,
  useHasProfileEditMarketingPreferencesStep,
  useHasAddressStep,
  useUpdatePageTitle,
  useHasConfirmUpdateContactMobileNumber,
} from '../../hooks';

const ProfileEdit = () => {
  const [b2cContainer] = useB2cContainer();
  const [hasSignInStep] = useHasSignInStep();
  const [hasProfileEditPasswordStep] = useHasProfileEditPasswordStep();
  const [hasProfileEditPasscodeStep] = useHasPasscodeStep();
  const [hasProfileEditConfirmSecurityQuestionStep] =
    useHasProfileEditConfirmSecurityQuestionStep();
  const [hasProfileEditUpdateSecurityQuestionStep] =
    useHasProfileEditUpdateSecurityQuestionStep();
  const { hasMfaSetupStep } = useHasMfaSetupStep();
  const [hasMfaChallengeStep] = useHasMfaChallengeStep();
  const [hasConfirmUpdateOtherContactNumber] =
    useHasConfirmUpdateContactMobileNumber();
  const [hasProfileEditPersonalDetailsStep] =
    useHasProfileEditPersonalDetailsStep();
  const [hasProfileEditVerifyEmailAddressStep] =
    useHasProfileEditVerifyEmailAddressStep();
  const [hasProfileEditMarketingPreferencesStep] =
    useHasProfileEditMarketingPreferencesStep();
  const [hasAddressStep] = useHasAddressStep();

  // Move inside react container
  useEffect(() => {
    if (!b2cContainer) return;

    const container = document.getElementById('b2c-react-container');
    if (!container) return;

    container.appendChild(b2cContainer);

    b2cContainer.classList.remove('visuallyhidden');
  }, [b2cContainer]);

  useUpdatePageTitle('.heading h1');

  return (
    <>
      {hasSignInStep ? <Signin /> : null}
      {hasMfaSetupStep ? <MfaSetup /> : null}
      {hasMfaChallengeStep ? <MfaChallenge /> : null}
      {hasProfileEditPasscodeStep ? <ProfileEditPasscode /> : null}
      {hasProfileEditPasswordStep ? <ProfileEditPassword /> : null}
      {hasProfileEditConfirmSecurityQuestionStep ? (
        <ProfileEditConfirmSecurityQuestion />
      ) : null}
      {hasProfileEditUpdateSecurityQuestionStep ? (
        <ProfileEditUpdateSecurityQuestion />
      ) : null}
      {hasProfileEditPersonalDetailsStep ? (
        <ProfileEditPersonalDetails />
      ) : null}
      {hasProfileEditVerifyEmailAddressStep ? (
        <ProfileEditVerifyEmailAddress />
      ) : null}
      {hasProfileEditMarketingPreferencesStep ? (
        <ProfileEditMarketingPreferences />
      ) : null}
      {hasAddressStep ? <ProfileEditAddress /> : null}
      {hasConfirmUpdateOtherContactNumber ? (
        <ConfirmUpdateContactMobileNumber />
      ) : null}
    </>
  );
};

export default ProfileEdit;
