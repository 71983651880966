import { useEffect } from 'react';
import { useB2cContainer } from '.';

const useRemoveChangeButton = ({ selector }: { selector: string }) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const changeEmailAddressButton = document.querySelector(selector);

    changeEmailAddressButton?.remove();
  }, [b2cContainer]);
};

export default useRemoveChangeButton;
