import { useContext } from 'react';
import { useEffect, useRef, useState } from 'react';
import { CustomClaimsContext } from '../../context/CustomClaimsContext';
import {
  useAddAlertSummary,
  useAddAlertSummaryMutationObserver,
  useB2cContainer,
  useButton,
  useEnterKeydownHandler,
  useFocusErrorSummaryScrollToTarget,
  useHasProfileEditPersonalDetailsStep,
  useHideContinueButton,
  useIntroText,
  useMoveButtons,
  useMutationObserver,
  usePolicy,
  useRemoveAttribute,
  useStyleDropdown,
  useStyleInputs,
  useWatchStyleDisplayProperty,
} from '../../hooks';
import { ButtonProps } from '../../hooks/useButton';
import './MfaChallenge.scss';
import { Policies } from '../policies';
import { ProfileEditPersonalDetails } from '../ProfileEditPersonalDetails';

const MfaChallenge = () => {
  const [b2cContainer] = useB2cContainer();
  const policy = usePolicy();
  const [loadPage, setLoadPage] = useState(false);
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState<boolean>();
  const [appendLastFourDigits, setAppendLastFourDigits] = useState<boolean>();
  const [mobileNumberHasError, setMobileNumberHasError] = useState<boolean>();
  const [showChangeMobileNumberLink, setShowChangeMobileNumberLink] =
    useState(true);
  const [showKeepMobileNumberLink, setShowKeepMobileNumberLink] =
    useState(false);
  const [codeVerified, setCodeVerified] = useState(false);
  const refCodeVerified = useRef(codeVerified);
  const { customClaims = {} } = useContext(CustomClaimsContext);
  const [hasProfileEditPersonalDetailsStep] =
    useHasProfileEditPersonalDetailsStep();

  useIntroText('.Paragraph .attrEntry #mfaChallengeHeaderText');

  useMoveButtons();

  useStyleDropdown();

  // Set focus to error summary on form submission failure
  useFocusErrorSummaryScrollToTarget({
    buttonSelectors: [
      '#mfaChallengeVerificationControl_but_verify_code',
      '#mfaChallengeVerificationControl_but_send_code',
    ],
  });

  // Remove style attribute where its set to `inline`
  useRemoveAttribute({
    attribute: 'style',
    selector: 'li[style="display: inline;"]',
  });

  // Undo `display: inline` property on enter code, contry code and mobile number fields
  useWatchStyleDisplayProperty({
    selectors: [
      '#mfaChallengeVerificationControl .VerificationCode',
      '#mfaChallengeVerificationControl .DropdownSingleSelect',
      '#mfaChallengeVerificationControl .mfaChallengeContactUsParagraphText',
      '#mfaChallengeVerificationControl .readOnlyStrongAuthenticationPhoneNumber',
    ],
  });

  useStyleInputs({
    otherInputContainerSelector:
      '.VerificationControl .readOnlyStrongAuthenticationPhoneNumber .attrEntry',
  });

  // Add send, verify, resend code & change mobile number button styles
  (
    [
      { selector: '.buttons #continue', type: 'button', variant: 'primary' },
      { selector: '.buttons #cancel', type: 'button', variant: 'secondary' },
      { selector: '.buttons .sendCode', type: 'button', variant: 'primary' },
      { selector: '.buttons .verifyCode', type: 'button', variant: 'primary' },
      {
        selector: '.buttons .changeClaims',
        type: 'button',
        variant: 'primary',
      },
      {
        selector: '.buttons .sendNewCode',
        type: 'button',
        variant: 'secondary',
      },
    ] as ButtonProps[]
  ).forEach((button) => useButton(button));

  useHideContinueButton({
    verificationCodeButtonSelector:
      '#mfaChallengeVerificationControl_but_send_code',
  });

  // Add selector mfa specific styles
  useEffect(() => {
    if (!b2cContainer) return;

    b2cContainer.classList.add('tfl-mfa-challenge');
  }, [b2cContainer]);

  // Move verify code success message container to appropriate location
  useEffect(() => {
    if (!b2cContainer) return;

    const verificationSuccessTextContainer = b2cContainer?.querySelector(
      '.verificationSuccessText'
    ) as HTMLDivElement;

    const verificationInfoTextContainer = b2cContainer?.querySelector(
      '.verificationInfoText'
    ) as HTMLDivElement;

    verificationInfoTextContainer?.after(verificationSuccessTextContainer);
  }, [b2cContainer]);

  // Set alternative header text
  useEffect(() => {
    if (!b2cContainer) return;

    const introHeading = b2cContainer.querySelector('.heading h1');

    const alternativeIntroHeading =
      b2cContainer.querySelector('#mfaChallengeAlternativeHeaderText')
        ?.textContent || '';

    if (!alternativeIntroHeading) return;

    introHeading?.setAttribute(
      'data-verify-number-text',
      alternativeIntroHeading
    );
  }, [b2cContainer]);

  // Move inline error containers below the input
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainers = b2cContainer.querySelectorAll(
      '#mfaChallengeVerificationControl ul .attrEntry .error.itemLevel'
    );

    errorContainers.forEach((container) => {
      const input = container.parentNode?.querySelector('input');

      container.classList.add(`${input?.id}`);
      container.setAttribute(
        'data-summary-link',
        `.tfl-alert-summary__link.${input?.id}`
      );
    });

    errorContainers.forEach((container) =>
      container.parentNode?.appendChild(container)
    );
  }, [b2cContainer]);

  // Remove/hide elements causing style issues/add default styles/not required on page load
  useEffect(() => {
    if (!b2cContainer) return;

    [
      '.TextBox.signInName',
      '#mfaChallengeAlternativeFirstParagraphText_label',
      '#mfaChallengeAlternativeHeaderText_label',
      '#mfaChallengeFirstParagraphText_label',
      '#mfaChallengeThirdParagraphText_label',
      '#mfaChallengeFourthParagraphText_label',
      '#mfaChallengeVerificationControl_label',
      '#mfaChallengeMustCompleteAllFieldsText_label',
      '#mfaChallengeContactUsParagraphText_label',
      '#mfaChallengeChangeYourNumberHeaderText_label',
    ].forEach((selector) => b2cContainer.querySelector(selector)?.remove());

    [
      '#mfaChallengeAlternativeFirstParagraphText',
      '#mfaChallengeAlternativeHeaderText',
      '#mfaChallengeChangeNumberCheckbox_Yes',
      '#mfaChallengeContactUsParagraphText',
      '#mfaChallengeFirstParagraphText',
      '#mfaChallengeThirdParagraphText',
      '#mfaChallengeFourthParagraphText',
      '#mfaChallengeMustCompleteAllFieldsText',
      '#mfaChallengeChangeYourNumberHeaderText',
      '#countryCode',
    ].forEach((selector) => {
      const container = b2cContainer.querySelector(selector)?.closest('li');

      container
        ? ((container.style.display = 'none'),
          container?.closest('li')?.classList.add('visuallyhidden'))
        : null;
    });

    b2cContainer
      .querySelector('#mfaChallengeSecondParagraphText')
      ?.closest('li')
      ?.remove();

    b2cContainer
      .querySelector('#mfaChallengeVerificationControl_but_change_claims')
      ?.classList.add('visuallyhidden');

    // Render links inside contact us paragraph
    const contactUsParagraph = b2cContainer.querySelector(
      '#mfaChallengeContactUsParagraphText'
    );

    if (!contactUsParagraph) return;

    // render markup inside the text node by setting innerHTML
    // then extract the text node again and set plain text on aria-label
    contactUsParagraph.innerHTML = contactUsParagraph.textContent || '';
    contactUsParagraph.setAttribute(
      'aria-label',
      contactUsParagraph.textContent || ''
    );
  }, [b2cContainer]);

  // Show/hide masked mobile number field
  useEffect(() => {
    if (!b2cContainer) return;

    const maskedPhoneNumberInputContainer = b2cContainer.querySelector(
      '.VerificationControl .readOnlyStrongAuthenticationPhoneNumber'
    ) as HTMLLIElement;

    if (!maskedPhoneNumberInputContainer) return;

    showPhoneNumberInput
      ? (maskedPhoneNumberInputContainer.classList.remove('visuallyhidden'),
        (maskedPhoneNumberInputContainer.style.display = 'block'))
      : (maskedPhoneNumberInputContainer.classList.add('visuallyhidden'),
        (maskedPhoneNumberInputContainer.style.display = 'none'));
  }, [b2cContainer, showPhoneNumberInput]);

  // Hide masked mobile number input on send code button click
  useEffect(() => {
    if (!b2cContainer) return;

    const sendCodeButton = b2cContainer.querySelector(
      '#mfaChallengeVerificationControl_but_send_code'
    );

    if (!sendCodeButton) return;

    const handler = () => {
      setShowPhoneNumberInput(mobileNumberHasError);
      setAppendLastFourDigits(!mobileNumberHasError);
    };

    sendCodeButton.addEventListener('click', handler);

    return () => sendCodeButton.removeEventListener('click', handler);
  }, [b2cContainer, mobileNumberHasError, setShowPhoneNumberInput]);

  // Append phone number last four digits to first paragraph
  useEffect(() => {
    if (!b2cContainer) return;

    if (appendLastFourDigits === undefined) return;

    const verificationSuccessMessage = b2cContainer.querySelector(
      '#mfaChallengeVerificationControl_success_message'
    );

    if (!verificationSuccessMessage) return;

    const maskedPhoneNumberInput = b2cContainer.querySelector(
      '#readOnlyStrongAuthenticationPhoneNumber'
    ) as HTMLInputElement;

    if (!maskedPhoneNumberInput) return;

    const lastFourDigitsSpan =
      (b2cContainer.querySelector(
        '.tfl-phone-last-four-digits'
      ) as HTMLSpanElement) || document.createElement('span');

    const lastFourDigitsPrefixSpan =
      (b2cContainer.querySelector(
        '.tfl-phone-last-four-prefix'
      ) as HTMLSpanElement) || document.createElement('span');

    lastFourDigitsPrefixSpan.classList.add('tfl-phone-last-four-prefix');
    lastFourDigitsPrefixSpan.innerHTML = ` ending`;

    if (!appendLastFourDigits) {
      lastFourDigitsSpan.innerHTML = '';
      lastFourDigitsPrefixSpan.innerHTML = '';
      return;
    }

    const lastFourDigits = maskedPhoneNumberInput.value
      .split('-')
      .pop()
      ?.substr(-4, 4);

    if (!lastFourDigits) return;
    lastFourDigitsSpan.classList.add('tfl-phone-last-four-digits');
    lastFourDigitsSpan.innerHTML = ` ${lastFourDigits}`;

    const successMessage =
      verificationSuccessMessage.firstChild?.nodeValue || '';

    if (!successMessage) return;

    verificationSuccessMessage.textContent =
      successMessage?.replace('.', '') ?? '';
    verificationSuccessMessage.appendChild(lastFourDigitsPrefixSpan);
    verificationSuccessMessage.appendChild(lastFourDigitsSpan);
    verificationSuccessMessage.append('.');

    setAppendLastFourDigits(() => undefined);
  }, [b2cContainer, appendLastFourDigits, setAppendLastFourDigits]);

  // Append last four number digits to success message
  useEffect(() => {
    if (!b2cContainer) return;

    const successMessageContainerSelector = `#mfaChallengeVerificationControl_success_message`;

    const successMessageContainer = b2cContainer.querySelector(
      `#mfaChallengeVerificationControl_success_message`
    ) as HTMLDivElement;

    if (!successMessageContainer) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const otpCodeSuccessMessageContainer = target as HTMLElement;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const otpCodeSuccessMessageShowing =
        otpCodeSuccessMessageContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const hasOtpCodeVerifiedSuccessMessage = [
        'The code has been verified',
        'Your code has been verified',
      ].some((message) =>
        otpCodeSuccessMessageContainer?.textContent?.includes(message)
      );

      const result =
        otpCodeSuccessMessageShowing && !hasOtpCodeVerifiedSuccessMessage;

      setAppendLastFourDigits(() => result);
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: successMessageContainerSelector,
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer, setAppendLastFourDigits]);

  // Add change/keep your mobile number link
  useEffect(() => {
    if (!b2cContainer) return;

    const changeNumberLinkTextPlaceholder = b2cContainer?.querySelector(
      '#mfaChallengeThirdParagraphText'
    ) as HTMLParagraphElement;

    const keepNumberLinkTextPlaceholder = b2cContainer?.querySelector(
      '#mfaChallengeFourthParagraphText'
    ) as HTMLParagraphElement;

    const alternativeFirstParagraphContainer = b2cContainer
      ?.querySelector('#mfaChallengeAlternativeFirstParagraphText')
      ?.closest('li') as HTMLLIElement;

    const contactUsParagraphContainer = b2cContainer
      ?.querySelector('#mfaChallengeContactUsParagraphText')
      ?.closest('li') as HTMLLIElement;

    const continueButton = b2cContainer?.querySelector(
      '#continue'
    ) as HTMLElement;

    const changeMobileNumberCheckbox = b2cContainer.querySelector(
      '#mfaChallengeChangeNumberCheckbox_Yes'
    ) as HTMLInputElement;

    if (!b2cContainer.querySelector('.tfl-change-mobile-number')) {
      const changeNumberLink = document.createElement('a');
      changeNumberLink.href = '#';
      changeNumberLink.classList.add('tfl-change-mobile-number');
      changeNumberLink.text =
        changeNumberLinkTextPlaceholder?.textContent?.trim() || '';

      changeNumberLink.addEventListener('click', (event: Event) => {
        event.preventDefault();
        event.stopPropagation();

        [
          alternativeFirstParagraphContainer,
          contactUsParagraphContainer,
        ].forEach((container) => {
          container.style.display = !refCodeVerified.current ? 'block' : 'none';
          refCodeVerified.current
            ? container.classList.add('visuallyhidden')
            : container.classList.remove('visuallyhidden');
        });

        if (refCodeVerified.current) {
          if (!continueButton) return;
          changeMobileNumberCheckbox.checked = true;
          continueButton.dispatchEvent(new Event('click'));
        }

        setShowChangeMobileNumberLink(() => false);
        setShowKeepMobileNumberLink(() => !refCodeVerified.current);
      });

      const container = document.createElement('div');
      container.append(changeNumberLink);
      container.classList.add('tfl-change-mobile-number-list-item');
      container.setAttribute('aria-hidden', 'false');

      const targetContainer = b2cContainer?.querySelector(
        '#mfaChallengeVerificationControl ul'
      ) as HTMLUListElement;

      if (!targetContainer) return;

      targetContainer.append(container);
    }

    if (!b2cContainer.querySelector('.tfl-keep-mobile-number')) {
      const keepNumberLink = document.createElement('a');
      keepNumberLink.href = '#';
      keepNumberLink.classList.add('tfl-keep-mobile-number');
      keepNumberLink.text =
        keepNumberLinkTextPlaceholder?.textContent?.trim() || '';

      keepNumberLink.addEventListener('click', (event: Event) => {
        event.preventDefault();
        event.stopPropagation();

        if (refCodeVerified.current) {
          changeMobileNumberCheckbox.checked = false;
          continueButton.dispatchEvent(new Event('click'));
          return;
        }

        [
          alternativeFirstParagraphContainer,
          contactUsParagraphContainer,
        ].forEach((container) => {
          container.style.display = 'none';
          container.classList.add('visuallyhidden');
        });

        setShowKeepMobileNumberLink(() => false);
        setShowChangeMobileNumberLink(() => true);
      });

      const container = document.createElement('div');
      container.append(keepNumberLink);
      container.classList.add('tfl-keep-mobile-number-list-item');
      container.setAttribute('aria-hidden', 'true');

      const targetContainer = b2cContainer?.querySelector(
        '#mfaChallengeVerificationControl ul'
      ) as HTMLUListElement;

      if (!targetContainer) return;

      targetContainer.append(container);
    }
  }, [
    b2cContainer,
    setShowChangeMobileNumberLink,
    setShowKeepMobileNumberLink,
  ]);

  // Show/hide change/keep mobile number links and set change mobile number checkbox
  useEffect(() => {
    if (!b2cContainer) return;

    const changeNumberContainer = b2cContainer.querySelector(
      '.tfl-change-mobile-number-list-item'
    ) as HTMLDivElement;

    const keepNumberContainer = b2cContainer.querySelector(
      '.tfl-keep-mobile-number-list-item'
    ) as HTMLDivElement;

    const changeMobileNumberCheckbox = b2cContainer.querySelector(
      '#mfaChallengeChangeNumberCheckbox_Yes'
    ) as HTMLInputElement;

    if (changeNumberContainer) {
      changeNumberContainer.style.display = showChangeMobileNumberLink
        ? 'block'
        : 'none';
      changeNumberContainer.setAttribute(
        'aria-hidden',
        `${!showChangeMobileNumberLink}`
      );
    }

    if (keepNumberContainer) {
      keepNumberContainer.style.display = showKeepMobileNumberLink
        ? 'block'
        : 'none';
      keepNumberContainer.setAttribute(
        'aria-hidden',
        `${!showKeepMobileNumberLink}`
      );
    }

    if (changeMobileNumberCheckbox) {
      changeMobileNumberCheckbox.checked = !showChangeMobileNumberLink;
    }

    const introHeadingText = b2cContainer.querySelector(
      '.heading h1'
    ) as HTMLHeadingElement;

    const headingText = getHeadingTextContent();

    introHeadingText.textContent = headingText;

    document.title = [
      headingText,
      process.env.REACT_APP_PAGE_TITLE_POSTFIX,
    ].join(' - ');

    function getHeadingTextContent() {
      const optionOne =
        !showChangeMobileNumberLink && !showKeepMobileNumberLink
          ? introHeadingText.getAttribute('data-original-text')
          : null;

      const optionTwo = introHeadingText.getAttribute(
        showChangeMobileNumberLink
          ? 'data-original-text'
          : 'data-verify-number-text'
      );

      return optionOne || optionTwo;
    }
  }, [b2cContainer, showChangeMobileNumberLink, showKeepMobileNumberLink]);

  // Once the code has been verified
  useEffect(() => {
    if (!b2cContainer) return;

    if (!codeVerified) return;

    refCodeVerified.current = codeVerified;
  }, [b2cContainer, codeVerified]);

  // Handle enter key press on verify code input field when performing Mfa Challenge
  useEnterKeydownHandler({
    inputFieldSelector: '#mfaVerificationCode',
    targetButtonSelector: '#mfaChallengeVerificationControl_but_verify_code',
  });

  // Mutation observers
  // Add mutation observer, on successful code verification, to
  // - show continue button, code input & `change mobile number` link and success icon ✅
  // - hide change button
  useEffect(() => {
    const otpCodeSuccessMessageContainerSelector =
      '#mfaChallengeVerificationControl_success_message';
    const verifyCodeButtonSelector =
      '#mfaChallengeVerificationControl_but_verify_code';

    const verifyCodeButton = b2cContainer?.querySelector(
      verifyCodeButtonSelector
    ) as HTMLElement;

    const mfaVerificationCodeContainer = b2cContainer?.querySelector(
      '.TextBox.VerificationCode'
    ) as HTMLElement;

    const continueButton = b2cContainer?.querySelector(
      '#continue'
    ) as HTMLElement;

    const mfaVerificationCodeInputContainer = b2cContainer
      ?.querySelector('#mfaVerificationCode')
      ?.closest('li') as HTMLLIElement;

    const verificationSuccessTextContainer = b2cContainer?.querySelector(
      '.verificationSuccessText'
    ) as HTMLDivElement;

    if (!mfaVerificationCodeContainer || !verifyCodeButton) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const otpCodeSuccessMessageContainer = target as HTMLElement;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const otpCodeSuccessMessageShowing =
        otpCodeSuccessMessageContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const hasOtpCodeSuccessMessage =
        otpCodeSuccessMessageContainer?.textContent?.includes(
          'Your code has been verified'
        );

      const mfaVerificationCodeInput = b2cContainer?.querySelector(
        '#mfaVerificationCode'
      );

      if (otpCodeSuccessMessageShowing && hasOtpCodeSuccessMessage) {
        mfaVerificationCodeInput?.classList.remove(
          'tfl-text-input__input--loading'
        );
        mfaVerificationCodeContainer.style.display = 'block';
        mfaVerificationCodeContainer.setAttribute('aria-hidden', 'false');

        mfaVerificationCodeInput?.classList.add(
          'tfl-text-input__input--success'
        );

        // Code verified so move success message before enter code input container
        mfaVerificationCodeInputContainer.before(
          verificationSuccessTextContainer
        );

        // If keep number link is being displayed,
        // then must have clicked on change number link on previous screen
        if (otpCodeSuccessMessageShowing) {
          continueButton.setAttribute('aria-hidden', 'false');
          continueButton.removeAttribute('disabled');
          continueButton.style.display = 'block';
          continueButton.classList.remove('visuallyhidden');
          continueButton.focus();
        }

        setCodeVerified(() => true);

        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otpCodeSuccessMessageContainerSelector,
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Leverage out the box loading indicator ui element provided by the policy
  useEffect(() => {
    if (!b2cContainer) return;

    const loadingContainerSelector = '.working';
    const verificationCodeInput = b2cContainer.querySelector(
      '#mfaVerificationCode'
    );

    const successMessageContainer = b2cContainer.querySelector(
      `#mfaChallengeVerificationControl_success_message`
    ) as HTMLElement;

    if (!successMessageContainer) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const loadingContainer = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const busy =
        loadingContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (!busy) {
        verificationCodeInput?.classList.remove(
          'tfl-text-input__input--loading'
        );
        successMessageContainer.style.display = 'block';
        successMessageContainer.setAttribute('aria-hidden', 'false');
        return;
      }

      verificationCodeInput?.classList.add('tfl-text-input__input--loading');
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: loadingContainerSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Copy other verify code error message to inline error container
  useEffect(() => {
    if (!b2cContainer) return;

    const otherErrorMessageContainerSelector =
      '#mfaChallengeVerificationControl_error_message';
    const otherErrorMessageContainer = b2cContainer.querySelector(
      otherErrorMessageContainerSelector
    );

    if (!otherErrorMessageContainer) return;

    // We can't remove it from DOM so just hide it
    otherErrorMessageContainer?.classList.add('visuallyhidden');

    const verificationCodeContainer = b2cContainer.querySelector(
      '.TextBox.VerificationCode'
    );
    const verificationCodeInlineErrorContainer =
      verificationCodeContainer?.querySelector('.error.itemLevel');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const otherErrorMessageContainer = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const otherErrorMessageContainerShowing =
        otherErrorMessageContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const textContent = otherErrorMessageContainer?.textContent;

      const otherErrorMessageContainerHasTextContent = !!textContent?.length;

      if (
        !otherErrorMessageContainerShowing ||
        !otherErrorMessageContainerHasTextContent
      )
        return;

      const verificationCodeContainerShowing =
        verificationCodeContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      if (
        !verificationCodeContainerShowing ||
        !verificationCodeInlineErrorContainer
      )
        return;

      verificationCodeInlineErrorContainer.textContent = textContent;
      verificationCodeInlineErrorContainer.setAttribute('aria-hidden', 'false');
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otherErrorMessageContainerSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Skip to Mfa reset/change mobile number screen
  useEffect(() => {
    if (!b2cContainer) return;

    const changeNumberLink = b2cContainer.querySelector(
      '.tfl-change-mobile-number'
    ) as HTMLLinkElement;

    if (!changeNumberLink) return;

    const updateMfaNumber =
      customClaims && customClaims['idTokenHint_SkipToResetMfa'] === 'Yes';

    const hasSessionStorageSkipToResetMfaHint =
      sessionStorage.getItem('skipToResetMfa') === 'true';

    const postContactNumberUpdateSkipToResetMfa =
      policy === Policies.ProfileEdit && hasSessionStorageSkipToResetMfaHint;

    const skipToResetMfaNumber =
      updateMfaNumber || postContactNumberUpdateSkipToResetMfa;

    if (!skipToResetMfaNumber) return;

    sessionStorage.removeItem('skipToResetMfa');
    changeNumberLink.dispatchEvent(new Event('click'));
  }, [b2cContainer, policy, customClaims]);

  // Show/Hide Mfa cancel button if found in id token hint
  useEffect(() => {
    if (!b2cContainer) return;

    const cancelButton = b2cContainer.querySelector(
      '#cancel'
    ) as HTMLButtonElement;

    if (!cancelButton) return;

    const parentContainer = cancelButton.parentElement;

    parentContainer?.append(cancelButton);

    const allowCancelMfa =
      customClaims && customClaims['idTokenHint_AllowCancelMfa'] === 'Yes';

    if (allowCancelMfa) return;

    cancelButton.classList.add('visuallyhidden');
    cancelButton.style.display = 'none';
  }, [b2cContainer]);

  // Send text on page load
  useEffect(() => {
    if (!b2cContainer) return;

    const sendCodeButton = b2cContainer.querySelector(
      '#mfaChallengeVerificationControl_but_send_code'
    );

    if (!sendCodeButton) return;

    sendCodeButton.dispatchEvent(new Event('click'));

    setShowPhoneNumberInput(false);

    setLoadPage(true);
  }, [b2cContainer, setLoadPage, setShowPhoneNumberInput]);

  // Set error state if mobile number input has error
  useEffect(() => {
    if (!b2cContainer) return;

    const mobileNumberErrorContainerSelector =
      '.readOnlyStrongAuthenticationPhoneNumber .error.itemLevel';
    const mobileNumberErrorContainer = b2cContainer.querySelector(
      mobileNumberErrorContainerSelector
    );

    if (!mobileNumberErrorContainer) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const targetErrorContainer = target as HTMLElement;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const mobileNumberInputHasError =
        targetErrorContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      setMobileNumberHasError(mobileNumberInputHasError);
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: mobileNumberErrorContainerSelector,
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer, setMobileNumberHasError]);

  useAddAlertSummaryMutationObserver();

  useAddAlertSummary();

  // Add mutation observer for all inputs/dropdown error states
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainersSelector = '.attrEntry .error.itemLevel';
    const errorContainers = Array.from(
      b2cContainer?.querySelectorAll(errorContainersSelector)
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';

    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (!errorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target, type } = mutation;
      const errorContainer = target as Element;
      const parentElement = errorContainer?.parentElement;
      const element =
        parentElement?.querySelector('input') ||
        parentElement?.querySelector('select');

      if (!element) return;

      const errorContainerHasTextContent =
        !!errorContainer?.textContent?.length;
      const showingError =
        errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (type === 'childList' && !errorContainerHasTextContent) {
        element?.classList.remove(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.remove(classname)
        );
        return;
      }

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      // remove default error class or ovveride style in css
      element?.classList.remove('invalid');

      element?.classList.remove(textInputErrorClassname);
      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainer?.classList.remove(classname)
      );

      if (showingError && errorContainerHasTextContent) {
        element?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.add(classname)
        );
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: errorContainersSelector,
        config: {
          childList: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Move b2c container inside react container
  useEffect(() => {
    if (!b2cContainer) return;

    if (!loadPage) return;

    const container = document.getElementById('b2c-react-container');
    container?.appendChild(b2cContainer);

    b2cContainer.classList.remove('visuallyhidden');
  }, [b2cContainer, loadPage]);

  return (
    <>
      {hasProfileEditPersonalDetailsStep ? (
        <ProfileEditPersonalDetails />
      ) : null}
    </>
  );
};

export default MfaChallenge;
