import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

const useFocusServerErrorScrollToTarget = () => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const authContainer = document.querySelector('.tfl-auth-container');

    const scrollTargetSpan = document.createElement('SPAN');
    scrollTargetSpan.classList.add('server-error-scroll-target');

    if (!authContainer) return;

    authContainer?.prepend(scrollTargetSpan);

    const serverErrorSummaryContainer = b2cContainer?.querySelector(
      '#claimVerificationServerError'
    ) as HTMLElement;

    if (!serverErrorSummaryContainer) return;

    const handler = () => {
      const scrollTarget = authContainer?.querySelector(
        '.server-error-scroll-target'
      );

      const callback = () => {
        const serverErrorSummaryHeader = b2cContainer.querySelector(
          '.tfl-alert.tfl-alert--error.tfl-server-error'
        ) as HTMLElement;

        if (!serverErrorSummaryHeader) return;

        serverErrorSummaryHeader.focus();
        scrollTarget?.scrollIntoView({ behavior: 'smooth' });
      };

      setTimeout(() => callback(), 500);
    };

    const submitButtons = b2cContainer.querySelectorAll(
      'button[type="submit"]'
    );

    submitButtons.forEach((button) =>
      button?.addEventListener('click', handler)
    );

    return () => {
      submitButtons.forEach((button) =>
        button?.removeEventListener('click', handler)
      );
    };
  }, [b2cContainer]);
};

export default useFocusServerErrorScrollToTarget;
