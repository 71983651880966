import { useEffect } from 'react';
import {
  useB2cContainer,
  useButton,
  useEmptyParagraphRemover,
  useHelpLinkRemover,
  useIntroText,
  useStyleInputs,
  useMoveButtons,
  useMoveContinueButton,
  useHideContinueButton,
  useMoveIntroText,
  useStyleDropdown,
  useRemoveElement,
  useMutationObserver,
  useFocusErrorSummaryScrollToTarget,
  useRemoveChangeButton,
  useHasSignInStep,
  useRemoveAttribute,
  useHasMfaChallengeStep,
  useWatchStyleDisplayProperty,
  useMfaOtpCallback,
  useEnterKeydownHandler,
  useUpdatePageTitle,
  useHasConfirmUpdateContactMobileNumber,
  useHasProfileEditPersonalDetailsStep,
  useHasProfileEditVerifyEmailAddressStep,
} from '../../hooks';
import ConfirmUpdateContactMobileNumber from '../ConfirmUpdateContactMobileNumber';
import { DisconnectFunction } from '../../hooks/useMutationObserver';
import { typedBoolean } from '../../types/tfl-types';
import {
  MfaChallenge,
  ProfileEditPersonalDetails,
  ProfileEditVerifyEmailAddress,
  Signin,
} from '..';
import './MfaSetup.scss';

const MfaSetup = () => {
  const [b2cContainer] = useB2cContainer();
  const [hasSignInStep] = useHasSignInStep();
  const [hasMfaChallengeStep] = useHasMfaChallengeStep();
  const [hasConfirmUpdateOtherContactNumber] =
    useHasConfirmUpdateContactMobileNumber();
  const [hasProfileEditPersonalDetailsStep] =
    useHasProfileEditPersonalDetailsStep();
  const [hasProfileEditVerifyEmailAddressStep] =
    useHasProfileEditVerifyEmailAddressStep();
  const [callback] = useMfaOtpCallback();

  useIntroText('.Paragraph .attrEntry #mfaSetupHeaderText');

  // Move intro text inside form
  useMoveIntroText();

  // Remove all empty li Paragraph
  useEmptyParagraphRemover();

  // Remove help link texts
  useHelpLinkRemover();

  // Add tfl branded styles to input containers/inputs/labels
  useStyleInputs({ otherInputContainerSelector: '.entry-item .error' });

  // Add tfl branded styles to dropdown/labels
  useStyleDropdown();

  // Add text a code button styles
  useButton({
    selector: 'mfaSetupVerificationControl_but_send_code',
    type: 'button',
    variant: 'primary',
  });

  // Add send code button styles
  useButton({
    selector: 'mfaSetupVerificationControl_but_send_new_code',
    type: 'button',
    variant: 'secondary',
  });

  // Add verify code button styles
  useButton({
    selector: 'mfaSetupVerificationControl_but_verify_code',
    type: 'button',
    variant: 'primary',
  });

  // Add continue code button styles
  useButton({
    selector: 'continue',
    type: 'button',
    variant: 'primary',
  });

  // Move continue button next to other buttons
  useMoveContinueButton();

  // Hide continue button on landing page
  useHideContinueButton({
    verificationCodeButtonSelector:
      '#mfaSetupVerificationControl_but_verify_code',
  });

  // Move and append all buttons to bottom of the card
  useMoveButtons('#api > .buttons');

  // Add focus to error skip link on send/verify button click
  useFocusErrorSummaryScrollToTarget({
    buttonSelectors: [
      '#mfaSetupVerificationControl_but_send_code',
      '#mfaSetupVerificationControl_but_verify_code',
    ],
  });

  // Remove label(s) breaking style
  useRemoveElement({
    selectors: [
      '#mfaSetupVerificationControl_label',
      '#mustCompleteAllFieldsParagraphText_label',
    ],
  });

  // Remove change button
  useRemoveChangeButton({
    selector: '#mfaSetupVerificationControl_but_change_claims',
  });

  // Remove inline style attribute from country code & mobile number fields
  [
    '.DropdownSingleSelect.countryCode',
    '.TextBox.phoneNumberMinusCountryCode',
  ].forEach((selector) => useRemoveAttribute({ attribute: 'style', selector }));

  // Move error container below input
  useEffect(() => {
    if (!b2cContainer) return;

    const inputErrorContainers = Array.from(
      b2cContainer.querySelectorAll(
        '.verificationControlContent .TextBox .attrEntry .error.itemLevel'
      )
    );

    const dropdownErrorContainers = Array.from(
      b2cContainer.querySelectorAll(
        '.verificationControlContent .DropdownSingleSelect .attrEntry .error.itemLevel'
      )
    ) as HTMLElement[];

    [...inputErrorContainers, ...dropdownErrorContainers].forEach(
      (container) => {
        const element =
          container.parentNode?.querySelector('input') ||
          container.parentNode?.querySelector('select');
        container.classList.add(`${element?.id}`);
        container.setAttribute(
          'data-summary-link',
          `.tfl-alert-summary__link.${element?.id}`
        );
      }
    );

    [...inputErrorContainers, ...dropdownErrorContainers].forEach((container) =>
      container.parentNode?.appendChild(container)
    );
  }, [b2cContainer]);

  // Move verification message above enter code input label
  useEffect(() => {
    if (!b2cContainer) return;

    if (hasMfaChallengeStep) return;

    const verificationSuccessContainer = b2cContainer.querySelector(
      '.verificationSuccessText'
    );
    const enterCodeContainer = b2cContainer.querySelector(
      '#mfaVerificationCode_label'
    )?.parentNode;

    if (!verificationSuccessContainer) return;
    enterCodeContainer?.prepend(verificationSuccessContainer);
  }, [b2cContainer]);

  // Add Alert Summary
  useEffect(() => {
    if (!b2cContainer) return;

    if (hasProfileEditPersonalDetailsStep) return;

    const errorSummaryHeader = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;

    if (!errorSummaryHeader) return;

    errorSummaryHeader?.classList.add('tfl-alert', 'tfl-alert--error');
    errorSummaryHeader?.setAttribute('aria-labelledby', 'tfl-alert-heading');

    const errorSummaryText = errorSummaryHeader.innerText;
    errorSummaryHeader.innerText = '';

    const alertContainerH2 = document.createElement('H2');
    alertContainerH2?.classList.add(
      'tfl-alert__header',
      'tfl-alert__header--with-description'
    );
    errorSummaryHeader?.prepend(alertContainerH2);
    alertContainerH2.textContent = errorSummaryText;
    alertContainerH2.id = 'tfl-alert-heading';

    // Add UL links
    const alertContent = document.createElement('DIV');
    alertContent.classList.add('tfl-alert__content');

    errorSummaryHeader.prepend(alertContent);
    errorSummaryHeader.insertBefore(alertContainerH2, alertContent);

    const inputErrorContainers = Array.from(
      b2cContainer.querySelectorAll(
        '.verificationControlContent .TextBox .attrEntry .error.itemLevel'
      )
    );

    const dropdownErrorContainers = Array.from(
      b2cContainer.querySelectorAll(
        '.verificationControlContent .DropdownSingleSelect .attrEntry .error.itemLevel'
      )
    ) as HTMLElement[];

    const readOnlyErrorContainers = Array.from(
      b2cContainer.querySelectorAll(
        '.verificationControlContent .Readonly .attrEntry .error.itemLevel'
      )
    ) as HTMLElement[];

    const errorSummaryLinks = [
      ...inputErrorContainers,
      ...dropdownErrorContainers,
      ...readOnlyErrorContainers,
    ].map((container) => {
      const targetControl =
        container?.parentNode?.querySelector('input') ||
        container?.parentNode?.querySelector('select');

      if (!targetControl?.id) return;

      const link = document.createElement('A');
      link.id = `skip-link-${targetControl?.id}`;
      link.setAttribute('href', `#${targetControl?.id}`);
      link.classList.add('tfl-alert-summary__link-text');
      link.textContent = container.textContent;

      link.addEventListener('click', (event) => {
        event.preventDefault();
        const link = event.target as HTMLElement;
        const targetInputId = link.getAttribute('href');
        if (!targetInputId) return;
        const targetInput = b2cContainer.querySelector(
          targetInputId
        ) as HTMLElement;
        targetInput?.focus();
      });

      const errorSummaryLink = {
        targetId: `${targetControl?.id}`,
        element: link,
      };

      return errorSummaryLink;
    });

    const ulLinks = document.createElement('UL');
    ulLinks.classList.add('tfl-alert-summary');
    alertContent.prepend(ulLinks);

    errorSummaryLinks.filter(typedBoolean).forEach((link) => {
      const listItem = document.createElement('LI');
      listItem.classList.add(`tfl-alert-summary__link`, link?.targetId, 'hide');
      listItem.appendChild(link.element);

      ulLinks.appendChild(listItem);
    });
  }, [b2cContainer]);

  // Handle enter key press on verify code input field.
  // Caters for both MfaChallenge Step & MfaChallenge registering new phone number.
  useEnterKeydownHandler({
    inputFieldSelector: '#mfaVerificationCode',
    targetButtonSelector: '#mfaSetupVerificationControl_but_verify_code',
  });

  // Handle enter key press on phone number input field when settting up a Mfa phone number.
  useEnterKeydownHandler({
    inputFieldSelector: '#phoneNumberMinusCountryCode',
    targetButtonSelector: '#mfaSetupVerificationControl_but_send_code',
  });

  // Add mutation observer for all inputs/dropdown error states
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainersSelector = '.attrEntry .error.itemLevel';
    const errorContainers = Array.from(
      b2cContainer?.querySelectorAll(errorContainersSelector)
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';

    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (!errorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target, type } = mutation;
      const errorContainer = target as Element;
      const parentElement = errorContainer?.parentElement;
      const element =
        parentElement?.querySelector('input') ||
        parentElement?.querySelector('select');

      if (!element) return;

      const errorContainerHasTextContent =
        !!errorContainer?.textContent?.length;
      const showingError =
        errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (type === 'childList' && !errorContainerHasTextContent) {
        element?.classList.remove(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.remove(classname)
        );
        return;
      }

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      // remove default error class or ovveride style in css
      element?.classList.remove('invalid');

      element?.classList.remove(textInputErrorClassname);
      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainer?.classList.remove(classname)
      );

      if (showingError && errorContainerHasTextContent) {
        element?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.add(classname)
        );
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: errorContainersSelector,
        config: {
          childList: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Attach event handlers side effects Alert Summary
  useEffect(() => {
    // control input box and dropdown css error states
    if (!b2cContainer) return;

    const errorSummaryContainer = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;

    if (!errorSummaryContainer) return;

    const inputs = Array.from(
      b2cContainer?.querySelectorAll('input')
    ) as HTMLElement[];

    const dropdowns = Array.from(
      b2cContainer?.querySelectorAll('select')
    ) as HTMLElement[];

    const inputErrorContainerSelectors = inputs.map(
      (input) => `.error.itemLevel.${input.id}`
    );

    const dropdownErrorContainerSelectors = dropdowns.map(
      (dropdown) => `.error.itemLevel.${dropdown?.id}`
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';
    const dropdownErrorClassname = 'tfl-dropdown__container--error';
    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    const errorContainerSelectors = [
      ...inputErrorContainerSelectors,
      ...dropdownErrorContainerSelectors,
    ];
    if (!errorContainerSelectors.length) return;

    const inputErrorContainers = errorContainerSelectors.map((selector) =>
      b2cContainer.querySelector(selector)
    );
    if (!inputErrorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const targetErrorContainer = target as Element;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const hasInlineErrorsOnThePage =
        inputErrorContainers.find(
          (container) =>
            container?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
            'false'
        ) !== undefined;

      errorSummaryContainer.setAttribute(
        'aria-hidden',
        hasInlineErrorsOnThePage ? 'false' : 'true'
      );
      errorSummaryContainer.style.display = hasInlineErrorsOnThePage
        ? 'block'
        : 'none';

      const targetSummaryItemLinkSelector =
        targetErrorContainer.attributes.getNamedItem(
          'data-summary-link'
        )?.nodeValue;

      if (!targetSummaryItemLinkSelector) return;

      let targetSummaryItemLink: HTMLElement | null | undefined = undefined;

      targetSummaryItemLink = b2cContainer?.querySelector(
        targetSummaryItemLinkSelector
      ) as HTMLElement;

      if (!targetSummaryItemLink) return;

      const dropdown =
        targetErrorContainer?.parentElement?.querySelector('select');
      const input = targetErrorContainer?.parentElement?.querySelector('input');

      const errorContainerShowingError =
        targetErrorContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      // update alert summary link
      errorContainerShowingError
        ? (targetSummaryItemLink.classList.add('show'),
          targetSummaryItemLink.classList.remove('hide'))
        : (targetSummaryItemLink.classList.add('hide'),
          targetSummaryItemLink.classList.remove('show'));

      targetSummaryItemLink.setAttribute(
        'aria-hidden',
        errorContainerShowingError ? 'false' : 'true'
      );

      const skipLink = targetSummaryItemLink.querySelector('a');
      if (skipLink) {
        skipLink.textContent = errorContainerShowingError
          ? targetErrorContainer?.textContent
          : '';
      }

      if (errorContainerShowingError) {
        dropdown?.classList.add(dropdownErrorClassname);
        input?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          targetErrorContainer?.classList.add(classname)
        );
        return;
      }

      dropdown?.classList.remove(dropdownErrorClassname);
      input?.classList.remove(textInputErrorClassname);

      errorContainerValidationErrorClassnames.forEach((classname) =>
        targetErrorContainer?.classList.remove(classname)
      );
    };

    const disconnects: DisconnectFunction[] = [];

    errorContainerSelectors.forEach((selector) => {
      const { disconnect } = {
        ...useMutationObserver({
          querySelector: selector,
          callback,
        }),
      };

      disconnects.push(disconnect);
    });

    return () => {
      disconnects?.forEach((disconnect) =>
        typeof disconnect === 'function' ? disconnect() : undefined
      );
    };
  }, [b2cContainer]);

  // Add mutation observer to show continue button & code input box & disable verify code input loader
  useEffect(() => {
    const otpCodeSuccessMessageContainerSelector =
      '#mfaSetupVerificationControl_success_message';
    const verifyCodeButtonSelector =
      '#mfaSetupVerificationControl_but_verify_code';

    const verifyCodeButton = b2cContainer?.querySelector(
      verifyCodeButtonSelector
    );

    const mfaVerificationCodeContainer = b2cContainer?.querySelector(
      '.TextBox.VerificationCode'
    ) as HTMLElement;

    if (!mfaVerificationCodeContainer || !verifyCodeButton) return;

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otpCodeSuccessMessageContainerSelector,
        callback: (mutation: MutationRecord) =>
          callback(mutation, '#mfaVerificationCode'),
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Leverage out the box loading indicator ui element provided by the policy
  useEffect(() => {
    if (!b2cContainer) return;

    const loadingContainerSelector = '.working';
    const verificationCodeInput = b2cContainer.querySelector(
      '#mfaVerificationCode'
    );

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const loadingContainer = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const busy =
        loadingContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (!busy) {
        verificationCodeInput?.classList.remove(
          'tfl-text-input__input--loading'
        );
        return;
      }

      verificationCodeInput?.classList.add('tfl-text-input__input--loading');
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: loadingContainerSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Copy other verify code error message to inline error container
  useEffect(() => {
    if (!b2cContainer) return;

    const otherErrorMessageContainerSelector =
      '#mfaSetupVerificationControl_error_message';
    const otherErrorMessageContainer = b2cContainer.querySelector(
      otherErrorMessageContainerSelector
    );

    if (!otherErrorMessageContainer) return;

    // We can't remove it from DOM so just hide it
    otherErrorMessageContainer?.classList.add('visuallyhidden');

    const mobilePhoneNumberContainer = b2cContainer.querySelector(
      '.TextBox.phoneNumberMinusCountryCode'
    );

    const mobilePhoneNumberInlineErrorContainer =
      mobilePhoneNumberContainer?.querySelector('.error.itemLevel');

    const verificationCodeContainer = b2cContainer.querySelector(
      '.TextBox.VerificationCode'
    );

    const verificationCodeInlineErrorContainer =
      verificationCodeContainer?.querySelector('.error.itemLevel');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const otherErrorMessageContainer = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const otherErrorMessageContainerShowing =
        otherErrorMessageContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const textContent = otherErrorMessageContainer?.textContent;

      const otherErrorMessageContainerHasTextContent = !!textContent?.length;

      if (
        !otherErrorMessageContainerShowing ||
        !otherErrorMessageContainerHasTextContent
      )
        return;

      const verificationCodeContainerShowing =
        verificationCodeContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      if (
        verificationCodeContainerShowing &&
        verificationCodeInlineErrorContainer
      ) {
        verificationCodeInlineErrorContainer.textContent = textContent;
        verificationCodeInlineErrorContainer.setAttribute(
          'aria-hidden',
          'false'
        );
        return;
      }

      const mobilePhoneNumberContainerShowing =
        mobilePhoneNumberContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      if (
        mobilePhoneNumberContainerShowing &&
        mobilePhoneNumberInlineErrorContainer
      ) {
        mobilePhoneNumberInlineErrorContainer.textContent = textContent;
        mobilePhoneNumberInlineErrorContainer.setAttribute(
          'aria-hidden',
          'false'
        );
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otherErrorMessageContainerSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Undo `display: inline` on contry code & mobile number fields
  useWatchStyleDisplayProperty({
    selectors: [
      '#mfaSetupVerificationControl .tfl-dropdown.countryCode',
      '#mfaSetupVerificationControl .TextBox.phoneNumberMinusCountryCode',
    ],
  });

  // Move inside react container
  useEffect(() => {
    if (!b2cContainer) return;

    const container = document.getElementById('b2c-react-container');
    if (!container) return;

    container.appendChild(b2cContainer);

    b2cContainer.classList.remove('visuallyhidden');
  }, [b2cContainer]);

  useUpdatePageTitle('.heading h1');

  return (
    <>
      {hasSignInStep ? <Signin /> : null}
      {hasMfaChallengeStep ? <MfaChallenge /> : null}
      {hasConfirmUpdateOtherContactNumber ? (
        <ConfirmUpdateContactMobileNumber />
      ) : null}
      {hasProfileEditPersonalDetailsStep ? (
        <ProfileEditPersonalDetails />
      ) : null}
      {hasProfileEditVerifyEmailAddressStep ? (
        <ProfileEditVerifyEmailAddress />
      ) : null}
    </>
  );
};

export default MfaSetup;
