import { useEffect } from 'react';
import { useAddPasscodeSecuritySpan, useStyleInputs } from '../../hooks';
import useB2cContainer from '../../hooks/useB2cContainer';
import { PasscodeValidation } from '../PasscodeValidation';
import './Security.scss';

const Security = () => {
  const [b2cContainer] = useB2cContainer();

  useStyleInputs({ otherInputContainerSelector: '#attributeList .attrEntry' });

  // Remove unnecessary element causing style issue + remove Margin-Bottom from first Li
  useEffect(() => {
    if (!b2cContainer) return;

    const secondParagraph = b2cContainer.querySelector(
      '#securityDetails_SecondParagraphText_label'
    );
    secondParagraph?.remove();

    const firstParagraph = b2cContainer.querySelector(
      '#securityDetails_FirstParagraphText'
    );

    const closestLi = firstParagraph?.parentElement?.closest('li');
    closestLi?.classList.add('tfl-create-passcode-title');
  }, [b2cContainer]);

  // Move contact customer services paragraph to the bottom of the page
  useEffect(() => {
    if (!b2cContainer) return;

    const contactCustomerServicesParagraph = b2cContainer.querySelector(
      '#securityDetails_ThirdParagraphText'
    );

    if (!contactCustomerServicesParagraph) return;

    const securityAsnwerInput = b2cContainer.querySelector(
      '#securityQuestion_Answer'
    ) as HTMLInputElement;

    if (!securityAsnwerInput) return;

    const targetContainer = securityAsnwerInput.closest(
      '#attributeList'
    ) as HTMLDivElement;

    if (!targetContainer) return;

    targetContainer.after(contactCustomerServicesParagraph);
  }, [b2cContainer]);

  useAddPasscodeSecuritySpan();

  // Change Security question drop down placeholder to "Choose a question"
  useEffect(() => {
    if (!b2cContainer) return;

    const securityQuestionDropDown = b2cContainer.querySelector(
      '#securityQuestion_DropdownList'
    ) as HTMLSelectElement;

    if (!securityQuestionDropDown) return;

    const securityQuestionDropDownOptions = Array.from(
      securityQuestionDropDown.options
    );

    const placeholderOption = securityQuestionDropDownOptions.find(
      (option) => option.hasAttribute('disabled') && option.selected
    );

    if (!placeholderOption) return;

    placeholderOption.textContent = 'Choose a question';
  }, [b2cContainer]);

  return (
    <>
      <PasscodeValidation />
    </>
  );
};

export default Security;
