import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppProviders } from './context/AppProviders';


ReactDOM.createRoot(document.getElementById('app') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
     <AppProviders>
      <App />
     </AppProviders>
    </BrowserRouter>
  </React.StrictMode>);