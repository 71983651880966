import { useEffect } from 'react';
import {
  useB2cContainer,
  useButton,
  useEmptyParagraphRemover,
  useHelpLinkRemover,
} from '../../hooks';

const OneQuickThing = () => {
  const [b2cContainer] = useB2cContainer();
  useEmptyParagraphRemover();

  useHelpLinkRemover();

  useButton({ selector: '#continue', type: 'button', variant: 'primary' });

  //Add intro text styles
  useEffect(() => {
    if (!b2cContainer) return;

    const introParagraph = b2cContainer.querySelector(
      '.textInParagraph'
    ) as HTMLParagraphElement;

    const introParagraphContainer = b2cContainer.querySelector(
      '.oneQuickThingHeaderText_li'
    );

    if (!introParagraph || !introParagraphContainer) return;

    const headingElement = b2cContainer.querySelector('[role="heading"]');

    if (!headingElement) return;

    headingElement.textContent = introParagraph.innerText;

    introParagraphContainer.remove();
  }, [b2cContainer]);

  useEffect(() => {
    if (!b2cContainer) return;

    const label = b2cContainer?.querySelector(
      '#oneQuickThingFirstParagraphText_label'
    );
    if (!label) return;

    label.remove();
  }, [b2cContainer]);

  return <></>;
};

export default OneQuickThing;
