import { Header } from '@tfl/component-library';
import { HeaderProps } from '@tfl/component-library/build/components/Header/Header.types';
import mockHeaderData from '../../assets/headerdata.json';

export const AuthHeader = () => {
  const props: HeaderProps = {
    data: mockHeaderData,
    homeUrl: `${process.env.REACT_APP_ROUNDEL_HOME_URL}`,
    searchBarVisible: true,
    accountButtonRequired: false,
    accountProps: {
      isUserAuthenticated: false,
      handleSignIn: () => void 0,
      handleSignOut: () => void 0,
    },
  };

  return <Header {...props} />;
};
