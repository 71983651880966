import '../assets/scss/components/Address.scss';
import { useEffect, useRef, useState } from 'react';
import {
  PostcodeDetailType,
  PostcodePicklistType,
} from '../types/PostcodeLookup.types';
import { Regex } from '../constants';
import { useB2cContainer, useMutationObserver } from './index';

const getUrl = ({ method }: { method: string | undefined }): string =>
  `${process.env.REACT_APP_POSTCODE_BASE_URL}/${method}`;

const apiKey:
  | string
  | undefined = `${process.env.REACT_APP_POSTCODE_OCP_APIM_SUBSCRIPTION_KEY}`;

const picklistUrl = getUrl({
  method: process.env.REACT_APP_POSTCODE_BASE_PICKLIST_METHOD,
});

const detailUrl = getUrl({
  method: process.env.REACT_APP_POSTCODE_BASE_DETAIL_METHOD,
});

const headers = {
  method: 'get',
  headers: new Headers({
    [`Ocp-Apim-Subscription-Key`]: apiKey,
    'Content-Type': 'application/json',
  }),
};

const useAddressStyling = () => {
  const [b2cContainer] = useB2cContainer();
  const [loadingPicklist, setLoadingPicklist] = useState<boolean>();
  const [addressKey, setAddressKey] = useState<string | undefined>();
  const [clearFormExceptPostcode, setClearFormExceptPostcode] =
    useState<boolean>();
  const [showDropdownError, setShowDropdownError] = useState<
    boolean | undefined
  >();
  const [clearFormFieldsInlineError, setClearFormFieldsInlineError] =
    useState<boolean>();
  const [
    clearManualAddressEntryPageInilneErrors,
    setClearManualAddressEntryPageInilneErrors,
  ] = useState<boolean>();
  const [selectedCountry, setSelectedCountry] = useState<{
    value: string;
    userChanged: boolean;
  }>();
  const ukCountryValue = '227';
  const [addressPickList, setAddressPickList] =
    useState<PostcodePicklistType[]>();
  const [selectedAddressDetails, setSelectedAddressDetails] = useState<
    PostcodeDetailType | undefined
  >();
  const [postcodeLookupError, setPostcodeLookupError] = useState<
    string | undefined
  >();
  const [postcodeDoesNotExist, setPostcodeDoesNotExist] = useState<boolean>();
  const inputLabels = useRef({
    uk: {
      StreetName: '',
      TownCity: '',
      PostCode: '',
    },
    nonUk: {
      AddressLine1: '',
      AddressLine4: '',
      PostalZipCode: '',
    },
  });
  const postcodeLookupRegexValidationMessage = useRef('');
  const postcodeLookupEmptyValidationMessage = useRef('');
  const houseNumberHouseNameValidationMessage = useRef('');
  const postcodeValidationRegex = useRef({
    uk: '',
    nonUk: '',
  });

  const styleSubText = (id: string) => {
    const label = b2cContainer?.querySelector(`#${id}`) as HTMLElement;
    if (!label) return;

    const labelText = label.innerText;

    const subTextPattern = /(\(.*\))/;
    const subTextMatch = labelText.match(subTextPattern);
    if (subTextMatch) {
      label.textContent = labelText.replace(subTextPattern, '');
      const subtext = document.createElement('SPAN');
      subtext.classList.add('tfl-subtext');
      subtext.innerText = subTextMatch[0];
      label.append(subtext);
      label.setAttribute('data-original-text', labelText);
    }
  };

  const styleSubHeader = (id: string) => {
    const element = b2cContainer?.querySelector(`#${id}`) as HTMLElement;
    if (!element) return;
    element.classList.add('tfl-subheader');
  };

  // Remove/update elements causing style issues
  useEffect(() => {
    if (!b2cContainer) return;

    ['#address_IntroParagraphText_label'].forEach((selector) =>
      b2cContainer.querySelector(selector)?.remove()
    );
  }, [b2cContainer]);

  // Prepare initial view
  useEffect(() => {
    if (!b2cContainer) return;

    const extractValidationMessageAndHideElement = (id: string) => {
      const validationMessageField = b2cContainer.querySelector(`#${id}`);

      const message = validationMessageField?.textContent ?? '';

      const validationMessageContainer = validationMessageField?.closest('li');

      if (validationMessageContainer) {
        validationMessageContainer.style.display = 'none';
      }

      return message;
    };

    postcodeLookupRegexValidationMessage.current =
      extractValidationMessageAndHideElement(
        'address_PostcodeLookupRegexValidationMessage'
      );

    postcodeLookupEmptyValidationMessage.current =
      extractValidationMessageAndHideElement(
        'address_PostcodeLookupEmptyValidationMessage'
      );

    houseNumberHouseNameValidationMessage.current =
      extractValidationMessageAndHideElement(
        'address_HouseNumberHouseNameValidationMessage'
      );

    const getNonUkText = (id: string) => {
      const elem = b2cContainer.querySelector(`#${id}`) as HTMLParagraphElement;
      const innerText = elem.textContent ?? '';
      elem.closest('li')?.remove();
      return innerText;
    };

    inputLabels.current = {
      uk: {
        StreetName:
          b2cContainer.querySelector('#address_StreetName_label')
            ?.textContent ?? '',
        TownCity:
          b2cContainer.querySelector('#address_TownCity_label')?.textContent ??
          '',
        PostCode:
          b2cContainer.querySelector('#address_Postcode_label')?.textContent ??
          '',
      },
      nonUk: {
        AddressLine1: getNonUkText('address_StreetNameNonUkLabel'),
        AddressLine4: getNonUkText('address_TownCityNonUkLabel'),
        PostalZipCode: getNonUkText('address_PostcodeNonUkLabel'),
      },
    };

    const containerDiv = document.querySelector('.container') as HTMLDivElement;

    containerDiv?.classList.add('tfl-address-form-container-uk');

    const countryDropDownListItem = b2cContainer
      ?.querySelector('#address_Country')
      ?.closest('li') as HTMLLIElement;

    countryDropDownListItem?.classList.add('tfl-country-dropdown');

    const postcodeInput = b2cContainer.querySelector(
      '#address_Postcode'
    ) as HTMLInputElement;

    if (postcodeInput) {
      postcodeValidationRegex.current.uk = Regex.postcode.source;
      postcodeValidationRegex.current.nonUk =
        postcodeInput.getAttribute('pattern') || '';
      postcodeInput.setAttribute('pattern', postcodeValidationRegex.current.uk);
    }
  }, [b2cContainer]);

  // apply text styles
  useEffect(() => {
    if (!b2cContainer) return;

    styleSubHeader('address_SubHeaderText_AddressLookup');
    styleSubHeader('address_SubHeaderText_YourAddress');

    // Restyle subtext, e.g. (optional)
    styleSubText('address_HouseNumber_label');
    styleSubText('address_HouseName_label');
    styleSubText('address_AddressLine2_label');
    styleSubText('address_AddressLine3_label');
    styleSubText('address_TownCityNonUkLabel_label');
  }, [b2cContainer]);

  // Save initial country value to state
  useEffect(() => {
    if (!b2cContainer) return;

    const countryDropDown = b2cContainer.querySelector(
      '#address_Country'
    ) as HTMLSelectElement;

    const selectedCountryValue =
      countryDropDown.options[countryDropDown.selectedIndex].value;

    setSelectedCountry({ value: selectedCountryValue, userChanged: false });
  }, [b2cContainer]);

  //Update form based on country selection
  useEffect(() => {
    if (!b2cContainer) return;

    const ukInputSelectors = [
      'address_HouseNumber',
      'address_HouseName',
      'address_StreetName',
      'address_AddressLine2',
      'address_AddressLine3',
      'address_TownCity',
      'address_Postcode',
    ];

    const ukLabelSelectors = [
      'address_SubHeaderText_AddressLookup',
      'address_SubHeaderText_YourAddress',
    ];

    const nonUkInputSelectors = [
      'address_StreetName',
      'address_TownCity',
      'address_AddressLine2',
      'address_AddressLine3',
      'address_Postcode',
    ];

    const postcodeLookupSelectors = [
      'address_PostcodeLookupInput',
      'tfl-find-your-address',
    ];

    const showHideElements = function (ids: string[], show: boolean) {
      ids.forEach((id) => {
        const elem = (
          b2cContainer.querySelector(`#${id}`) as HTMLInputElement
        )?.closest('li');
        if (elem) {
          elem.classList.remove(show ? 'hide' : 'show');
          elem.classList.add(show ? 'show' : 'hide');
        }
      });
    };

    const clearElements = function (ids: string[]) {
      ids.forEach((id) => {
        const elem = b2cContainer.querySelector(`#${id}`) as HTMLInputElement;
        if (elem) {
          elem.value = '';
        }
      });
    };

    const setLabelValue = function (id: string, text: string) {
      const elem = b2cContainer.querySelector(id) as HTMLInputElement;
      elem.textContent = text;
    };

    const setAriaLabelValue = function (id: string, text: string) {
      const elem = b2cContainer.querySelector(id) as HTMLInputElement;
      elem.setAttribute('aria-label', text);
    };

    showHideElements(ukInputSelectors, false);
    showHideElements(nonUkInputSelectors, false);
    showHideElements(ukLabelSelectors, false);

    if (selectedCountry?.userChanged) {
      clearElements(ukInputSelectors);
      clearElements(nonUkInputSelectors);
      clearElements(['address_PostcodeLookupInput']);
      setAddressPickList(undefined);
    }

    const isUkSelected = selectedCountry?.value === ukCountryValue;

    showHideElements(
      isUkSelected ? ukInputSelectors : nonUkInputSelectors,
      true
    );
    showHideElements(postcodeLookupSelectors, isUkSelected);
    showHideElements(ukLabelSelectors, isUkSelected);

    const streetNameLabel = isUkSelected
      ? inputLabels.current.uk.StreetName
      : inputLabels.current.nonUk.AddressLine1;
    setLabelValue('#address_StreetName_label', streetNameLabel);
    setAriaLabelValue('#address_StreetName', streetNameLabel);

    const townCityLabel = isUkSelected
      ? inputLabels.current.uk.TownCity
      : inputLabels.current.nonUk.AddressLine4;
    setLabelValue('#address_TownCity_label', townCityLabel);
    setAriaLabelValue('#address_TownCity', townCityLabel);

    const postcodeLabel = isUkSelected
      ? inputLabels.current.uk.PostCode
      : inputLabels.current.nonUk.PostalZipCode;
    setLabelValue('#address_Postcode_label', postcodeLabel);
    setAriaLabelValue('#address_Postcode', postcodeLabel);

    styleSubText('address_TownCity_label');

    const postcodeInput = b2cContainer.querySelector(
      '#address_Postcode'
    ) as HTMLInputElement;

    if (postcodeInput) {
      postcodeInput.setAttribute(
        'pattern',
        isUkSelected
          ? postcodeValidationRegex.current.uk
          : postcodeValidationRegex.current.nonUk
      );
    }
  }, [b2cContainer, selectedCountry]);

  // Create find your address button
  useEffect(() => {
    if (!b2cContainer) return;

    const findAddressButton = b2cContainer.querySelector(
      '#tfl-find-your-address'
    );

    if (findAddressButton) return;

    const findAddressButtonParagraph = b2cContainer.querySelector(
      '#address_PostcodeLookup'
    ) as HTMLParagraphElement;

    if (!findAddressButtonParagraph) return;

    const findAddressButtonContainer = document.createElement('li');
    findAddressButtonContainer.classList.add(
      'tfl-find-your-address-container',
      'hiding-results'
    );

    const findYourAddressButton = document.createElement('button');
    findYourAddressButton.id = 'tfl-find-your-address';
    findYourAddressButton.type = 'button';
    findYourAddressButton.textContent = findAddressButtonParagraph.innerText;
    findYourAddressButton.classList.add(
      'tfl-find-your-address',
      'tfl-button',
      'tfl-button--secondary'
    );

    findAddressButtonContainer.append(findYourAddressButton);

    const findAddressButtonParagraphContainer =
      findAddressButtonParagraph.closest('li');

    findAddressButtonParagraphContainer?.after(findAddressButtonContainer);

    findAddressButtonParagraphContainer?.remove();
  }, [b2cContainer]);

  // Toggle postcode lookup results visibility
  useEffect(() => {
    if (!b2cContainer) return;

    const addressDropdownContainer = b2cContainer
      .querySelector('#address_AddressDropdown')
      ?.closest('li');

    const findAddressButtonContainer = b2cContainer.querySelector(
      '.tfl-find-your-address-container'
    );

    addressDropdownContainer?.classList.add('tfl-address-dropdown-container');

    if (addressPickList) {
      findAddressButtonContainer?.classList.remove('hiding-results');
      addressDropdownContainer?.classList.add('show');
      addressDropdownContainer?.classList.remove('hide');
    } else {
      findAddressButtonContainer?.classList.add('hiding-results');
      addressDropdownContainer?.classList.add('hide');
      addressDropdownContainer?.classList.remove('show');
    }
  }, [b2cContainer, addressPickList]);

  // Move address inline error containers below the input
  useEffect(() => {
    if (!b2cContainer) return;

    const findYourAddressButtonContainer = b2cContainer.querySelector(
      '.tfl-find-your-address-container'
    );

    if (!findYourAddressButtonContainer) return;

    const inputErrorContainers = Array.from(
      b2cContainer.querySelectorAll('.TextBox .attrEntry .error')
    ) as HTMLElement[];

    const dropdownErrorContainers = Array.from(
      b2cContainer.querySelectorAll('.DropdownSingleSelect .attrEntry .error')
    ) as HTMLElement[];

    [...inputErrorContainers, ...dropdownErrorContainers].forEach(
      (container) => {
        const element =
          container.parentNode?.querySelector('input') ||
          container.parentNode?.querySelector('select');
        container.classList.add(`${element?.id}`);
        container.setAttribute(
          'data-summary-link',
          `.tfl-alert-summary__link.${element?.id}`
        );
      }
    );

    [...inputErrorContainers, ...dropdownErrorContainers].forEach((container) =>
      container.parentNode?.appendChild(container)
    );
  }, [b2cContainer]);

  // Lookup postcode on ALS and store picklist to state
  useEffect(() => {
    if (!b2cContainer || !loadingPicklist) return;

    const postcodeInput = b2cContainer.querySelector(
      '#address_PostcodeLookupInput'
    ) as HTMLInputElement;

    if (!postcodeInput) return;

    const lookupPostcode = async ({ postcode }: { postcode: string }) => {
      let picklist: PostcodePicklistType[] = [];
      try {
        const picklistResponse = await fetch(
          `${picklistUrl}=${postcode}`,
          headers
        );
        picklist = await picklistResponse.json();

        if (picklistResponse.status !== 200) {
          console.warn(
            `Something went wrong, could not find any address for postcode : ${postcode}`,
            {
              ...picklist,
            }
          );

          setAddressPickList(undefined);
          setPostcodeLookupError(
            'Oops, something went wrong looking up that postcode, please try again'
          );
          setPostcodeDoesNotExist(true);
          return;
        }

        setAddressPickList(picklist);
        setPostcodeLookupError(undefined);
      } catch (err) {
        console.warn(`Something went wrong`, err);
      } finally {
        setLoadingPicklist(false);
        setShowDropdownError(false);
        setAddressKey(undefined);
      }
    };

    lookupPostcode({ postcode: postcodeInput.value });
  }, [
    b2cContainer,
    loadingPicklist,
    clearFormExceptPostcode,
    showDropdownError,
    addressKey,
  ]);

  //  Look up address detail by picklist key on ALS and store to state
  useEffect(() => {
    if (!addressKey) return;

    const getAddressDetails = async (addressKey: string) => {
      let detail: Partial<PostcodeDetailType> = {};
      try {
        const detailResponse = await fetch(
          `${detailUrl}=${addressKey}`,
          headers
        );

        detail = await detailResponse.json();

        return {
          detail: Object.assign(
            { AddressLineTwo: '', AddressLineThree: '' },
            detail as PostcodeDetailType
          ),
        };
      } catch (err) {
        console.warn(`Something went wrong`, err);
      }
    };

    getAddressDetails(addressKey).then((data) =>
      setSelectedAddressDetails(data?.detail)
    );
  }, [addressKey]);

  // disable postcode lookup button when fetching postcode picklist
  useEffect(() => {
    if (!b2cContainer) return;

    const findYourAddressButton = b2cContainer.querySelector(
      '#tfl-find-your-address'
    );

    if (!findYourAddressButton) return;

    const addressDropDown = b2cContainer.querySelector(
      '#address_AddressDropdown'
    ) as HTMLSelectElement;

    if (!addressDropDown) return;

    if (loadingPicklist) {
      findYourAddressButton.setAttribute('disabled', 'true');
      return;
    }

    findYourAddressButton.removeAttribute('disabled');
  }, [b2cContainer, loadingPicklist]);

  // Populate address dropdown from address context
  useEffect(() => {
    if (!b2cContainer) return;

    const addressDropDown = b2cContainer.querySelector(
      '#address_AddressDropdown'
    ) as HTMLSelectElement;

    if (!addressDropDown) return;

    const createOption = ({
      Key,
      Address,
    }: PostcodePicklistType): HTMLOptionElement => {
      const option = document.createElement('option');
      option.text = Address;
      option.value = Key;
      return option;
    };

    const createDefaultOption = (
      address: PostcodePicklistType
    ): HTMLOptionElement => {
      const option = createOption(address);
      option.setAttribute('disabled', 'disbled');
      option.selected = true;
      return option;
    };

    Array.from(addressDropDown.options).forEach((option) => option.remove());

    addressDropDown.add(
      createDefaultOption({
        Key: 'default',
        Address: addressPickList?.length
          ? `${addressPickList?.length} addresses found`
          : 'Choose an address',
      })
    );

    if (!addressPickList?.length) return;

    addressDropDown.focus();
    addressPickList?.forEach((address) => {
      if (!address) return;
      addressDropDown.add(createOption(address));
    });
  }, [b2cContainer, addressPickList, addressKey]);

  // Populate form with selected address detail
  useEffect(() => {
    if (!b2cContainer) return;

    const inputSelectors = {
      HouseNumber: 'address_HouseNumber',
      HouseName: 'address_HouseName',
      StreetName: 'address_StreetName',
      AddressLineTwo: 'address_AddressLine2',
      AddressLineThree: 'address_AddressLine3',
      City: 'address_TownCity',
      Postcode: 'address_Postcode',
    };

    const addressInputMap: Map<string, HTMLInputElement> = new Map();
    Object.entries(inputSelectors).forEach(([key, value]) =>
      addressInputMap.set(
        key,
        b2cContainer.querySelector(`#${value}`) as HTMLInputElement
      )
    );

    // An edge case if somehow there is no address detail available for a postcode picklist id
    if (!selectedAddressDetails) {
      setAddressKey(() => undefined);
      setClearFormExceptPostcode(() => true);
      return;
    }

    Object.entries(selectedAddressDetails).forEach(([key, value]) => {
      const input = addressInputMap.get(key);
      if (!input) return;
      input.value = value;
    });

    const addressDropDown = b2cContainer.querySelector(
      '#address_AddressDropdown'
    ) as HTMLSelectElement;

    for (let i = 0; i < addressDropDown.options.length; i++) {
      if (addressDropDown.options[i].value === addressKey) {
        addressDropDown.selectedIndex = i;
        break;
      }
    }
  }, [selectedAddressDetails, b2cContainer]);

  // Show error if no address option selected from a populated dropdown
  useEffect(() => {
    if (!b2cContainer || showDropdownError === undefined) return;

    const addressDropDown = b2cContainer.querySelector(
      '#address_AddressDropdown'
    ) as HTMLSelectElement;

    const errorContainer = addressDropDown?.parentElement?.querySelector(
      '.error.itemLevel'
    ) as HTMLElement;

    if (!addressDropDown || !errorContainer) return;

    if (!showDropdownError) {
      errorContainer.setAttribute('aria-hidden', 'true');
      errorContainer.textContent = null;
      return;
    }

    errorContainer.focus();
    errorContainer.setAttribute('aria-hidden', 'false');
    errorContainer.textContent = 'Choose your address';

    setShowDropdownError(() => undefined);
  }, [b2cContainer, showDropdownError]);

  // Set error on postcode lookup field
  useEffect(() => {
    if (!b2cContainer) return;

    const postcodeLookupErrorContainer = document.querySelector(
      `.error.itemLevel.address_PostcodeLookupInput`
    );
    if (!postcodeLookupErrorContainer) return;

    postcodeLookupErrorContainer.textContent = postcodeLookupError ?? '';
    postcodeLookupErrorContainer.setAttribute(
      'aria-hidden',
      postcodeLookupError ? 'false' : 'true'
    );
  }, [b2cContainer, postcodeLookupError]);

  // Set error on postcode if doesn't exists
  useEffect(() => {
    if (!b2cContainer || !postcodeDoesNotExist) return;

    const findYourAddressButton = document.querySelector(
      `#tfl-find-your-address`
    );
    if (!findYourAddressButton) return;

    const postcodeErrorContainer = document.querySelector(
      `.error.itemLevel.address_PostcodeLookupInput`
    );
    if (!postcodeErrorContainer) return;

    postcodeErrorContainer.textContent = 'Enter a postcode that exists';
    postcodeErrorContainer.setAttribute('aria-hidden', 'false');

    b2cContainer?.scrollIntoView({ behavior: 'smooth' });

    setPostcodeDoesNotExist(false);
  }, [b2cContainer, postcodeDoesNotExist]);

  // Attach side effects for event handlers/mutation observers below this line

  // Add handler for country selection
  useEffect(() => {
    if (!b2cContainer) return;

    const countryDropdown = b2cContainer.querySelector(
      '#address_Country'
    ) as HTMLSelectElement;

    if (!countryDropdown) return;

    const handler = async ({ target }: Event) => {
      const dropDown = target as HTMLSelectElement;
      const key = dropDown.options[dropDown.selectedIndex].value;
      setSelectedCountry({ value: key, userChanged: true });
      setClearFormFieldsInlineError(true);
    };

    countryDropdown.addEventListener('input', handler);

    return () => countryDropdown.removeEventListener('input', handler);
  }, [b2cContainer]);

  // Set up event handler to look up postcode
  useEffect(() => {
    if (!b2cContainer) return;

    const findYourAddressButton = b2cContainer.querySelector(
      '#tfl-find-your-address'
    );

    if (!findYourAddressButton) return;

    const postcodeInput = b2cContainer.querySelector(
      '#address_PostcodeLookupInput'
    ) as HTMLInputElement;
    if (!postcodeInput) return;

    const handler = async () => {
      setPostcodeLookupError(undefined);
      const { value } = postcodeInput;

      if (value == '') {
        setPostcodeLookupError(postcodeLookupEmptyValidationMessage.current);
        return;
      }

      if (!Regex.postcode.test(value)) {
        console.warn(`Postcode '${value}' failed regex`);
        setPostcodeLookupError(postcodeLookupRegexValidationMessage.current);
        return;
      }
      setLoadingPicklist(true);
    };

    findYourAddressButton.addEventListener('click', handler);

    return () => findYourAddressButton.removeEventListener('click', handler);
  }, [b2cContainer]);

  // Set up event handler for selected address
  useEffect(() => {
    if (!b2cContainer) return;

    const addressDropDown = b2cContainer.querySelector(
      '#address_AddressDropdown'
    ) as HTMLSelectElement;

    if (!addressDropDown) return;

    const handler = async ({ target }: Event) => {
      const dropDown = target as HTMLSelectElement;
      const key = dropDown.options[dropDown.selectedIndex].value;
      setAddressKey(() => key);
      setShowDropdownError(() => false);
      setClearFormFieldsInlineError(() => true);
    };

    addressDropDown.addEventListener('input', handler);

    return () => addressDropDown.removeEventListener('input', handler);
  }, [b2cContainer, addressKey, showDropdownError, clearFormFieldsInlineError]);

  // Clear error state on input fields except address dropdown
  useEffect(() => {
    if (!b2cContainer || !clearFormFieldsInlineError) return;

    const inputFieldErrorContainers = Array.from(
      b2cContainer.querySelectorAll('input ~ .error.itemLevel')
    ) as HTMLInputElement[];

    const dropdownFieldErrorContainers = Array.from(
      b2cContainer.querySelectorAll('select ~ .error.itemLevel')
    ).filter(
      (container) => !container.classList.contains('address_AddressDropdown')
    ) as HTMLInputElement[];

    [...inputFieldErrorContainers, ...dropdownFieldErrorContainers].forEach(
      (container) => {
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }
        container.textContent = null;
        container?.setAttribute('aria-hidden', 'true');
      }
    );

    setClearFormFieldsInlineError(() => false);
  }, [b2cContainer, clearFormFieldsInlineError]);

  // Clear errors on manual address entry page inline errors
  useEffect(() => {
    if (!b2cContainer || !clearManualAddressEntryPageInilneErrors) return;

    const inputFieldErrorContainers = Array.from(
      b2cContainer.querySelectorAll('input ~ .error.itemLevel')
    ).filter(
      (container) => !container.classList.contains('address_Postcode')
    ) as HTMLInputElement[];

    inputFieldErrorContainers.forEach((container) => {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
      container.textContent = null;
      container?.setAttribute('aria-hidden', 'true');
    });

    setClearManualAddressEntryPageInilneErrors(() => false);
  }, [b2cContainer, clearManualAddressEntryPageInilneErrors]);

  // Set error summary items default visibility
  useEffect(() => {
    if (!b2cContainer) return;

    const errorSummaryContainer = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;

    if (!errorSummaryContainer) return;

    const errorSummarySkipLinks = errorSummaryContainer.querySelectorAll(
      '.tfl-alert-summary__link'
    );

    errorSummarySkipLinks.forEach((link) => {
      link.classList.remove('show');
    });
  }, [b2cContainer]);

  // Address page error summary mutation observer except address dropdown field
  useEffect(() => {
    if (!b2cContainer) return;

    const errorSummaryContainer = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;

    if (!errorSummaryContainer) return;

    let inputs = Array.from(
      b2cContainer?.querySelectorAll('input')
    ) as HTMLElement[];

    inputs = inputs.filter(
      (input) => input.id != 'address_PostcodeLookupInput'
    );

    const dropdowns = Array.from(
      b2cContainer?.querySelectorAll('select')
    ).filter(
      (container) => !container.classList.contains('address_AddressDropdown')
    ) as HTMLElement[];

    const inputErrorContainerSelectors = inputs.map(
      (input) => `.error.itemLevel.${input.id}`
    );

    const dropdownErrorContainerSelectors = dropdowns.map(
      (dropdown) => `.error.itemLevel.${dropdown?.id}`
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';
    const dropdownErrorClassname = 'tfl-dropdown__container--error';
    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    const errorContainerSelectors = [
      ...inputErrorContainerSelectors,
      ...dropdownErrorContainerSelectors,
    ];
    if (!errorContainerSelectors.length) return;

    const inputDropdownErrorContainers = errorContainerSelectors.map(
      (selector) => b2cContainer.querySelector(selector)
    );
    if (!inputDropdownErrorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const targetErrorContainer = target as Element;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const hasInlineErrorsOnThePage =
        inputDropdownErrorContainers.find(
          (container) =>
            container?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
            'false'
        ) !== undefined;

      errorSummaryContainer.setAttribute(
        'aria-hidden',
        hasInlineErrorsOnThePage ? 'false' : 'true'
      );
      errorSummaryContainer.style.display = hasInlineErrorsOnThePage
        ? 'block'
        : 'none';

      const targetSummaryItemLinkSelector =
        targetErrorContainer.attributes.getNamedItem(
          'data-summary-link'
        )?.nodeValue;

      if (!targetSummaryItemLinkSelector) return;

      let targetSummaryItemLink: HTMLElement | null | undefined = undefined;

      targetSummaryItemLink = b2cContainer?.querySelector(
        targetSummaryItemLinkSelector
      ) as HTMLElement;

      if (!targetSummaryItemLink) return;

      const dropdown =
        targetErrorContainer?.parentElement?.querySelector('select');
      const input = targetErrorContainer?.parentElement?.querySelector('input');

      const errorContainerShowingError =
        targetErrorContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      // update alert summary link
      errorContainerShowingError
        ? (targetSummaryItemLink.classList.add('show'),
          targetSummaryItemLink.classList.remove('hide'))
        : (targetSummaryItemLink.classList.add('hide'),
          targetSummaryItemLink.classList.remove('show'));

      targetSummaryItemLink.setAttribute(
        'aria-hidden',
        errorContainerShowingError ? 'false' : 'true'
      );

      const skipLink = targetSummaryItemLink.querySelector('a');

      // https://medium.com/@roxeteer/javascript-one-liner-to-get-elements-text-content-without-its-child-nodes-8e59269d1e71
      const targetErrorContainerTextContentOnly = Array.from(
        targetErrorContainer.childNodes
      ).reduce(
        (acc, node) => acc + (node.nodeType === 3 ? node.textContent : ''),
        ''
      );

      if (skipLink) {
        skipLink.textContent = errorContainerShowingError
          ? targetErrorContainerTextContentOnly
          : '';
      }

      errorContainerShowingError
        ? (dropdown?.classList.add(dropdownErrorClassname),
          input?.classList.add(textInputErrorClassname))
        : (dropdown?.classList.remove(dropdownErrorClassname),
          input?.classList.remove(textInputErrorClassname));

      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainerShowingError
          ? targetErrorContainer?.classList.add(classname)
          : targetErrorContainer?.classList.remove(classname)
      );
    };

    const disconnects: VoidFunction[] | undefined = [];

    errorContainerSelectors.forEach((selector) => {
      const { disconnect } = {
        ...useMutationObserver({
          querySelector: selector,
          callback,
        }),
      };

      typeof disconnect === 'function' && disconnects.push(disconnect);
    });

    return () => {
      disconnects?.forEach(
        (disconnect) => typeof disconnect === 'function' ?? disconnect()
      );
    };
  }, []);

  // Add mutation observer for all inputs/dropdown error states
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainersSelector = '.attrEntry .error.itemLevel';
    const errorContainers = Array.from(
      b2cContainer?.querySelectorAll(errorContainersSelector)
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';

    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (!errorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target, type } = mutation;
      const errorContainer = target as Element;
      const parentElement = errorContainer?.parentElement;
      const element =
        parentElement?.querySelector('input') ||
        parentElement?.querySelector('select');

      if (!element) return;

      const errorContainerHasTextContent =
        !!errorContainer?.textContent?.length;
      const showingError =
        errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (type === 'childList' && !errorContainerHasTextContent) {
        element?.classList.remove(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.remove(classname)
        );
        return;
      }

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      // remove default error class or ovveride style in css
      element?.classList.remove('invalid');

      element?.classList.remove(textInputErrorClassname);
      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainer?.classList.remove(classname)
      );

      if (showingError && errorContainerHasTextContent) {
        element?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.add(classname)
        );
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: errorContainersSelector,
        config: {
          childList: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);
};

export default useAddressStyling;
