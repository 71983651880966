import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

const useRemoveAsteriskFromInputLabels = () => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const inputLabels = b2cContainer.querySelectorAll('form label');

    if (!inputLabels) return;

    inputLabels.forEach((label) => {
      const inputLabelText = label.textContent;

      if (!inputLabelText) return;

      if (!inputLabelText.includes('*')) return;

      const inputLabelTextWithoutAsterisk = inputLabelText.replace(/\*/g, '');

      label.textContent = inputLabelTextWithoutAsterisk;
    });
  }, [b2cContainer]);
};

export default useRemoveAsteriskFromInputLabels;
