import { useEffect } from 'react';
import {
  useAddAlertSummary,
  useAddAlertSummaryMutationObserver,
  useB2cContainer,
  useButton,
  useEnterKeydownHandler,
  useFocusErrorSummaryScrollToTarget,
  useMfaOtpCallback,
  useMoveContinueButton,
  useMoveErrorContainerBelowInput,
  useMutationObserver,
  useRemoveAttribute,
  useRemoveElement,
  useStyleInputs,
} from '../../hooks';

import './ProfileEditVerifyEmailAddress.scss';

const emailVerificationControlInfoMessageSelector =
  '#emailVerificationControl_info_message';

const ProfileEditVerifyEmailAddress = () => {
  const [b2cContainer] = useB2cContainer();
  const [callback] = useMfaOtpCallback();

  useStyleInputs({
    otherInputContainerSelector:
      '#emailVerificationControl .TextBox .attrEntry',
  });

  // Add intro text styles
  useEffect(() => {
    if (!b2cContainer) return;

    const introParagraph = b2cContainer.querySelector(
      '.intro p'
    ) as HTMLParagraphElement;

    if (!introParagraph) return;

    const headingElement = b2cContainer.querySelector('.heading h1');

    if (!headingElement) return;

    headingElement.textContent = introParagraph.innerText;
    introParagraph.remove();
  }, [b2cContainer]);

  // Show emailVerificationControl_info_message
  useEffect(() => {
    if (!b2cContainer) return;

    const emailVerificationControlInfoMessage = b2cContainer.querySelector(
      emailVerificationControlInfoMessageSelector
    ) as HTMLElement;
    if (!emailVerificationControlInfoMessage) return;
    emailVerificationControlInfoMessage.style.display = 'block';
    emailVerificationControlInfoMessage.setAttribute('aria-hidden', 'false');
  }, [b2cContainer]);

  // Add mutation observer to reset emailVerificationControl_info_message display when B2C client side api hides it due input changes
  useEffect(() => {
    if (!b2cContainer) return;

    const callback = (mutation: MutationRecord) => {
      const { target } = mutation;
      const element = target as HTMLElement;

      if (!element) return;

      if (element.style.display !== 'block') {
        element.style.display = 'block';
      }

      if (element.getAttribute('aria-hidden') !== 'false') {
        element.setAttribute('aria-hidden', 'false');
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: emailVerificationControlInfoMessageSelector,
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Add send verification code button styles
  useButton({
    selector: '.verificationControlContent .buttons .sendCode',
    type: 'button',
    variant: 'primary',
  });

  useMoveErrorContainerBelowInput({
    selectors: ['.TextBox .attrEntry .error'],
  });

  useAddAlertSummary();

  useAddAlertSummaryMutationObserver();

  useRemoveElement({ selectors: ['#emailVerificationControl_label'] });

  // Add verify code button styles
  useButton({
    selector: 'emailVerificationControl_but_verify_code',
    type: 'button',
    variant: 'primary',
  });

  // Add send new code button styles
  useButton({
    selector: 'emailVerificationControl_but_send_new_code',
    type: 'button',
    variant: 'secondary',
  });

  // Add continue button styles
  useButton({
    selector: 'continue',
    type: 'button',
    variant: 'primary',
  });

  // Add mutation observer for all inputs error states
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainersSelector = '.attrEntry .error.itemLevel';
    const errorContainers = Array.from(
      b2cContainer?.querySelectorAll(errorContainersSelector)
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';

    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (!errorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target, type } = mutation;
      const errorContainer = target as Element;
      const parentElement = errorContainer?.parentElement;
      const element = parentElement?.querySelector('input');

      if (!element) return;

      const errorContainerHasTextContent =
        !!errorContainer?.textContent?.length;
      const showingError =
        (errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
          'false') !==
        undefined;

      if (type === 'childList' && !errorContainerHasTextContent) {
        element?.classList.remove(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.remove(classname)
        );
        return;
      }

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      // remove default error class or ovveride style in css
      element?.classList.remove('invalid');

      element?.classList.remove(textInputErrorClassname);
      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainer?.classList.remove(classname)
      );

      if (showingError && errorContainerHasTextContent) {
        element?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.add(classname)
        );
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: errorContainersSelector,
        config: {
          childList: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Set focus to error summary on form submission failure
  useFocusErrorSummaryScrollToTarget({
    buttonSelectors: ['#continue', '#emailVerificationControl_but_verify_code'],
  });

  useMoveContinueButton();

  // Hide continue button on landing page
  useEffect(() => {
    if (!b2cContainer) return;

    const continueButton = b2cContainer.querySelector(
      '#continue'
    ) as HTMLElement;
    const sendVerificationButton = b2cContainer?.querySelector(
      '#emailVerificationControl_but_send_code'
    );

    if (!sendVerificationButton) return;

    if (continueButton) {
      continueButton.setAttribute('aria-hidden', 'true');
      continueButton.style.display = 'none';
    }
  }, [b2cContainer]);

  // Move and append all buttons to bottom of the card
  useEffect(() => {
    if (!b2cContainer) return;

    const buttonsContainer = b2cContainer.querySelector(
      '#emailVerificationControl_but_send_code'
    )?.parentNode;

    if (!buttonsContainer) return;

    const form = b2cContainer?.querySelector('#attributeVerification');
    form?.appendChild(buttonsContainer);
  }, [b2cContainer]);

  // Move verification message above enter code input label
  useEffect(() => {
    if (!b2cContainer) return;

    const verificationSuccessContainer = b2cContainer.querySelector(
      '.verificationSuccessText'
    );

    const enterCodeContainer = b2cContainer.querySelector(
      '#emailVerificationCode_label'
    )?.parentNode;

    if (!verificationSuccessContainer) return;

    enterCodeContainer?.prepend(verificationSuccessContainer);
  }, [b2cContainer]);

  // Add mutation observer, on successful code verificaition, to
  // - show continue button, code input and success icon ✅
  useEffect(() => {
    const otpCodeSuccessMessageContainerSelector =
      '#emailVerificationControl_success_message';
    const verifyCodeButtonSelector =
      '#emailVerificationControl_but_verify_code';

    const verifyCodeButton = b2cContainer?.querySelector(
      verifyCodeButtonSelector
    );

    const passwordResetVerificationCodeContainer = b2cContainer?.querySelector(
      '.TextBox.VerificationCode'
    ) as HTMLElement;

    const continueButton = b2cContainer?.querySelector(
      '#continue'
    ) as HTMLElement;

    if (!passwordResetVerificationCodeContainer || !verifyCodeButton) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const otpCodeSuccessMessageContainer = target as HTMLElement;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const otpCodeSuccessMessageShowing =
        otpCodeSuccessMessageContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const hasOtpCodeSuccessMessage = [
        'The code has been verified',
        'Your code has been verified',
      ].some((message) =>
        otpCodeSuccessMessageContainer?.textContent?.includes(message)
      );

      const passwordResetVerificationCodeInput = b2cContainer?.querySelector(
        '#emailVerificationCode'
      );

      if (otpCodeSuccessMessageShowing && hasOtpCodeSuccessMessage) {
        passwordResetVerificationCodeInput?.classList.remove(
          'tfl-text-input__input--loading'
        );
        passwordResetVerificationCodeContainer.style.display = 'block';
        passwordResetVerificationCodeContainer.setAttribute(
          'aria-hidden',
          'false'
        );

        passwordResetVerificationCodeInput?.classList.add(
          'tfl-text-input__input--success'
        );

        if (otpCodeSuccessMessageShowing) {
          continueButton.setAttribute('aria-hidden', 'false');
          continueButton.removeAttribute('disabled');
          continueButton.style.display = 'block';
          continueButton.classList.remove('visuallyhidden');
        }

        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otpCodeSuccessMessageContainerSelector,
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // add loader to email address input field after user clicks 'send verification code'
  useEffect(() => {
    if (!b2cContainer) return;

    const sendVerificationButtonSelector =
      '#emailVerificationControl_but_send_code';
    const verificationCodeInput = b2cContainer?.querySelector(
      '#emailVerificationCode'
    );
    const verificationCodeInputContainer =
      b2cContainer?.querySelector('.VerificationCode');
    const continueButton = b2cContainer?.querySelector('#continue');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const sendVerificationButton = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const sendVerificationButtonHidden =
        sendVerificationButton?.attributes?.getNamedItem('aria-hidden')
          ?.nodeValue === 'true';
      const claimVerificationServerError = b2cContainer.querySelector(
        '#claimVerificationServerError'
      );

      const claimVerificationServerErrorThrown =
        claimVerificationServerError?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const codeInputVisible =
        verificationCodeInputContainer?.attributes?.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const continueButtonVisible =
        continueButton?.attributes?.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (!sendVerificationButtonHidden) return;

      if (
        continueButtonVisible ||
        codeInputVisible ||
        claimVerificationServerErrorThrown
      ) {
        verificationCodeInput?.classList.remove(
          'tfl-text-input__input--loading'
        );
        return;
      }

      verificationCodeInput?.classList.add('tfl-text-input__input--loading');
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: sendVerificationButtonSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // remove loader from email adress input field when error summary is displayed
  useEffect(() => {
    if (!b2cContainer) return;

    const alertSummarySelector = '#requiredFieldMissing';
    const emailAddressInput = b2cContainer?.querySelector('#signInName');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const alertSummary = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const errorSummaryVisible =
        alertSummary?.attributes?.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (!alertSummary) return;

      if (errorSummaryVisible) {
        emailAddressInput?.classList.remove('tfl-text-input__input--loading');
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: alertSummarySelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, []);

  // Add mutation observer to show continue button & code input box & disable email input loader
  useEffect(() => {
    const otpCodeSuccessMessageContainerSelector =
      '#emailVerificationControl_success_message';
    const verifyCodeButtonSelector =
      '#emailVerificationControl_but_verify_code';

    const verifyCodeButton = b2cContainer?.querySelector(
      verifyCodeButtonSelector
    );

    const mfaVerificationCodeContainer = b2cContainer?.querySelector(
      '.TextBox.VerificationCode'
    ) as HTMLElement;

    if (!mfaVerificationCodeContainer || !verifyCodeButton) return;

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otpCodeSuccessMessageContainerSelector,
        callback: (mutation: MutationRecord) =>
          callback(mutation, '#emailVerificationCode'),
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Take user back to landing page when try to edit email address
  useEffect(() => {
    if (!b2cContainer) return;

    const emailAddressInput = b2cContainer.querySelector('#signInName');
    const continueButton = b2cContainer?.querySelector(
      '#continue'
    ) as HTMLElement;
    const verifyCodeButton = b2cContainer?.querySelector(
      '#emailVerificationControl_but_verify_code'
    ) as HTMLElement;
    const resendCodeButton = b2cContainer?.querySelector(
      '#emailVerificationControl_but_send_new_code'
    ) as HTMLElement;
    const sendVerificationCodeButton = b2cContainer.querySelector(
      '#emailVerificationControl_but_send_code'
    ) as HTMLElement;
    const verficationCodeInputContainer = b2cContainer?.querySelector(
      '.TextBox.VerificationCode'
    ) as HTMLElement;
    const verificationCodeInput = b2cContainer?.querySelector(
      '#emailVerificationCode'
    ) as HTMLElement;

    const handler = (event: Event) => {
      const emailAddressInput = event.target as HTMLElement;
      if (!emailAddressInput) return;

      const userIsAlreadyOnLandingPage =
        window.getComputedStyle(sendVerificationCodeButton, null)?.display ===
        'block';

      if (userIsAlreadyOnLandingPage) return;

      sendVerificationCodeButton.style.display = 'block';
      sendVerificationCodeButton.setAttribute('aria-hidden', 'false');

      verificationCodeInput.removeAttribute('disabled');
      verificationCodeInput.textContent = '';
      verificationCodeInput.classList.remove(
        'tfl-text-input__input--success',
        'tfl-text-input__input--loading'
      );

      [
        verficationCodeInputContainer,
        verifyCodeButton,
        resendCodeButton,
        continueButton,
      ].forEach((element) => {
        element.style.display = 'none';
        element.setAttribute('aria-hidden', 'true');
      });
    };

    emailAddressInput?.addEventListener('input', handler);

    return () => emailAddressInput?.removeEventListener('input', handler);
  }, [b2cContainer]);

  // Remove change button (This button can't be removed from the policy)
  useEffect(() => {
    if (!b2cContainer) return;

    const changeEmailAddressButton = document.querySelector(
      '#emailVerificationControl_but_change_claims'
    );
    changeEmailAddressButton?.remove();
  }, [b2cContainer]);

  // Copy other email/verify code error message to inline error container
  useEffect(() => {
    if (!b2cContainer) return;

    const otherErrorMessageContainerSelector =
      '#emailVerificationControl_error_message';
    const otherErrorMessageContainer = b2cContainer.querySelector(
      otherErrorMessageContainerSelector
    );

    if (!otherErrorMessageContainer) return;

    // We can't remove it from DOM so just hide it
    otherErrorMessageContainer?.classList.add('visuallyhidden');

    const emailContainer = b2cContainer.querySelector('.TextBox.signInName');
    const emailInlineErrorContainer =
      emailContainer?.querySelector('.error.itemLevel');

    const verificationCodeContainer = b2cContainer.querySelector(
      '.TextBox.VerificationCode'
    );
    const verificationCodeInlineErrorContainer =
      verificationCodeContainer?.querySelector('.error.itemLevel');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const otherErrorMessageContainer = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const otherErrorMessageContainerShowing =
        otherErrorMessageContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const textContent = otherErrorMessageContainer?.textContent;

      const otherErrorMessageContainerHasTextContent = !!textContent?.length;

      if (
        !otherErrorMessageContainerShowing ||
        !otherErrorMessageContainerHasTextContent
      )
        return;

      const verificationCodeContainerShowing =
        verificationCodeContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      if (
        verificationCodeContainerShowing &&
        verificationCodeInlineErrorContainer
      ) {
        verificationCodeInlineErrorContainer.textContent = textContent;
        verificationCodeInlineErrorContainer.setAttribute(
          'aria-hidden',
          'false'
        );
        return;
      }

      const emailContainerShowing =
        emailContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (emailContainerShowing && emailInlineErrorContainer) {
        emailInlineErrorContainer.textContent = textContent;
        emailInlineErrorContainer.setAttribute('aria-hidden', 'false');
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otherErrorMessageContainerSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Remove inline style attribute from all list items
  useRemoveAttribute({
    attribute: 'style',
    selector: 'li[style*="display: inline"]',
  });

  // Handle enter key press on email address input field when performing email address update (send code)
  useEnterKeydownHandler({
    inputFieldSelector: '#signInName',
    targetButtonSelector: '#emailVerificationControl_but_send_code',
  });

  // Handle enter key press on email address input field when performing email address update (verify code)
  useEnterKeydownHandler({
    inputFieldSelector: '#emailVerificationCode',
    targetButtonSelector: '#emailVerificationControl_but_verify_code',
  });

  return <></>;
};

export default ProfileEditVerifyEmailAddress;
