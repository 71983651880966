import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

const useSubmitButtonLoadingSpinner = (selectors?: string[]) => {
  const [b2cContainer] = useB2cContainer();

  const IsFormValid = () => {
    if (!b2cContainer) return false;

    const inlineInputErrorContainers = Array.from(
      b2cContainer.querySelectorAll('.error.itemLevel')
    );

    const result = !inlineInputErrorContainers.find(
      (container) =>
        container?.attributes.getNamedItem('aria-hidden')?.nodeValue === 'false'
    );

    return result;
  };

  useEffect(() => {
    if (!b2cContainer) return;

    const submitButtons = b2cContainer.querySelectorAll(
      'button[type="submit"]'
    );

    if (!submitButtons?.length) return;

    let timeoutID: number;
    const submitButtonhandler = (event: Event) => {
      if (!IsFormValid()) return;

      const target = event.target as HTMLButtonElement;

      if (!target) return;

      target.classList.add(`tfl-button-submit--loading`);
      target.setAttribute('disabled', 'disabled');

      timeoutID = window.setTimeout(
        () => {
          target.classList.remove(`tfl-button-submit--loading`);
          target.removeAttribute('disabled');
        },
        3000,
        [target]
      );
    };

    submitButtons.forEach((button) =>
      button?.addEventListener('click', submitButtonhandler)
    );

    let otherTargets: HTMLElement[] = [];

    if (selectors?.length) {
      otherTargets = selectors?.map((selector) =>
        b2cContainer.querySelector(selector)
      ) as HTMLElement[];

      otherTargets.forEach((button) =>
        button?.addEventListener('click', submitButtonhandler)
      );
    }

    return () => {
      submitButtons.forEach((button) =>
        button?.removeEventListener('click', submitButtonhandler)
      );

      otherTargets?.forEach((button) =>
        button?.removeEventListener('click', submitButtonhandler)
      );

      window.clearTimeout(timeoutID);
    };
  }, [b2cContainer]);
};

export default useSubmitButtonLoadingSpinner;
