import { useEffect } from 'react';
import { useB2cContainer } from '.';

type AddAtrributeProps = { selector: string; attribute: string; value: string };

const useAddAttribute = ({ selector, attribute, value }: AddAtrributeProps) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    if (!selector || !attribute) return;

    const element = b2cContainer.querySelector(selector);

    if (!element) return;

    element.setAttribute(attribute, value);
  }, [b2cContainer]);
};

export default useAddAttribute;
