import { useEffect } from 'react';
import {
  useAddAlertSummary,
  useAddAlertSummaryMutationObserver,
  useAddAttribute,
  useB2cContainer,
  useButton,
  useEmptyParagraphRemover,
  useFocusErrorSummaryScrollToTarget,
  useHtml5ValidationRemover,
  useIntroText,
  useMoveButtons,
  useMoveErrorContainerBelowInput,
  useMutationObserver,
  useRemoveElement,
  useStyleDropdown,
  useStyleInputs,
} from '../../hooks';
import { ButtonProps } from '../../hooks/useButton';

const ProfileEditConfirmSecurityQuestion = () => {
  const [b2cContainer] = useB2cContainer();

  useIntroText();

  useEmptyParagraphRemover();

  useHtml5ValidationRemover();

  useAddAlertSummary();

  useStyleDropdown();

  useStyleInputs();

  useAddAttribute({
    selector: '#securityQuestion_DropdownList',
    attribute: 'disabled',
    value: 'disabled',
  });

  useMoveButtons('#api > .buttons');

  useRemoveElement({
    selectors: [
      '#fieldIncorrect',
      '#securityDetails_ConfirmAnswerText_label',
      '#confirmSecurityQuestion_DropdownLabelText_label',
    ],
  });

  useMoveErrorContainerBelowInput({
    selectors: [
      '#attributeList .attrEntry .error.itemLevel',
      '#attributeList .TextBox .error.itemLevel',
    ],
  });

  (
    [
      {
        selector: 'continue',
        type: 'button',
        variant: 'primary',
      },
      {
        selector: 'cancel',
        type: 'button',
        variant: 'secondary',
      },
    ] as ButtonProps[]
  ).forEach((button) => useButton(button));

  useFocusErrorSummaryScrollToTarget({
    buttonSelectors: ['#continue'],
  });

  // Set confirm question dropdown label
  useEffect(() => {
    if (!b2cContainer) return;

    const dropdownLabelTextContainer = b2cContainer.querySelector(
      '#confirmSecurityQuestion_DropdownLabelText'
    );

    if (!dropdownLabelTextContainer) return;

    const dropdownLabelText = dropdownLabelTextContainer.textContent;

    if (!dropdownLabelText) return;

    const dropdownLabel = b2cContainer.querySelector(
      '#securityQuestion_DropdownList_label'
    );

    if (!dropdownLabel) return;

    dropdownLabel.textContent = dropdownLabelText;

    dropdownLabelTextContainer.closest('li')?.remove();
  }, [b2cContainer]);

  // Add mutation observers below this line
  useAddAlertSummaryMutationObserver();

  // Add mutation observer for all inputs/dropdown error states
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainersSelector = '.attrEntry .error.itemLevel';
    const errorContainers = Array.from(
      b2cContainer?.querySelectorAll(errorContainersSelector)
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';

    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (!errorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target, type } = mutation;
      const errorContainer = target as Element;
      const parentElement = errorContainer?.parentElement;
      const element =
        parentElement?.querySelector('input') ||
        parentElement?.querySelector('select');

      if (!element) return;

      const errorContainerHasTextContent =
        !!errorContainer?.textContent?.length;
      const showingError =
        errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (type === 'childList' && !errorContainerHasTextContent) {
        element?.classList.remove(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.remove(classname)
        );
        return;
      }

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      // remove default error class or ovveride style in css
      element?.classList.remove('invalid');

      element?.classList.remove(textInputErrorClassname);
      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainer?.classList.remove(classname)
      );

      if (showingError && errorContainerHasTextContent) {
        element?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.add(classname)
        );
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: errorContainersSelector,
        config: {
          childList: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  return <></>;
};

export default ProfileEditConfirmSecurityQuestion;
