import { useB2cContainer } from '.';

const useHasProfileEditMarketingPreferencesStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasProfileEditMarketingPreferencesStep =
    b2cContainer?.querySelector('#editTermsOfUseConsentChoice_None') !== null;

  return [hasProfileEditMarketingPreferencesStep];
};

export default useHasProfileEditMarketingPreferencesStep;
