export const Policies = {
  Mfa: 'b2c_1a_mfa',
  PasswordReset: 'b2c_1a_passwordreset',
  PasswordResetOnSignInSignUp: 'b2c_1a_passwordreset_on_signin_signup',
  ProfileEdit: 'b2c_1a_profileedit',
  SignInLegacy: 'b2c_1a_signin_legacy',
  SignInB2c: 'b2c_1a_signin_b2c',
  SignIn: 'b2c_1a_signin',
  SignUp: 'b2c_1a_register',
  Unknown: 'unknown',
};

export const CombinedPolicies = {
  SelfAsserted: 'selfasserted',
  SignInSignUp: 'combinedsigninandsignup',
};
