import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

const usePlaceHolderRemover = () => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;
    // Remove hint/placeholder text from input boxes, can't do in the policy as it drives label text
    const inputs = b2cContainer.querySelectorAll('input');
    inputs.forEach((input) => {
      input.removeAttribute('title');
      input.removeAttribute('placeholder');
    });
  }, [b2cContainer]);
};

export default usePlaceHolderRemover;
