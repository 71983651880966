import './ProfileEditMarketingPreferences.scss';
import { useEffect } from 'react';
import {
  useB2cContainer,
  useButton,
  useEmptyParagraphRemover,
  useIntroText,
  useMoveButtons,
  useStyleSubText,
} from '../../hooks';

const ProfileEditMarketingPreferences = () => {
  const [b2cContainer] = useB2cContainer();

  useIntroText();

  useEmptyParagraphRemover();

  useMoveButtons('#api > .buttons');

  useButton({
    selector: 'continue',
    type: 'button',
    variant: 'primary',
  });

  useButton({
    selector: 'cancel',
    type: 'button',
    variant: 'secondary',
  });

  useStyleSubText({ selectors: ['.tfl-page-heading'] });

  useEffect(() => {
    if (!b2cContainer) return;

    // Remove the empty .intro div
    const introDiv = b2cContainer?.querySelector('div.intro');
    introDiv?.remove();
  }, [b2cContainer]);

  useEffect(() => {
    if (!b2cContainer) return;

    // Restyle the header

    const headerParagraph = b2cContainer?.querySelector(
      '.Paragraph .attrEntry'
    ) as HTMLElement;
    if (!headerParagraph) return;
    headerParagraph.classList.add('tfl-market-prefs-header');

    const textInParagraph = headerParagraph.querySelector(
      '.textInParagraph'
    ) as HTMLElement;
    if (!textInParagraph) return;

    const textIntro = textInParagraph.innerText;
    const parent = textInParagraph.parentElement;
    textInParagraph.remove();

    const heading = document.createElement('H2');
    const subheadingPattern = /(\(.*\))/;
    const subheadingMatch = textIntro.match(subheadingPattern);
    heading.textContent = textIntro.replace(subheadingPattern, '');
    heading.setAttribute('data-original-text', textIntro);
    heading.classList.add('tfl-intro');
    if (subheadingMatch) {
      const subheading = document.createElement('SPAN');
      subheading.classList.add('tfl-subheading');
      subheading.innerText = subheadingMatch[0];
      heading.append(subheading);
    }

    parent?.append(heading);
  }, [b2cContainer]);

  useEffect(() => {
    if (!b2cContainer) return;

    // move the server error to below intro text
    const serverErrorContainer = b2cContainer?.querySelector(
      '#claimVerificationServerError'
    ) as HTMLElement;

    if (!serverErrorContainer) return;

    const introContainer = b2cContainer?.querySelector(
      '#attributeList .Paragraph'
    ) as HTMLElement;
    if (!introContainer) return;

    serverErrorContainer.remove();
    introContainer.append(serverErrorContainer);
  }, [b2cContainer]);

  useEffect(() => {
    if (!b2cContainer) return;

    // Hide the extra checkbox container
    const hiddenCheckboxContainer = b2cContainer?.querySelector(
      'div.attrEntry #editTermsOfUseConsentChoice_None'
    ) as HTMLElement;
    if (
      hiddenCheckboxContainer?.parentNode &&
      (hiddenCheckboxContainer.parentNode as HTMLElement)
    )
      (hiddenCheckboxContainer.parentNode as HTMLElement).style.display =
        'none';
  }, [b2cContainer]);

  useEffect(() => {
    // Add component library classes to checkbox components and put them
    // in compatible container
    if (!b2cContainer) return;

    const checkboxOffersFromTfl = b2cContainer?.querySelector(
      '#editTermsOfUseConsentChoice_AgreeToReceiveOffersFromTfLYes'
    );
    checkboxOffersFromTfl?.classList.add('tfl-checkbox__input');

    const labelOffersFromtfl = b2cContainer?.querySelector(
      '#AgreeToReceiveOffersFromTfLYes_option'
    );
    labelOffersFromtfl?.classList.add('tfl-checkbox__label');

    const checkboxOffersFromToc = b2cContainer?.querySelector(
      '#editTermsOfUseConsentChoice_AgreeToReceiveOffersFromTocYes'
    );
    checkboxOffersFromToc?.classList.add('tfl-checkbox__input');

    const labelOffersFromToc = b2cContainer?.querySelector(
      '#AgreeToReceiveOffersFromTocYes_option'
    );
    labelOffersFromToc?.classList.add('tfl-checkbox__label');

    const checkboxMultiSelect = b2cContainer?.querySelector(
      '.CheckboxMultiSelect .attrEntry'
    );

    const tflPromotionContainer = document.createElement('DIV');
    tflPromotionContainer.classList.add('tfl-checkbox');

    const tocPromotionContainer = document.createElement('DIV');
    tocPromotionContainer.classList.add('tfl-checkbox');

    checkboxMultiSelect?.parentNode?.appendChild(tflPromotionContainer);
    checkboxMultiSelect?.parentNode?.appendChild(tocPromotionContainer);

    if (
      !checkboxOffersFromTfl ||
      !checkboxOffersFromToc ||
      !labelOffersFromtfl ||
      !labelOffersFromToc
    )
      return;

    tocPromotionContainer.prepend(labelOffersFromToc);
    tocPromotionContainer.prepend(checkboxOffersFromToc);
    tflPromotionContainer.prepend(labelOffersFromtfl);
    tflPromotionContainer.prepend(checkboxOffersFromTfl);
  }, [b2cContainer]);

  return <></>;
};

export default ProfileEditMarketingPreferences;
