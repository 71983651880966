import { useEffect } from 'react';
import { useB2cContainer } from '.';

const useMoveHeading = () => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const form = b2cContainer.querySelector('form');
    const headingContainer = b2cContainer.querySelector('.heading');

    if (!headingContainer) return;

    form?.prepend(headingContainer);
  }, [b2cContainer]);
};

export default useMoveHeading;
