import React from 'react';
import { Card, Container, Row } from 'react-bootstrap';
import { CommonActions } from '..';
import './B2cCard.scss';
const B2cCard = () => {
  return (
    <Container className="tfl-auth-container" fluid>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Card
            id="b2c-react-container"
            className="tfl-b2c-container border-0 col-md-6 col-lg-4"
          />
          <CommonActions />
        </Row>
      </Container>
    </Container>
  );
};

export default B2cCard;
