import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

export interface IUseEnterKeydownHandlerParams {
  inputFieldSelector: string;
  targetButtonSelector: string;
}

/**
 * Adds a `Keydown` event listener to an input text field that fires a click event on target button control,
 * when an `Enter` key is pressed.
 * @param {Object} IUseEnterKeydownHandlerParams
 * @property {string} inputFieldSelector DOM selector for an input field
 * @property {string} targetButtonSelector DOM selector for a button control
 * @returns void
 */
const useEnterKeydownHandler = ({
  inputFieldSelector,
  targetButtonSelector,
}: IUseEnterKeydownHandlerParams) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const targetInput = b2cContainer?.querySelector(
      inputFieldSelector
    ) as HTMLInputElement;

    const targetButton = b2cContainer?.querySelector(
      targetButtonSelector
    ) as HTMLButtonElement;

    if (!targetInput)
      console.warn(
        `useEnterKeydownHandler: cannot find input element with selector "${inputFieldSelector}"`
      );

    if (!targetButton)
      console.warn(
        `useEnterKeydownHandler: cannot find button element with selector "${targetButtonSelector}"`
      );

    if (!targetInput || !targetButton) return;

    const handler = (event: KeyboardEvent): void => {
      const { key } = event;

      if (key !== 'Enter') return;

      event.preventDefault();

      targetButton?.click();
    };

    targetInput?.addEventListener('keydown', handler);

    return () => targetInput?.removeEventListener('keydown', handler);
  }, [b2cContainer]);
};

export default useEnterKeydownHandler;
