import { useEffect } from 'react';
import { useB2cContainer } from '.';
import { typedBoolean } from '../types/tfl-types';

const useAddAlertSummary = () => {
  const [b2cContainer] = useB2cContainer();

  // Add Alert Summary
  useEffect(() => {
    if (!b2cContainer) return;

    const errorSummaryHeader = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;

    if (!errorSummaryHeader) return;

    errorSummaryHeader?.classList.add('tfl-alert', 'tfl-alert--error');
    errorSummaryHeader?.setAttribute('aria-labelledby', 'tfl-alert-heading');

    const errorSummaryText = errorSummaryHeader.innerText;
    errorSummaryHeader.innerText = '';

    const alertContainerH2 = document.createElement('H2');
    alertContainerH2?.classList.add(
      'tfl-alert__header',
      'tfl-alert__header--with-description'
    );
    errorSummaryHeader?.prepend(alertContainerH2);
    alertContainerH2.textContent = errorSummaryText;
    alertContainerH2.id = 'tfl-alert-heading';

    // Add UL links
    const alertContent = document.createElement('DIV');
    alertContent.classList.add('tfl-alert__content');

    errorSummaryHeader.prepend(alertContent);
    errorSummaryHeader.insertBefore(alertContainerH2, alertContent);

    const inputInlineErrorContainers = Array.from(
      b2cContainer.querySelectorAll('#attributeList .error.itemLevel')
    );

    const errorSummaryLinks = inputInlineErrorContainers.map((container) => {
      // only interested in container with a sibling INPUT or SELECT element
      const targetControl = Array.from(
        container?.parentNode?.children ?? []
      ).find((c) => c.tagName === 'INPUT' || c.tagName === 'SELECT');

      if (!targetControl?.id) return;

      const link = document.createElement('A');
      link.id = `skip-link-${targetControl?.id}`;
      link.setAttribute('href', `#${targetControl?.id}`);
      link.classList.add('tfl-alert-summary__link-text');
      link.textContent = container.textContent;

      link.addEventListener('click', (event) => {
        event.preventDefault();
        const link = event.target as HTMLElement;
        const targetInputId = link.getAttribute('href');
        if (!targetInputId) return;
        const targetInput = b2cContainer.querySelector(
          targetInputId
        ) as HTMLElement;
        targetInput?.focus();
      });

      const errorSummaryLink = {
        targetId: `${targetControl?.id}`,
        element: link,
      };

      return errorSummaryLink;
    });

    const ulLinks = document.createElement('UL');
    ulLinks.classList.add('tfl-alert-summary');
    alertContent.prepend(ulLinks);

    errorSummaryLinks.filter(typedBoolean).forEach((link) => {
      const listItem = document.createElement('LI');
      listItem.classList.add(`tfl-alert-summary__link`, link?.targetId, 'hide');
      listItem.appendChild(link.element);

      ulLinks.appendChild(listItem);
    });
  }, [b2cContainer]);
};

export default useAddAlertSummary;
