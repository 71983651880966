import { useB2cContainer } from '.';

const useHasProfileEditVerifyEmailAddressStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasProfileEditVerifyEmailAddress =
    b2cContainer?.querySelector('#emailVerificationControl_but_send_code') !==
    null;

  return [hasProfileEditVerifyEmailAddress];
};

export default useHasProfileEditVerifyEmailAddressStep;
