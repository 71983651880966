import useB2cContainer from './useB2cContainer';

const useHasConfirmUpdateContactMobileNumber = () => {
  const [b2cContainer] = useB2cContainer();

  const hasConfirmUpdateContactMobileNumber =
    b2cContainer?.querySelector('#yourDetailsParagraphText') !== null &&
    b2cContainer?.querySelector('#yourDetailsSecondParagraphText') !== null;

  return [hasConfirmUpdateContactMobileNumber];
};

export default useHasConfirmUpdateContactMobileNumber;
