import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

const useAddPasscodeSecuritySpan = () => {
  const [b2cContainer] = useB2cContainer();

  // Add description to create a passcode label
  useEffect(() => {
    if (!b2cContainer) return;

    const securityInput = b2cContainer.querySelector(
      '#securityPasscode_Answer'
    );

    const securityInputLabel = b2cContainer.querySelector(
      '#securityPasscode_Answer_label'
    );

    const securityDetailsParagraph = b2cContainer.querySelector(
      '#securityDetails_SecondParagraphText'
    ) as HTMLParagraphElement;

    if (!securityInputLabel || !securityDetailsParagraph) return;

    const securitySpanAlreadyExists =
      b2cContainer.querySelector('#tfl-security-description-span') !== null;

    if (securitySpanAlreadyExists) return;

    const descriptionLabelSpan = document.createElement('span');
    descriptionLabelSpan.id = 'tfl-security-description-span';
    descriptionLabelSpan.classList.add('tfl-security-description-span');
    descriptionLabelSpan.textContent = securityDetailsParagraph?.textContent;
    securityDetailsParagraph.textContent = '';
    securityDetailsParagraph.style.display = 'none';

    [securityInputLabel, securityInput].forEach((element) => {
      if (!element) return;
      element.setAttribute('aria-describedby', `${descriptionLabelSpan.id}`);
    });

    securityInput?.setAttribute(
      'aria-label',
      `${securityInputLabel.textContent}`
    );

    securityInputLabel.after(descriptionLabelSpan);
  }, [b2cContainer]);
};

export default useAddPasscodeSecuritySpan;
