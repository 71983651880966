import { Regex } from '../constants';

type ValidationPatternItem = {
  evaluate: (passcode: string) => boolean;
  control: {
    icon: HTMLElement | null;
    description: HTMLElement | null;
  };
};

type ValidationPattern = {
  exactlySixNumbers: ValidationPatternItem;
  notThreeInaRow: ValidationPatternItem;
  nonConsecutive: ValidationPatternItem;
};

const usePasscode = (): {
  validate: (target: HTMLInputElement) => boolean;
} => {
  const a11yMeetCriteriaSpan = 'Your passcode meets all necessary criterias.';
  const a11yDoesNotMeetCriteriaSpan =
    'Your passcode does not meet following criteria;';
  const a11ySpan = document.querySelector('.tfl-passcode-a11y');

  const selector = {
    exactlySixNumbers: 'tfl-passcode-length',
    notThreeInaRow: 'tfl-passcode-number-repetition',
    nonConsecutive: 'tfl-passcode-no-consecutive-number',
  };

  const patterns: ValidationPattern = {
    exactlySixNumbers: {
      evaluate: (value: string) =>
        Regex.number.test(value) && value.length === 6,
      control: {
        icon: document.querySelector(`.${selector.exactlySixNumbers}`),
        description: document.querySelector(`#${selector.exactlySixNumbers}`),
      },
    },
    notThreeInaRow: {
      evaluate: (value: string) => {
        return (
          Regex.number.test(value) &&
          !Regex.numberRepeatedThreeTimesInaRow.test(value)
        );
      },
      control: {
        icon: document.querySelector(`.${selector.notThreeInaRow}`),
        description: document.querySelector(`#${selector.notThreeInaRow}`),
      },
    },
    nonConsecutive: {
      evaluate: (value: string) =>
        Regex.number.test(value) &&
        !Regex.consecutiveNumbersUpToSix.test(value),
      control: {
        icon: document.querySelector(`.${selector.nonConsecutive}`),
        description: document.querySelector(`#${selector.nonConsecutive}`),
      },
    },
  };

  setInputAriaDescribedByDescription(patterns);

  let classes = {
    default: 'tfl-passcode-match-icon--default',
    success: 'tfl-passcode-match-icon--success',
  };

  const success = (classList: DOMTokenList | undefined) => (
    classList?.add(classes.success), classList?.remove(classes.default)
  );

  const reset = (classList: DOMTokenList | undefined): void => (
    classList?.add(classes.default), classList?.remove(classes.success)
  );

  const evaluate = (
    { evaluate, control }: ValidationPatternItem,
    value: string
  ) => ({
    result: evaluate(value),
    control,
  });

  const validatePasscodeInputEventHandler = (
    target: HTMLInputElement
  ): boolean => {
    const value = target?.value;

    // if use clicks confirm button we set default icon class to ❌ in html5 data attribute
    classes = Object.assign(classes, {
      default: target?.dataset?.defaultIconClass,
    });

    // reset icons to default
    if (!value?.length) {
      Object.values(selector)
        .map((selector) => document?.querySelector(`.${selector}`)?.classList)
        .forEach(reset);
    }

    // set ✅ / 🔘 / ❌ icons
    const validationDetail = Object.values(patterns)
      .map((pattern) => evaluate(pattern, value))
      .map(
        ({ result, control }) => (
          result
            ? success(control.icon?.classList)
            : reset(control.icon?.classList),
          { result, control }
        )
      );

    // set validation result
    const result =
      validationDetail.find(({ result }) => result === false) === undefined;

    if (!a11ySpan) return result;

    if (result) {
      target?.classList.add('tfl-text-input__input--success');
      updateInputAriaDescribedByDescription(a11yMeetCriteriaSpan);
      return result;
    }

    // passcode does not meet criteria
    target?.classList.remove('tfl-text-input__input--success');

    a11ySpan.setAttribute('aria-live', 'assertive');

    // Prepare a11y message from validationDetail
    const unmetCriterion = validationDetail
      .filter(({ result }) => !result)
      .map(({ control: { description } }) => description?.textContent)
      .join(' and ')
      .replace(/,([^,]*)$/, ' and $1');

    const validationMessage = `${a11yDoesNotMeetCriteriaSpan} Your passcode needs to be, ${unmetCriterion}`;
    updateInputAriaDescribedByDescription(validationMessage);

    return result;
  };

  return { validate: validatePasscodeInputEventHandler };
};

export default usePasscode;

function setInputAriaDescribedByDescription(patterns: ValidationPattern) {
  const inputAriaDescribedBySpan = document.querySelector(
    '.tfl-security-description-span'
  );

  const inputAriaDescribedByDescription =
    inputAriaDescribedBySpan?.textContent || '';

  const passcodeCriterionDescription = Object.values(patterns)
    .map(({ control: { description } }) => description?.textContent)
    .join(' and ')
    .replace(/,([^,]*)$/, ' and $1');

  const inputAriaDescribedByDescriptionWithPasscodeCriterion = `${inputAriaDescribedByDescription} Your passcode needs to be, ${passcodeCriterionDescription}`;

  if (inputAriaDescribedBySpan) {
    inputAriaDescribedBySpan.setAttribute(
      'aria-label',
      inputAriaDescribedByDescriptionWithPasscodeCriterion
    );
  }
}

function updateInputAriaDescribedByDescription(description: string) {
  const inputAriaDescribedBySpan = document.querySelector(
    '.tfl-security-description-span'
  );

  if (inputAriaDescribedBySpan) {
    inputAriaDescribedBySpan.setAttribute('aria-label', description);
  }
}
