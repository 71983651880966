import { useB2cContainer } from '.';

const useHasPasscodeStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasSecurityCode =
    b2cContainer?.querySelector('#securityPasscode_Answer') !== null;

  return [hasSecurityCode];
};

export default useHasPasscodeStep;
