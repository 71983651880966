import { useEffect } from 'react';
import {
  useAddAlertSummary,
  useAddAlertSummaryMutationObserver,
  useB2cContainer,
  useButton,
  useEmptyParagraphRemover,
  useFocusErrorSummaryScrollToTarget,
  useHtml5ValidationRemover,
  useIntroText,
  useMoveErrorContainerBelowInput,
  useRemoveElement,
  useStyleDropdown,
  useStyleInputs,
  useStyleSubText,
} from '../../hooks';
import useMutationObserver from '../../hooks/useMutationObserver';

const ProfileEditPersonalDetails = () => {
  const [b2cContainer] = useB2cContainer();

  useIntroText();

  useHtml5ValidationRemover();

  useEmptyParagraphRemover();

  useStyleInputs({
    otherInputContainerSelector: '#attributeList .TextBox .attrEntry',
  });

  useStyleDropdown();

  useMoveErrorContainerBelowInput({
    selectors: [
      '.TextBox .attrEntry .error',
      '.DropdownSingleSelect .attrEntry .error',
    ],
  });

  useAddAlertSummary();

  useAddAlertSummaryMutationObserver();

  //unbold (optional) in label
  useStyleSubText({ selectors:['#contactMobileNumber_label', '#contactOtherNumber_label']});

  // Remove unnecessary element causing style issue + remove Margin-Bottom from first Li
  useRemoveElement({
    selectors: ['#mustCompleteAllFieldsParagraphText_label '],
  });

  useRemoveElement({ selectors: ['#fieldIncorrect'] });

  // Add continue button styles
  useButton({
    selector: 'continue',
    type: 'button',
    variant: 'primary',
  });

  // Add cancel button styles
  useButton({
    selector: 'cancel',
    type: 'button',
    variant: 'secondary',
  });

  // Move cancel button next to continue button
  useEffect(() => {
    if (!b2cContainer) return;
    const cancelButton = b2cContainer.querySelector('#cancel') as HTMLElement;
    const continueButton =
      b2cContainer?.querySelector('#continue')?.parentElement;
    if (!cancelButton) return;
    continueButton?.appendChild(cancelButton);
  }, [b2cContainer]);

  // Add mutation observer for all inputs error states
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainersSelector = '.attrEntry .error.itemLevel';
    const errorContainers = Array.from(
      b2cContainer?.querySelectorAll(errorContainersSelector)
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';

    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (!errorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target, type } = mutation;
      const errorContainer = target as Element;
      const parentElement = errorContainer?.parentElement;
      const element = parentElement?.querySelector('input');

      if (!element) return;

      const errorContainerHasTextContent =
        !!errorContainer?.textContent?.length;
      const showingError =
        (errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
          'false') !==
        undefined;

      if (type === 'childList' && !errorContainerHasTextContent) {
        element?.classList.remove(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.remove(classname)
        );
        return;
      }

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      // remove default error class or ovveride style in css
      element?.classList.remove('invalid');

      element?.classList.remove(textInputErrorClassname);
      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainer?.classList.remove(classname)
      );

      if (showingError && errorContainerHasTextContent) {
        element?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.add(classname)
        );
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: errorContainersSelector,
        config: {
          childList: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Set focus to error summary on form submission failure
  useFocusErrorSummaryScrollToTarget({
    buttonSelectors: ['#continue'],
  });

  return <></>;
};

export default ProfileEditPersonalDetails;
