import { useB2cContainer } from '.';

const useHasProfileEditPasswordStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasProfileEditPassword =
    b2cContainer?.querySelector('#password') !== null &&
    b2cContainer?.querySelector('#newPassword') !== null;

  return [hasProfileEditPassword];
};

export default useHasProfileEditPasswordStep;
