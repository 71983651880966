import { useEffect } from 'react';
import { useB2cContainer } from '.';

const useMoveButtons = (otherButtonContainerSelector?: string) => {
  const [b2cContainer] = useB2cContainer();

  // Move and append all buttons to bottom of the card
  useEffect(() => {
    if (!b2cContainer) return;

    const sourceButtonsContainer = b2cContainer.querySelector(
      '#attributeList .buttons'
    );

    const form = b2cContainer?.querySelector('#attributeVerification');

    if (!form) return;

    const targetButtonContainer = b2cContainer?.querySelector(
      '#attributeVerification > .buttons'
    );

    let otherSourceButtonContainer: Element | null = null;
    if (otherButtonContainerSelector) {
      otherSourceButtonContainer = b2cContainer?.querySelector(
        otherButtonContainerSelector
      );
    }

    if (targetButtonContainer) {
      [sourceButtonsContainer, otherSourceButtonContainer].forEach((source) =>
        source
          ?.querySelectorAll('button')
          .forEach((button) => targetButtonContainer.appendChild(button))
      );

      [sourceButtonsContainer, otherSourceButtonContainer].forEach(
        (sourceContainer) => sourceContainer?.remove()
      );
      return;
    }

    if (sourceButtonsContainer) form?.appendChild(sourceButtonsContainer);
  }, [b2cContainer]);
};

export default useMoveButtons;
