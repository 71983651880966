import { useB2cContainer } from '.';

const useHasAddressStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasPostcodeLookup =
    b2cContainer?.querySelector('#address_PostcodeLookup_label') !== null;

  return [hasPostcodeLookup];
};
export default useHasAddressStep;
