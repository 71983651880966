import { useB2cContainer } from '.';

const useHasMfaSetupStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasMfaSetupMarkup = () =>
    b2cContainer?.querySelector('#countryCode') !== null &&
    b2cContainer?.querySelector('#phoneNumberMinusCountryCode') !== null;
  //     ||
  //   b2cContainer?.querySelector('#continue')?.textContent?.toLowerCase() ===
  //     'set up mfa'
  // ;

  return { hasMfaSetupStep: hasMfaSetupMarkup() };
};

export default useHasMfaSetupStep;
