import { useEffect } from 'react';
import {
  useB2cContainer,
  useButton,
  useEmptyParagraphRemover,
  useHtml5ValidationRemover,
  useMutationObserver,
  usePassword,
  useFocusErrorSummaryScrollToTarget,
  useFocusServerErrorScrollToTarget,
  useRemoveAttribute,
  useHasSignInStep,
  useRemoveElement,
  useMfaOtpCallback,
  useEnterKeydownHandler,
  useStyleDropdown,
  useHasMfaSetupStep,
  useHasAddressStep,
  useHasMfaChallengeStep,
  useHasPasscodeStep,
  useUpdatePageTitle,
  useAddPasswordSecuritySpan,
} from '../../hooks';
import { Address, MfaChallenge, MfaSetup, Security, Signin } from '..';
import './PasswordReset.scss';
import { typedBoolean } from '../../types/tfl-types';

const PasswordReset = () => {
  const [b2cContainer] = useB2cContainer();
  const [hasSignInStep] = useHasSignInStep();
  const { hasMfaSetupStep } = useHasMfaSetupStep();
  const [hasAddressStep] = useHasAddressStep();
  const [hasPasscodeStep] = useHasPasscodeStep();
  const [hasMfaChallengeStep] = useHasMfaChallengeStep();
  const [callback] = useMfaOtpCallback();

  useRemoveElement({
    selectors: [
      '.intro > p',
      '#address_SubHeaderText_AddressLookup_label',
      '#address_SubHeaderText_YourAddress_label',
      '#oneQuickThingFirstParagraphText_label',
    ],
  });

  useFocusServerErrorScrollToTarget();

  useStyleDropdown();

  // intro text
  useEffect(() => {
    if (!b2cContainer) return;

    if (hasMfaChallengeStep || hasMfaSetupStep) return;

    const form = b2cContainer?.querySelector('#attributeVerification');

    if (!form) return;

    const headingTextSourceElement = b2cContainer?.querySelector(
      '.Paragraph .attrEntry .textInParagraph'
    ) as HTMLElement;

    if (!headingTextSourceElement) return;

    const headingElement = b2cContainer.querySelector('.heading h1');

    if (!headingElement) return;

    const textIntro = headingTextSourceElement.textContent ?? '';
    headingElement.textContent = textIntro;
    headingElement.setAttribute('data-original-text', textIntro);

    headingTextSourceElement.parentElement?.parentElement?.remove();
  }, [b2cContainer]);

  // Add Alert Summary
  useEffect(() => {
    if (!b2cContainer) return;

    const passwordMismatch = b2cContainer?.querySelector(
      '#passwordEntryMismatch'
    ) as HTMLElement;

    if (passwordMismatch) passwordMismatch.style.display = 'none';

    const errorSummaryHeader = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;

    if (!errorSummaryHeader) return;

    errorSummaryHeader?.classList.add('tfl-alert', 'tfl-alert--error');
    errorSummaryHeader?.setAttribute('aria-labelledby', 'tfl-alert-heading');

    const errorSummaryText = errorSummaryHeader.textContent;
    errorSummaryHeader.textContent = '';

    const alertContainerH2 = document.createElement('H2');
    alertContainerH2?.classList.add(
      'tfl-alert__header',
      'tfl-alert__header--with-description'
    );
    errorSummaryHeader?.prepend(alertContainerH2);
    alertContainerH2.textContent = errorSummaryText;
    alertContainerH2.id = 'tfl-alert-heading';

    // Add UL links
    const alertContent = document.createElement('DIV');
    alertContent.classList.add('tfl-alert__content');

    errorSummaryHeader.prepend(alertContent);
    errorSummaryHeader.insertBefore(alertContainerH2, alertContent);

    const inputInlineErrorContainers = Array.from(
      b2cContainer.querySelectorAll('.error.itemLevel')
    );

    if (!inputInlineErrorContainers) return;

    const errorSummaryLinks = inputInlineErrorContainers.map((container) => {
      // only interested in container with a sibling INPUT element
      const targetControl = Array.from(
        container?.parentNode?.children ?? []
      ).find((c) => c.tagName === 'INPUT');

      if (!targetControl?.id) return;

      const link = document.createElement('A');
      link.id = `skip-link-${targetControl?.id}`;
      link.setAttribute('href', `#${targetControl?.id}`);
      link.classList.add('tfl-alert-summary__link-text');
      link.textContent = container.textContent;

      link.addEventListener('click', (event) => {
        event.preventDefault();
        const link = event.target as HTMLElement;
        const targetInputId = link.getAttribute('href');
        if (!targetInputId) return;
        const targetInput = b2cContainer.querySelector(
          targetInputId
        ) as HTMLElement;
        targetInput?.focus();
      });

      const errorSummaryLink = {
        targetId: `${targetControl?.id}`,
        element: link,
      };

      return errorSummaryLink;
    });

    const ulLinks = document.createElement('UL');
    ulLinks.classList.add('tfl-alert-summary');
    alertContent.prepend(ulLinks);

    errorSummaryLinks.filter(typedBoolean).forEach((link) => {
      const listItem = document.createElement('LI');
      listItem.classList.add(`tfl-alert-summary__link`, link?.targetId, 'hide');
      listItem.appendChild(link.element);

      ulLinks.appendChild(listItem);
    });
  }, [b2cContainer]);

  // Add Alert summary styling to server error container
  useEffect(() => {
    if (!b2cContainer) return;

    const claimVerificationServerErrorSummaryHeader =
      b2cContainer?.querySelector(
        '#claimVerificationServerError'
      ) as HTMLElement;

    if (!claimVerificationServerErrorSummaryHeader) return;

    claimVerificationServerErrorSummaryHeader?.classList.add(
      'tfl-alert',
      'tfl-alert--error',
      'tfl-reset-password-server-error'
    );
    claimVerificationServerErrorSummaryHeader?.setAttribute(
      'aria-labelledby',
      'tfl-alert-heading'
    );
  }, [b2cContainer]);

  // Add tfl branded styles to input containers/labels
  useEffect(() => {
    if (!b2cContainer) return;

    const inputContainers = Array.from(
      b2cContainer?.querySelectorAll('.TextBox .attrEntry')
    );

    const passwordResetInputContainer = b2cContainer.querySelector(
      '.Password .attrEntry'
    );
    passwordResetInputContainer?.classList.add('tfl-text-input');

    const passwordResetInput =
      passwordResetInputContainer?.querySelector('input');

    passwordResetInput?.classList.add(
      'tfl-text-input',
      'tfl-text-input__input'
    );

    inputContainers?.forEach((container) =>
      container?.classList.add('tfl-text-input')
    );

    const labels = inputContainers?.map((container) =>
      container.querySelector('label')
    );

    const inputs = inputContainers?.map((container) =>
      container.querySelector('input')
    );

    labels?.forEach((container) =>
      container?.classList.add('tfl-text-input__label')
    );

    inputs?.forEach((container) =>
      container?.classList.add('tfl-text-input', 'tfl-text-input__input')
    );
  }, [b2cContainer]);

  // Remove html5 validation
  useHtml5ValidationRemover();

  // Remove all empty li Paragraph
  useEmptyParagraphRemover();

  // Remove help link texts
  useEffect(() => {
    if (!b2cContainer) return;

    b2cContainer
      .querySelectorAll('.helpLink.tiny')
      .forEach((helpText) => helpText.remove());
  }, [b2cContainer]);

  // Move inline error containers below the input
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainers = b2cContainer.querySelectorAll(
      '.TextBox .attrEntry .error'
    );

    errorContainers.forEach((container) => {
      const input = container.parentNode?.querySelector('input');
      container.classList.add(`${input?.id}`);
      container.setAttribute(
        'data-summary-link',
        `.tfl-alert-summary__link.${input?.id}`
      );
    });
    errorContainers.forEach((container) =>
      container.parentNode?.appendChild(container)
    );
  }, [b2cContainer]);

  // Add send verification code button styles
  useEffect(() => {
    if (!b2cContainer) return;

    const verificationCodeButton = b2cContainer.querySelector(
      '.verificationControlContent .buttons .sendCode'
    );
    verificationCodeButton?.classList.add('tfl-button', 'tfl-button--primary');
  }, [b2cContainer]);

  // Add verify code button styles
  useButton({
    selector: 'passwordResetEmailVerificationControl_but_verify_code',
    type: 'button',
    variant: 'primary',
  });

  // Add send new code button styles
  useButton({
    selector: 'passwordResetEmailVerificationControl_but_send_new_code',
    type: 'button',
    variant: 'secondary',
  });

  // Add continue button styles
  useButton({
    selector: 'continue',
    type: 'button',
    variant: 'primary',
  });

  // Move continue button next to other buttons
  useEffect(() => {
    if (!b2cContainer) return;

    const continueButton = b2cContainer.querySelector(
      '#continue'
    ) as HTMLElement;

    const targetContainer = b2cContainer?.querySelector(
      '#registerEmailVerificationControl_but_send_code'
    )?.parentElement;

    if (!continueButton) return;

    targetContainer?.appendChild(continueButton);
  }, [b2cContainer]);

  // Hide continue button on landing page
  useEffect(() => {
    if (!b2cContainer) return;

    const continueButton = b2cContainer.querySelector(
      '#continue'
    ) as HTMLElement;
    const sendVerificationButton = b2cContainer?.querySelector(
      '#passwordResetEmailVerificationControl_but_send_code'
    );

    if (!sendVerificationButton) return;

    if (continueButton) {
      continueButton.setAttribute('aria-hidden', 'true');
      continueButton.style.display = 'none';
    }
  }, [b2cContainer]);

  // Move and append all buttons to bottom of the card
  useEffect(() => {
    if (!b2cContainer) return;

    const buttonsContainer = b2cContainer.querySelector(
      '#passwordResetEmailVerificationControl_but_send_code'
    )?.parentNode;

    if (!buttonsContainer) return;

    const form = b2cContainer?.querySelector('#attributeVerification');
    form?.appendChild(buttonsContainer);
  }, [b2cContainer]);

  // Add sign in link
  useEffect(() => {
    if (!b2cContainer) return;

    const signInParagraph = b2cContainer?.querySelector(
      '#signInLinkParagraphText'
    );

    if (!signInParagraph) return;

    if (b2cContainer.querySelector('.tfl-password-reset-signin-div')) return;

    const signInSpan = document.createElement('DIV');
    const link = document.createElement('A');
    link.setAttribute('href', `${process.env.REACT_APP_SIGN_IN_LINK}`);
    link.innerText = 'Sign in';
    signInSpan.classList.add('tfl-password-reset-signin-div');

    const verificationCodeButton = b2cContainer.querySelector('.sendCode');

    signInParagraph.append(link);
    signInSpan.append(signInParagraph);
    verificationCodeButton?.parentNode?.appendChild(signInSpan);
  }, [b2cContainer]);

  // Move verification message above enter code input label
  useEffect(() => {
    if (!b2cContainer) return;

    const verificationSuccessContainer = b2cContainer.querySelector(
      '.verificationSuccessText'
    );

    const enterCodeContainer = b2cContainer.querySelector(
      '#emailVerificationCode_label'
    )?.parentNode;

    if (!verificationSuccessContainer) return;

    enterCodeContainer?.prepend(verificationSuccessContainer);
  }, [b2cContainer]);

  // Remove style-list
  useEffect(() => {
    if (!b2cContainer) return;

    const list = b2cContainer?.querySelectorAll('ul');
    list?.forEach((el) => el.setAttribute('style', 'list-style:none'));
  }, [b2cContainer]);

  // Remove inline style attribute from all list items
  useRemoveAttribute({
    attribute: 'style',
    selector: 'li[style*="display: inline"]',
  });

  // Remove unnecessary elements causing style issues
  useEffect(() => {
    if (!b2cContainer) return;

    b2cContainer
      .querySelector('#passwordReset_FirstParagraphText_label')
      ?.remove();

    b2cContainer.querySelector('#passwordReset_HeaderText_label')?.remove();

    b2cContainer
      .querySelector('#passwordResetEmailVerificationControl_label')
      ?.remove();

    b2cContainer.querySelector('#signInLinkParagraphText_label')?.remove();

    b2cContainer.querySelector('#fieldIncorrect')?.remove();

    b2cContainer.querySelector('#verifying_blurb')?.remove();
  }, [b2cContainer]);

  // Add Tfl branded styles to create password input
  useEffect(() => {
    if (!b2cContainer) return;

    const passwordInputContainer = b2cContainer.querySelector(
      '.attrEntry.validate'
    );
    passwordInputContainer?.classList.add('tfl-text-input');

    const label = passwordInputContainer?.querySelector('label');
    const input = b2cContainer.querySelector('input');

    label?.classList.add('tfl-text-input__label');
    input?.classList.add('tfl-text-input', 'tfl-text-input__input');
  }, [b2cContainer]);

  // Move password error container below the input
  useEffect(() => {
    if (!b2cContainer) return;

    const passwordErrorContainer = b2cContainer.querySelector(
      '.Password .error.itemLevel'
    );

    if (!passwordErrorContainer) return;

    const input = passwordErrorContainer?.parentNode?.querySelector('input');

    passwordErrorContainer?.classList.add(`${input?.id}`);
    passwordErrorContainer?.setAttribute(
      'data-summary-link',
      `.tfl-alert-summary__link.${input?.id}`
    );
    passwordErrorContainer?.parentNode?.appendChild(passwordErrorContainer);
  }, [b2cContainer]);

  // Create password validation markup below the input
  useEffect(() => {
    if (!b2cContainer) return;

    const passwordContainer = b2cContainer.querySelector(
      '#attributeList .Password'
    );

    if (!passwordContainer) return;

    const passwordInput = passwordContainer?.querySelector('#newPassword');
    passwordInput?.setAttribute('maxlength', '256');

    if (!passwordInput) return;

    const passwordMatchContainer = b2cContainer?.querySelector(
      '.tfl-password-match'
    );

    if (passwordMatchContainer) return;

    const passwordMatchItems = [
      {
        id: 'tfl-password-length',
        className: 'tfl-password-length',
        text: 'Minimum of eight characters',
      },
      {
        id: 'tfl-password-case',
        className: 'tfl-password-case',
        text: 'Capital and lower case letters',
      },
      {
        id: 'tfl-password-number-special-character',
        className: 'tfl-password-number-special-character',
        text: `At least one number or special character such as ?!@£$%^&*()`,
      },
    ];

    const passwordMatch = document.createElement('li');
    passwordMatch.classList.add('tfl-password-match');

    const a11ySpan = document.createElement('span');
    a11ySpan.classList.add('tfl-password-a11y', 'visuallyhidden');

    passwordMatch.appendChild(a11ySpan);

    passwordMatchItems.forEach(({ id, className, text }) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('tfl-password-match--wrapper');

      const icon = document.createElement('div');
      icon.classList.add(
        className,
        'tfl-password-match-icon',
        'tfl-password-match-icon--default'
      );

      const a11yIds = passwordMatchItems.map((item) => item.id).join(' ');
      passwordInput.setAttribute('aria-describedby', a11yIds);

      const description = document.createElement('div');
      description.id = id;
      description.classList.add('tfl-password-match--description');
      description.textContent = text;
      description.setAttribute('aria-live', 'polite');

      wrapper.appendChild(icon);
      wrapper.appendChild(description);

      passwordMatch.appendChild(wrapper);
    });

    passwordContainer.after(passwordMatch);
  }, [b2cContainer]);

  useAddPasswordSecuritySpan();

  // Validate password on input and toggle between ✅,❌ & 🔘 icons
  useEffect(() => {
    const passwordInputSelector = '#newPassword';
    const passwordInput = b2cContainer?.querySelector(
      passwordInputSelector
    ) as HTMLInputElement;

    if (!passwordInput) return;

    const { validate } = usePassword();

    const handler = ({ target }: Event) => validate(target as HTMLInputElement);

    passwordInput?.addEventListener('input', handler);

    return () => passwordInput?.removeEventListener('input', handler);
  }, [b2cContainer]);

  // Toggle show/hide password
  useEffect(() => {
    const passwordInputSelector = '#newPassword';
    const passwordInput = b2cContainer?.querySelector(
      passwordInputSelector
    ) as HTMLInputElement;

    if (!passwordInput) return;

    passwordInput.classList.add(
      'has-password-toggle--with-validation-indicator'
    );

    const toggleDiv = document.createElement('DIV');
    toggleDiv.classList.add('tfl-reset-password-toggle');

    const toggleSpan = document.createElement('SPAN');

    //Add 2 hidden span for accessibility
    const hiddenClickSpan = document.createElement('SPAN');
    hiddenClickSpan.classList.add('hidden-clip');
    hiddenClickSpan.textContent = 'click to';
    const hiddenPasswordSpan = document.createElement('SPAN');
    hiddenPasswordSpan.classList.add('hidden-clip');
    hiddenPasswordSpan.textContent = 'the password';

    toggleSpan.textContent =
      passwordInput.getAttribute('type') === 'password' ? 'Show' : 'Hide';

    // A11y attributes
    toggleDiv.setAttribute('aria-live', 'assertive');
    toggleDiv.setAttribute('aria-atomic', 'true');
    toggleDiv.setAttribute('aria-relevant', 'all');
    toggleDiv.setAttribute('tabindex', '0');

    passwordInput.classList.add('tfl-text-input__input--hide');
    passwordInput.parentNode?.append(toggleDiv);

    toggleDiv.append(toggleSpan);

    toggleSpan.parentNode?.prepend(hiddenClickSpan);
    toggleSpan.parentNode?.append(hiddenPasswordSpan);

    const togglePasswordHandler = () => {
      const type =
        passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
      passwordInput.setAttribute('type', type);
      toggleSpan.innerHTML === 'Show'
        ? (toggleSpan.innerHTML = 'Hide')
        : (toggleSpan.innerHTML = 'Show');
    };
    toggleDiv.addEventListener('click', togglePasswordHandler);

    // A11y toggleDiv
    const keyAccessibilityHandler = (e: { key: string }) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        toggleSpan.click();
      }
    };
    toggleDiv.addEventListener('keydown', keyAccessibilityHandler);

    return () => {
      toggleDiv.removeEventListener('click', togglePasswordHandler);
      toggleDiv.removeEventListener('keydown', keyAccessibilityHandler);
    };
  }, [b2cContainer]);

  // Set default icons class from 🔘 to ❌
  useEffect(() => {
    const continueButtonSelector = '#continue';
    const passwordInputSelector = '#newPassword';
    const continueButton = b2cContainer?.querySelector(
      continueButtonSelector
    ) as HTMLInputElement;
    const passwordInput = b2cContainer?.querySelector(
      passwordInputSelector
    ) as HTMLInputElement;

    if (!continueButton || !passwordInput) return;

    const handler = () => {
      const event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      passwordInput.setAttribute(
        'data-default-icon-class',
        'tfl-password-match-icon--failure'
      );
      passwordInput.dispatchEvent(event);
    };

    continueButton?.addEventListener('click', handler);

    return () => continueButton?.removeEventListener('click', handler);
  }, [b2cContainer]);

  // Handle enter key press on email input field when verifying code whilst resetting password.
  useEnterKeydownHandler({
    inputFieldSelector: '#emailVerificationCode',
    targetButtonSelector:
      '#passwordResetEmailVerificationControl_but_verify_code',
  });

  // Handle enter key press on email input field when sending code to verify email address.
  useEnterKeydownHandler({
    inputFieldSelector: '#signInName',
    targetButtonSelector:
      '#passwordResetEmailVerificationControl_but_send_code',
  });

  // Copy other email/verify code error message to inline error container
  useEffect(() => {
    if (!b2cContainer) return;

    const otherErrorMessageContainerSelector =
      '#passwordResetEmailVerificationControl_error_message';
    const otherErrorMessageContainer = b2cContainer.querySelector(
      otherErrorMessageContainerSelector
    );

    if (!otherErrorMessageContainer) return;

    // We can't remove it from DOM so just hide it
    otherErrorMessageContainer?.classList.add('visuallyhidden');

    const emailContainer = b2cContainer.querySelector('.TextBox.signInName');
    const emailInlineErrorContainer =
      emailContainer?.querySelector('.error.itemLevel');

    const verificationCodeContainer = b2cContainer.querySelector(
      '.TextBox.VerificationCode'
    );
    const verificationCodeInlineErrorContainer =
      verificationCodeContainer?.querySelector('.error.itemLevel');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const otherErrorMessageContainer = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const otherErrorMessageContainerShowing =
        otherErrorMessageContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const textContent = otherErrorMessageContainer?.textContent;

      const otherErrorMessageContainerHasTextContent = !!textContent?.length;

      if (
        !otherErrorMessageContainerShowing ||
        !otherErrorMessageContainerHasTextContent
      )
        return;

      const verificationCodeContainerShowing =
        verificationCodeContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      if (
        verificationCodeContainerShowing &&
        verificationCodeInlineErrorContainer
      ) {
        verificationCodeInlineErrorContainer.textContent = textContent;
        verificationCodeInlineErrorContainer.setAttribute(
          'aria-hidden',
          'false'
        );
        return;
      }

      const emailContainerShowing =
        emailContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (emailContainerShowing && emailInlineErrorContainer) {
        emailInlineErrorContainer.textContent = textContent;
        emailInlineErrorContainer.setAttribute('aria-hidden', 'false');
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otherErrorMessageContainerSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Move B2C container inside react container
  useEffect(() => {
    if (!b2cContainer) return;

    const container = document.getElementById('b2c-react-container');

    if (!container) return;

    container.appendChild(b2cContainer);

    b2cContainer.classList.remove('visuallyhidden');
  }, [b2cContainer]);

  // Attach event handlers side effects Alert Summary
  useEffect(() => {
    // control input box css error states
    if (!b2cContainer) return;

    const errorSummaryContainer = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;

    const claimVerificationServerErrorSummaryContainer =
      b2cContainer?.querySelector('#claimVerificationServerError');

    if (!errorSummaryContainer || !claimVerificationServerErrorSummaryContainer)
      return;

    const inputs = Array.from(
      b2cContainer?.querySelectorAll('input')
    ) as HTMLElement[];

    const inputErrorContainerSelectors = inputs.map(
      (input) => `.error.itemLevel.${input.id}`
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';
    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (!inputErrorContainerSelectors.length) return;

    const inputErrorContainers = inputErrorContainerSelectors.map((selector) =>
      b2cContainer.querySelector(selector)
    );
    if (!inputErrorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const targetErrorContainer = target as Element;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const hasInlineErrorsOnThePage =
        inputErrorContainers.find(
          (container) =>
            container?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
            'false'
        ) !== undefined;

      errorSummaryContainer.setAttribute(
        'aria-hidden',
        hasInlineErrorsOnThePage ? 'false' : 'true'
      );
      errorSummaryContainer.style.display = hasInlineErrorsOnThePage
        ? 'block'
        : 'none';

      const targetSummaryItemLinkSelector =
        targetErrorContainer.attributes.getNamedItem(
          'data-summary-link'
        )?.nodeValue;

      if (!targetSummaryItemLinkSelector) return;

      let targetSummaryItemLink: HTMLElement | null | undefined = undefined;

      targetSummaryItemLink = b2cContainer?.querySelector(
        targetSummaryItemLinkSelector
      ) as HTMLElement;

      if (!targetSummaryItemLink) return;

      const input = targetErrorContainer?.parentElement?.querySelector('input');

      const errorContainerShowingError =
        targetErrorContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      // update alert summary link
      errorContainerShowingError
        ? (targetSummaryItemLink.classList.add('show'),
          targetSummaryItemLink.classList.remove('hide'))
        : (targetSummaryItemLink.classList.add('hide'),
          targetSummaryItemLink.classList.remove('show'));

      targetSummaryItemLink.setAttribute(
        'aria-hidden',
        errorContainerShowingError ? 'false' : 'true'
      );

      const skipLink = targetSummaryItemLink.querySelector('a');
      if (skipLink) {
        skipLink.textContent = errorContainerShowingError
          ? targetErrorContainer?.textContent
          : '';
      }

      if (errorContainerShowingError) {
        input?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          targetErrorContainer?.classList.add(classname)
        );
        return;
      }

      input?.classList.remove(textInputErrorClassname);

      errorContainerValidationErrorClassnames.forEach((classname) =>
        targetErrorContainer?.classList.remove(classname)
      );
    };

    const disconnects: ((() => void) | undefined)[] = [];

    inputErrorContainerSelectors.forEach((selector) => {
      const { disconnect } = {
        ...useMutationObserver({
          querySelector: selector,
          callback,
        }),
      };

      disconnects.push(disconnect);
    });

    return () => {
      disconnects?.forEach((disconnect) =>
        typeof disconnect === 'function' ? disconnect() : undefined
      );
    };
  }, [b2cContainer]);

  // Add mutation observer, on successful code verificaition, to
  // - show continue button, code input and success icon ✅
  useEffect(() => {
    const otpCodeSuccessMessageContainerSelector =
      '#passwordResetEmailVerificationControl_success_message';
    const verifyCodeButtonSelector =
      '#passwordResetEmailVerificationControl_but_verify_code';

    const verifyCodeButton = b2cContainer?.querySelector(
      verifyCodeButtonSelector
    );

    const passwordResetVerificationCodeContainer = b2cContainer?.querySelector(
      '.TextBox.VerificationCode'
    ) as HTMLElement;

    const continueButton = b2cContainer?.querySelector(
      '#continue'
    ) as HTMLElement;

    if (!passwordResetVerificationCodeContainer || !verifyCodeButton) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const otpCodeSuccessMessageContainer = target as HTMLElement;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const otpCodeSuccessMessageShowing =
        otpCodeSuccessMessageContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const hasOtpCodeSuccessMessage = [
        'The code has been verified',
        'Your code has been verified',
      ].some((message) =>
        otpCodeSuccessMessageContainer?.textContent?.includes(message)
      );

      const passwordResetVerificationCodeInput = b2cContainer?.querySelector(
        '#emailVerificationCode'
      );

      if (otpCodeSuccessMessageShowing && hasOtpCodeSuccessMessage) {
        passwordResetVerificationCodeInput?.classList.remove(
          'tfl-text-input__input--loading'
        );
        passwordResetVerificationCodeContainer.style.display = 'block';
        passwordResetVerificationCodeContainer.setAttribute(
          'aria-hidden',
          'false'
        );

        passwordResetVerificationCodeInput?.classList.add(
          'tfl-text-input__input--success'
        );

        if (otpCodeSuccessMessageShowing) {
          continueButton.setAttribute('aria-hidden', 'false');
          continueButton.removeAttribute('disabled');
          continueButton.style.display = 'block';
          continueButton.classList.remove('visuallyhidden');
        }

        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otpCodeSuccessMessageContainerSelector,
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // add loader to email adress input field after user clicks 'send verification code'
  useEffect(() => {
    if (!b2cContainer) return;

    const sendVerificationButtonSelector =
      '#passwordResetEmailVerificationControl_but_send_code';
    const emailAddressInput = b2cContainer?.querySelector('#signInName');
    const verifyCodeInput = b2cContainer?.querySelector('.VerificationCode');
    const continueButton = b2cContainer?.querySelector('#continue');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const sendVerificationButton = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const sendVerificationButtonHidden =
        sendVerificationButton?.attributes?.getNamedItem('aria-hidden')
          ?.nodeValue === 'true';
      const claimVerificationServerError = b2cContainer.querySelector(
        '#claimVerificationServerError'
      );

      const claimVerificationServerErrorThrown =
        claimVerificationServerError?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const codeInputVisible =
        verifyCodeInput?.attributes?.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      const continueButtonVisible =
        continueButton?.attributes?.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (!sendVerificationButtonHidden) return;

      if (
        continueButtonVisible ||
        codeInputVisible ||
        claimVerificationServerErrorThrown
      ) {
        emailAddressInput?.classList.remove('tfl-text-input__input--loading');
        return;
      }

      emailAddressInput?.classList.add('tfl-text-input__input--loading');
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: sendVerificationButtonSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // remove loader from email adress input field when error summary is displayed
  useEffect(() => {
    if (!b2cContainer) return;

    const alertSummarySelector = '#requiredFieldMissing';
    const emailAddressInput = b2cContainer?.querySelector('#signInName');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const alertSummary = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const errorSummaryVisible =
        alertSummary?.attributes?.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (!alertSummary) return;

      if (errorSummaryVisible) {
        emailAddressInput?.classList.remove('tfl-text-input__input--loading');
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: alertSummarySelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, []);

  // Add mutation observer to show continue button & code input box & disable email input loader
  useEffect(() => {
    const otpCodeSuccessMessageContainerSelector =
      '#passwordResetEmailVerificationControl_success_message';
    const verifyCodeButtonSelector =
      '#passwordResetEmailVerificationControl_but_verify_code';

    const verifyCodeButton = b2cContainer?.querySelector(
      verifyCodeButtonSelector
    );

    const mfaVerificationCodeContainer = b2cContainer?.querySelector(
      '.TextBox.VerificationCode'
    ) as HTMLElement;

    if (!mfaVerificationCodeContainer || !verifyCodeButton) return;

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otpCodeSuccessMessageContainerSelector,
        callback: (mutation: MutationRecord) =>
          callback(mutation, '#emailVerificationCode'),
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Take user back to landing page when try to edit email address
  useEffect(() => {
    if (!b2cContainer) return;

    const emailAddressInput = b2cContainer.querySelector('#signInName');
    const continueButton = b2cContainer?.querySelector(
      '#continue'
    ) as HTMLElement;
    const verifyCodeButton = b2cContainer?.querySelector(
      '#passwordResetEmailVerificationControl_but_verify_code'
    ) as HTMLElement;
    const resendCodeButton = b2cContainer?.querySelector(
      '#passwordResetEmailVerificationControl_but_send_new_code'
    ) as HTMLElement;
    const sendVerificationCodeButton = b2cContainer.querySelector(
      '#passwordResetEmailVerificationControl_but_send_code'
    ) as HTMLElement;
    const verficationCodeInputContainer = b2cContainer?.querySelector(
      '.TextBox.VerificationCode'
    ) as HTMLElement;
    const verificationCodeInput = b2cContainer?.querySelector(
      '#emailVerificationCode'
    ) as HTMLElement;

    const handler = (event: Event) => {
      const emailAddressInput = event.target as HTMLElement;
      if (!emailAddressInput) return;

      const userIsAlreadyOnLandingPage =
        window.getComputedStyle(sendVerificationCodeButton, null)?.display ===
        'block';

      if (userIsAlreadyOnLandingPage) return;

      sendVerificationCodeButton.style.display = 'block';
      sendVerificationCodeButton.setAttribute('aria-hidden', 'false');

      verificationCodeInput.removeAttribute('disabled');
      verificationCodeInput.textContent = '';
      verificationCodeInput.classList.remove(
        'tfl-text-input__input--success',
        'tfl-text-input__input--loading'
      );

      [
        verficationCodeInputContainer,
        verifyCodeButton,
        resendCodeButton,
        continueButton,
      ].forEach((element) => {
        element.style.display = 'none';
        element.setAttribute('aria-hidden', 'true');
      });
    };

    emailAddressInput?.addEventListener('input', handler);

    return () => emailAddressInput?.removeEventListener('input', handler);
  }, [b2cContainer]);

  // Remove change button (This button can't be removed from the policy)
  useEffect(() => {
    if (!b2cContainer) return;

    const changeEmailAddressButton = document.querySelector(
      '#passwordResetEmailVerificationControl_but_change_claims'
    );
    changeEmailAddressButton?.remove();
  }, [b2cContainer]);

  // Add mutation observer for all inputs error states
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainersSelector = '.attrEntry .error.itemLevel';
    const errorContainers = Array.from(
      b2cContainer?.querySelectorAll(errorContainersSelector)
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';

    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (!errorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target, type } = mutation;
      const errorContainer = target as Element;
      const parentElement = errorContainer?.parentElement;
      const element = parentElement?.querySelector('input');

      if (!element) return;

      const errorContainerHasTextContent =
        !!errorContainer?.textContent?.length;
      const showingError =
        errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (type === 'childList' && !errorContainerHasTextContent) {
        element?.classList.remove(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.remove(classname)
        );
        return;
      }

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      // remove default error class or ovveride style in css
      element?.classList.remove('invalid');

      element?.classList.remove(textInputErrorClassname);
      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainer?.classList.remove(classname)
      );

      if (showingError && errorContainerHasTextContent) {
        element?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.add(classname)
        );
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: errorContainersSelector,
        config: {
          childList: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Set focus to error summary on form submission failure
  useFocusErrorSummaryScrollToTarget({
    buttonSelectors: [
      '#passwordResetEmailVerificationControl_but_verify_code',
      '#passwordResetEmailVerificationControl_but_send_code',
    ],
  });

  useUpdatePageTitle('.heading h1');

  return (
    <>
      {hasSignInStep ? <Signin /> : null}
      {hasAddressStep ? <Address /> : null}
      {hasPasscodeStep ? <Security /> : null}
      {hasMfaSetupStep ? <MfaSetup /> : null}
      {hasMfaChallengeStep ? <MfaChallenge /> : null}
    </>
  );
};

export default PasswordReset;
