import { useEffect } from 'react';
import { useB2cContainer } from '.';

const useTrimLeadingTrailingSpaces = () => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const inputs = b2cContainer.querySelectorAll('input');

    if (!inputs?.length) return;

    const handler = (event: Event) => {
      event.stopImmediatePropagation();

      const target = event.target as HTMLInputElement;
      target.value = target.value?.trim();
    };

    inputs.forEach((input) => input.addEventListener('blur', handler));

    return () =>
      inputs.forEach((input) => input.removeEventListener('blur', handler));
  }, [b2cContainer]);
};

export default useTrimLeadingTrailingSpaces;
