import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

const useFocusErrorSummaryScrollToTarget = ({
  buttonSelectors = [],
}: {
  buttonSelectors?: string[];
}) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const authContainer = document.querySelector('.tfl-auth-container');

    const scrollTargetSpan = document.createElement('SPAN');
    scrollTargetSpan.classList.add('scroll-target');

    authContainer?.prepend(scrollTargetSpan);

    const errorSummaryContainer = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;

    if (!errorSummaryContainer) return;

    const handler = () => {
      const scrollTarget = authContainer?.querySelector('.scroll-target');

      const callback = () => {
        const errorSummaryLinks = Array.from(
          b2cContainer.querySelectorAll('.tfl-alert-summary li a')
        ) as HTMLElement[];

        if (!errorSummaryLinks) return;

        const targetSkipLink = errorSummaryLinks.find(
          (link) => link.textContent !== ''
        );

        targetSkipLink?.focus();
      };

      scrollTarget?.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => callback(), 500);
    };

    const submitButtons = b2cContainer.querySelectorAll(
      'button[type="submit"]'
    );

    submitButtons.forEach((button) =>
      button?.addEventListener('click', handler)
    );

    let otherButtons: (HTMLElement | null)[] = [];

    otherButtons = buttonSelectors.map((selector) =>
      b2cContainer.querySelector(selector)
    );

    otherButtons.forEach((button) =>
      button?.addEventListener('click', handler)
    );

    return () => {
      submitButtons.forEach((button) =>
        button?.removeEventListener('click', handler)
      );

      otherButtons?.forEach((button) =>
        button?.removeEventListener('click', handler)
      );
    };
  }, [b2cContainer]);
};

export default useFocusErrorSummaryScrollToTarget;
