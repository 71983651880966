import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

const useAddPasswordSecuritySpan = () => {
  const [b2cContainer] = useB2cContainer();

  // Add description to create a password hidden label
  useEffect(() => {
    if (!b2cContainer) return;

    const securityInput = b2cContainer.querySelector('#newPassword');

    const securityInputLabel = b2cContainer.querySelector('#newPassword_label');

    if (!securityInputLabel) return;

    const securitySpanAlreadyExists =
      b2cContainer.querySelector('#tfl-security-description-span') !== null;

    if (securitySpanAlreadyExists) return;

    const descriptionLabelSpan = document.createElement('span');
    descriptionLabelSpan.id = 'tfl-security-description-span';
    descriptionLabelSpan.classList.add(
      'tfl-security-description-span',
      'visuallyhidden'
    );

    securityInput?.setAttribute(
      'aria-describedby',
      `${descriptionLabelSpan.id}`
    );

    securityInputLabel.after(descriptionLabelSpan);
  }, [b2cContainer]);
};

export default useAddPasswordSecuritySpan;
