import { useB2cContainer } from '.';

const useHasProfileEditPersonalDetailsStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasProfileEditPersonalDetails =
    b2cContainer?.querySelector('#givenName') &&
    b2cContainer?.querySelector('#signInName') !== null;

  return [hasProfileEditPersonalDetails];
};

export default useHasProfileEditPersonalDetailsStep;
