import { createContext } from 'react';

export type CustomClaims = Record<string, string>;

export type ICustomClaimsContext = {
  customClaims?: CustomClaims;
  setCustomClaims?: React.Dispatch<React.SetStateAction<CustomClaims>>;
};

export const CustomClaimsContext = createContext<ICustomClaimsContext>({
  customClaims: undefined,
  setCustomClaims: undefined,
});
