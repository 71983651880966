import { useEffect } from 'react';
import { useB2cContainer, useButton, useRemoveElement } from '../../hooks';

export const ConfirmUpdateContactMobileNumber = () => {
  const [b2cContainer] = useB2cContainer();

  useRemoveElement({
    selectors: [
      '#yourDetailsParagraphText_label',
      '#yourDetailsSecondParagraphText_label',
    ],
  });

  useButton({
    selector: 'continue',
    type: 'button',
    variant: 'primary',
  });

  useButton({
    selector: 'cancel',
    type: 'button',
    variant: 'secondary',
  });

  useEffect(() => {
    if (!b2cContainer) return;

    const continueToChangeMfaNumberButton =
      b2cContainer.querySelector('#continue');

    if (!continueToChangeMfaNumberButton) return;

    const handler = () => sessionStorage.setItem('skipToResetMfa', 'true');

    continueToChangeMfaNumberButton.addEventListener('click', handler);

    return () => {
      continueToChangeMfaNumberButton.removeEventListener('click', handler);
    };
  }, [b2cContainer]);

  return <></>;
};

export default ConfirmUpdateContactMobileNumber;
