import { useB2cContainer } from '.';

const useHasProfileEditUpdateSecurityQuestionStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasProfileEditConfirmSecurityQuestionStep =
    b2cContainer?.querySelector('#updateSecurityDetails_HeaderText') !== null &&
    b2cContainer?.querySelector('#securityQuestion_Answer') !== null;

  return [hasProfileEditConfirmSecurityQuestionStep];
};

export default useHasProfileEditUpdateSecurityQuestionStep;
