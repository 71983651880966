import { useEffect, useState } from 'react';

const useB2cContainer = () => {
  const [b2cContainer, setB2cContainer] = useState<HTMLElement | null>(() =>
    document.getElementById('api')
  );

  useEffect(() => {
    if (b2cContainer) return;
    setB2cContainer(document.getElementById('api'));
  }, [b2cContainer, setB2cContainer]);

  return [b2cContainer];
};

export default useB2cContainer;
