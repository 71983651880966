import { useB2cContainer } from '.';

export interface IOtpCallback {
  (mutation: MutationRecord, mfaVerificationCodeInputSelector: string): void;
}

const useMfaOtpCallback = () => {
  const [b2cContainer] = useB2cContainer();

  const continueButton = b2cContainer?.querySelector(
    '#continue'
  ) as HTMLElement;

  const mfaVerificationCodeContainer = b2cContainer?.querySelector(
    '.TextBox.VerificationCode'
  ) as HTMLElement;

  const callback: IOtpCallback = (
    mutation,
    mfaVerificationCodeInputSelector
  ) => {
    const { attributeName, target } = mutation;
    const otpCodeSuccessMessageContainer = target as HTMLElement;

    // ignore any other attribute mutation and bail out of this side effect
    if (attributeName !== 'aria-hidden') return;

    if (!mfaVerificationCodeInputSelector) return;

    const otpCodeSuccessMessageShowing =
      otpCodeSuccessMessageContainer?.attributes.getNamedItem('aria-hidden')
        ?.nodeValue === 'false';

    const hasOtpCodeSuccessMessage = [
      'The code has been verified',
      'Your code has been verified',
    ].some((message) =>
      otpCodeSuccessMessageContainer?.textContent?.includes(message)
    );

    const mfaVerificationCodeInput = b2cContainer?.querySelector(
      mfaVerificationCodeInputSelector
    );

    if (otpCodeSuccessMessageShowing && hasOtpCodeSuccessMessage) {
      mfaVerificationCodeInput?.classList.remove(
        'tfl-text-input__input--loading'
      );
      mfaVerificationCodeContainer.style.display = 'block';
      mfaVerificationCodeContainer.setAttribute('aria-hidden', 'false');

      continueButton.setAttribute('aria-hidden', 'false');
      continueButton.removeAttribute('disabled');
      continueButton.style.display = 'block';
      continueButton.focus();

      mfaVerificationCodeInput?.classList.add('tfl-text-input__input--success');
      return;
    }

    if (!otpCodeSuccessMessageShowing && !hasOtpCodeSuccessMessage) {
      otpCodeSuccessMessageContainer.style.display = 'block';
      otpCodeSuccessMessageContainer.setAttribute('aria-hidden', 'false');
    }
  };

  return [callback];
};
export default useMfaOtpCallback;
