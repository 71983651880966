import { useEffect } from 'react';
import {
  useAddAlertSummary,
  useAddAlertSummaryMutationObserver,
  useAddPasscodeSecuritySpan,
  useB2cContainer,
  useButton,
  useEmptyParagraphRemover,
  useFocusErrorSummaryScrollToTarget,
  useHtml5ValidationRemover,
  useIntroText,
  useMoveErrorContainerBelowInput,
  usePasscode,
  useRemoveElement,
  useStyleInputs,
} from '../../hooks';
import useMutationObserver from '../../hooks/useMutationObserver';
import './ProfileEditPasscode.scss';

const ProfileEditPasscode = () => {
  const [b2cContainer] = useB2cContainer();

  useIntroText();

  useEmptyParagraphRemover();

  useHtml5ValidationRemover();

  useStyleInputs({
    otherInputContainerSelector: '#attributeList .Password .attrEntry',
  });

  useMoveErrorContainerBelowInput({
    selectors: ['.TextBox .attrEntry .error', '.Password .attrEntry .error'],
  });

  useRemoveElement({
    selectors: ['#fieldIncorrect'],
  });

  useAddAlertSummary();

  useAddAlertSummaryMutationObserver();

  // Remove unnecessary element causing style issue + remove Margin-Bottom from first Li
  useEffect(() => {
    if (!b2cContainer) return;

    const secondParagraphLabel = b2cContainer.querySelector(
      '#securityDetails_SecondParagraphText_label'
    );
    secondParagraphLabel?.remove();

    const mustCompleteAllFieldParagraphLabel = b2cContainer.querySelector(
      '#mustCompleteAllFieldsParagraphText_label'
    );
    mustCompleteAllFieldParagraphLabel?.remove();
  }, [b2cContainer]);

  useAddPasscodeSecuritySpan();

  // Create passcode validation markup below passcode input
  useEffect(() => {
    if (!b2cContainer) return;

    const passcodeInput = b2cContainer?.querySelector(
      '#securityPasscode_Answer'
    );
    if (!passcodeInput) return;

    const passcodeContainer = passcodeInput.closest(
      'li.TextBox.securityPasscode_Answer_li'
    );
    if (!passcodeContainer) return;

    const passcodeMatchContainer = b2cContainer?.querySelector(
      '.tfl-passcode-match'
    );

    if (passcodeMatchContainer) return;

    const passcodeMatchItems = [
      {
        id: 'tfl-passcode-length',
        className: 'tfl-passcode-length',
        text: 'Six numbers long',
      },
      {
        id: 'tfl-passcode-number-repetition',
        className: 'tfl-passcode-number-repetition',
        text: 'No numbers three times in a row (for example 555)',
      },
      {
        id: 'tfl-passcode-no-consecutive-number',
        className: 'tfl-passcode-no-consecutive-number',
        text: `No consecutive numbers (for example 123)`,
      },
    ];

    const passcodeMatch = document.createElement('li');
    passcodeMatch.classList.add('tfl-passcode-match');

    const a11ySpan = document.createElement('span');
    const a11ySpanId = 'tfl-passcode-a11y';
    a11ySpan.id = a11ySpanId;
    a11ySpan.classList.add('tfl-passcode-a11y', 'visuallyhidden');

    passcodeMatch.appendChild(a11ySpan);

    passcodeMatchItems.forEach(({ id, className, text }) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('tfl-passcode-match--wrapper');

      const icon = document.createElement('div');
      icon.classList.add(
        className,
        'tfl-passcode-match-icon',
        'tfl-passcode-match-icon--default'
      );

      const description = document.createElement('div');
      description.id = id;
      description.classList.add('tfl-passcode-match--description');
      description.textContent = text;
      description.setAttribute('aria-live', 'polite');

      wrapper.appendChild(icon);
      wrapper.appendChild(description);

      passcodeMatch.appendChild(wrapper);
    });

    passcodeContainer.after(passcodeMatch);
  }, [b2cContainer]);

  // Validate passcode on input and toggle between ✅,❌ & 🔘 icons
  useEffect(() => {
    const passcodeInputSelector = '#securityPasscode_Answer';
    const passcodeInput = b2cContainer?.querySelector(
      passcodeInputSelector
    ) as HTMLInputElement;

    if (!passcodeInput) return;

    const { validate } = usePasscode();

    const handler = ({ target }: Event) => validate(target as HTMLInputElement);

    passcodeInput?.addEventListener('input', handler);

    return () => passcodeInput?.removeEventListener('input', handler);
  }, [b2cContainer]);

  // Toggle show/hide passcode
  useEffect(() => {
    const passcodeInputSelector = '#securityPasscode_Answer';
    const passcodeInput = b2cContainer?.querySelector(
      passcodeInputSelector
    ) as HTMLInputElement;

    if (!passcodeInput) return;

    passcodeInput.setAttribute('type', 'password');
    passcodeInput.classList.add(
      'has-password-toggle--with-validation-indicator'
    );

    const toggleDiv = document.createElement('DIV');
    toggleDiv.classList.add('tfl-passcode-toggle');

    const toggleSpan = document.createElement('SPAN');

    //Add 2 hidden span for accessibility
    const hiddenClickSpan = document.createElement('SPAN');
    hiddenClickSpan.classList.add('hidden-clip');
    hiddenClickSpan.textContent = 'click to';
    const hiddenPasscodeSpan = document.createElement('SPAN');
    hiddenPasscodeSpan.classList.add('hidden-clip');
    hiddenPasscodeSpan.textContent = 'the passcode';

    toggleSpan.textContent =
      passcodeInput.getAttribute('type') === 'password' ? 'Show' : 'Hide';

    // A11y attributes
    toggleDiv.setAttribute('aria-live', 'assertive');
    toggleDiv.setAttribute('aria-atomic', 'true');
    toggleDiv.setAttribute('aria-relevant', 'all');
    toggleDiv.setAttribute('tabindex', '0');

    passcodeInput.classList.add('tfl-text-input__input--hide');
    passcodeInput.after(toggleDiv);

    toggleDiv.append(toggleSpan);

    toggleSpan.parentNode?.prepend(hiddenClickSpan);
    toggleSpan.parentNode?.append(hiddenPasscodeSpan);

    const togglePasscodeHandler = () => {
      const type =
        passcodeInput.getAttribute('type') === 'password' ? 'text' : 'password';
      passcodeInput.setAttribute('type', type);
      toggleSpan.innerHTML === 'Show'
        ? (toggleSpan.innerHTML = 'Hide')
        : (toggleSpan.innerHTML = 'Show');
    };
    toggleDiv.addEventListener('click', togglePasscodeHandler);

    // A11y toggleDiv
    const keyAccessibilityHandler = (e: { key: string }) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        toggleSpan.click();
      }
    };
    toggleDiv.addEventListener('keydown', keyAccessibilityHandler);

    return () => {
      toggleDiv.removeEventListener('click', togglePasscodeHandler);
      toggleDiv.removeEventListener('keydown', keyAccessibilityHandler);
    };
  }, [b2cContainer]);

  // Set default icons class from 🔘 to ❌
  useEffect(() => {
    const continueButtonSelector = '#continue';
    const passcodeInputSelector = '#securityPasscode_Answer';
    const continueButton = b2cContainer?.querySelector(
      continueButtonSelector
    ) as HTMLInputElement;
    const passcodeInput = b2cContainer?.querySelector(
      passcodeInputSelector
    ) as HTMLInputElement;

    if (!continueButton || !passcodeInput) return;

    const handler = () => {
      const event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      passcodeInput.setAttribute(
        'data-default-icon-class',
        'tfl-passcode-match-icon--failure'
      );
      passcodeInput.dispatchEvent(event);
    };

    continueButton?.addEventListener('click', handler);

    return () => continueButton?.removeEventListener('click', handler);
  }, [b2cContainer]);

  // Add continue button styles
  useButton({
    selector: 'continue',
    type: 'button',
    variant: 'primary',
  });

  // Add cancel button styles
  useButton({
    selector: 'cancel',
    type: 'button',
    variant: 'secondary',
  });

  // Move cancel button next to continue button
  useEffect(() => {
    if (!b2cContainer) return;
    const cancelButton = b2cContainer.querySelector('#cancel') as HTMLElement;
    const continueButton =
      b2cContainer?.querySelector('#continue')?.parentElement;
    if (!cancelButton) return;
    continueButton?.appendChild(cancelButton);
  }, [b2cContainer]);

  // Add mutation observer for all inputs error states
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainersSelector = '.attrEntry .error.itemLevel';
    const errorContainers = Array.from(
      b2cContainer?.querySelectorAll(errorContainersSelector)
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';

    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (!errorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target, type } = mutation;
      const errorContainer = target as Element;
      const parentElement = errorContainer?.parentElement;
      const element = parentElement?.querySelector('input');

      if (!element) return;

      const errorContainerHasTextContent =
        !!errorContainer?.textContent?.length;
      const showingError =
        (errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
          'false') !==
        undefined;

      if (type === 'childList' && !errorContainerHasTextContent) {
        element?.classList.remove(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.remove(classname)
        );
        return;
      }

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      // remove default error class or ovveride style in css
      element?.classList.remove('invalid');

      element?.classList.remove(textInputErrorClassname);
      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainer?.classList.remove(classname)
      );

      if (showingError && errorContainerHasTextContent) {
        element?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.add(classname)
        );
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: errorContainersSelector,
        config: {
          childList: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Set focus to error summary on form submission failure
  useFocusErrorSummaryScrollToTarget({
    buttonSelectors: ['#continue'],
  });

  // Invoke passcode validation on component mount
  useEffect(() => {
    if (!b2cContainer) return;

    const passcodeInputSelector = '#securityPasscode_Answer';
    const passcodeInput = b2cContainer?.querySelector(
      passcodeInputSelector
    ) as HTMLInputElement;

    if (!passcodeInput) return;

    const event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });

    passcodeInput.dispatchEvent(event);
  }, [b2cContainer]);

  return <></>;
};

export default ProfileEditPasscode;
