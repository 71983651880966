import { useEffect } from 'react';
import { useB2cContainer } from '.';

type Props = {
  selectors: string[];
};
const useStyleSubText = ({ selectors }: Props) => {
  const [b2cContainer] = useB2cContainer();
  useEffect(() => {
    if (!b2cContainer || !selectors.length) return;

    const targetElements = selectors
      .map(
        (selector) =>
          Array.from(b2cContainer.querySelectorAll(selector)) as HTMLElement[]
      )
      .flat();
    targetElements.forEach((element) => {
      const labelText = element.innerText;

      const subTextPattern = /(\(.*\))/;
      const subTextMatch = labelText.match(subTextPattern);

      if (!subTextMatch) return;

      element.textContent = labelText.replace(subTextPattern, '');
      const subtext = document.createElement('SPAN');
      subtext.classList.add('tfl-subtext');
      subtext.innerText = subTextMatch[0];
      element.append(subtext);
      element.setAttribute('data-original-text', labelText);
    });
  }, [b2cContainer, selectors]);
};

export default useStyleSubText;
