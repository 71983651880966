import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

export const useUpdatePageTitle = (targetSelector: string) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer || !targetSelector) return;

    const target = b2cContainer.querySelector(targetSelector);
    if (!target) return;

    const pageHeading = target.textContent?.trim();
    if (!pageHeading?.length) return;

    const pageTitlePostfix = `${process.env.REACT_APP_PAGE_TITLE_POSTFIX}`;

    document.title = [pageHeading, pageTitlePostfix].join(' - ');
  }, [b2cContainer]);
};

export default useUpdatePageTitle;
