import { useB2cContainer } from '.';

const useHasProfileEditConfirmSecurityQuestionStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasProfileEditConfirmSecurityQuestionStep =
    b2cContainer?.querySelector('#securityQuestion_AnswerCheck') !== null &&
    b2cContainer?.querySelector('#securityQuestion_DropdownList_label') !==
      null;

  return [hasProfileEditConfirmSecurityQuestionStep];
};

export default useHasProfileEditConfirmSecurityQuestionStep;
