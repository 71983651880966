import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '.';
import { CombinedPolicies, Policies } from '../components/policies';

const usePolicy = (): string | null | undefined => {
  const query = useQuery();
  const location = useLocation();

  const [policy, setPolicy] = useState<string | null | undefined>(
    Policies.Unknown
  );

  const getPolicy = (): string | null | undefined => {
    const policyFromQueryParam = query.get('p')?.toLowerCase();
    const policyFromUrlSegment = getPolicyFromUrlSegment(Policies);
    const combinedPolicy = getPolicyFromUrlSegment(CombinedPolicies);
    const policy = policyFromQueryParam || policyFromUrlSegment || null;

    const isSigninOrLegacy =
      policyFromQueryParam === Policies.SignInB2c ||
      policyFromQueryParam === Policies.SignIn ||
      policyFromQueryParam === Policies.SignInLegacy;

    if (
      isSigninOrLegacy &&
      (combinedPolicy === CombinedPolicies.SignInSignUp ||
        combinedPolicy === CombinedPolicies.SelfAsserted)
    )
      return Policies.SignUp;

    const isPasswordResetWithSignIn =
      policyFromQueryParam === Policies.PasswordReset &&
      combinedPolicy === CombinedPolicies.SelfAsserted;

    if (isPasswordResetWithSignIn) {
      return Policies.PasswordResetOnSignInSignUp;
    }

    return policy?.toLowerCase();
  };

  const getPolicyFromUrlSegment = (
    policies: Record<string, string>
  ): string | undefined => {
    return location.pathname
      .toLowerCase()
      .split('/')
      .find((segment) => Object.values(policies).includes(segment));
  };

  useEffect(() => setPolicy(getPolicy), [query, getPolicy]);
  return policy;
};

export default usePolicy;
