import { useEffect } from 'react';
import { useB2cContainer } from '.';

const useMoveIntroText = () => {
  const [b2cContainer] = useB2cContainer();

  // Move intro text inside form
  useEffect(() => {
    if (!b2cContainer) return;

    const form = b2cContainer.querySelector('#attributeVerification');
    const title = b2cContainer.querySelector('.intro');

    if (!title) return;

    form?.prepend(title);
  }, [b2cContainer]);
};

export default useMoveIntroText;
