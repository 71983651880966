import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
} from 'react';
import {
  PostcodeDetailType,
  PostcodePicklistType,
} from '../types/PostcodeLookup.types';

const Address = createContext({
  state: {} as Partial<AddressState>,
  setState: {} as Dispatch<SetStateAction<AddressState>>,
});

const AddressProvider = ({
  children,
  value = {} as AddressState,
}: {
  children: React.ReactNode;
  value?: AddressState;
}) => {
  const [state, setState] = useState(value);
  return (
    <Address.Provider value={{ state, setState }}>{children}</Address.Provider>
  );
};

const useAddressState = () => {
  const context = useContext(Address);
  if (!context) {
    throw new Error('useAddressState must be used within a Address');
  }
  return context;
};

const Debug = (data?: Record<string, unknown>) => {
  const { state } = useAddressState();
  return (
    <pre style={{ position: 'absolute', top: '100px' }}>
      {JSON.stringify(state || {}, null, 2)}
      <hr />
      {JSON.stringify(data || {}, null, 2)}
      <hr />
    </pre>
  );
};

export type PostcodeDetailByKeyType = Map<
  string,
  PostcodeDetailType | undefined
>;

export interface AddressState {
  picklist?: PostcodePicklistType[];
  details?: PostcodeDetailByKeyType;
}

export { AddressProvider, Debug, useAddressState };
