import { useEffect } from 'react';
import { useB2cContainer } from '.';

interface StyleInputProps {
  otherInputContainerSelector?: string;
}

// Add tfl branded styles to input containers/inputs/labels
const useStyleInputs = (props?: StyleInputProps) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    let inputContainers = Array.from(
      b2cContainer?.querySelectorAll('.TextBox .attrEntry')
    );

    if (props?.otherInputContainerSelector) {
      const otherInputContainers = Array.from(
        b2cContainer?.querySelectorAll(props.otherInputContainerSelector)
      );

      if (!otherInputContainers) return;

      inputContainers = inputContainers.concat(otherInputContainers);
    }

    inputContainers?.forEach((container) =>
      container?.classList.add('tfl-text-input')
    );

    const labels = inputContainers?.map((container) =>
      container.querySelector('label')
    );

    const inputs = inputContainers?.map((container) =>
      container.querySelector('input')
    );

    labels?.forEach((container) =>
      container?.classList.add('tfl-text-input__label')
    );

    inputs?.forEach((container) =>
      container?.classList.add('tfl-text-input', 'tfl-text-input__input')
    );
  }, [b2cContainer]);
};

export default useStyleInputs;
