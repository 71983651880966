import { useEffect } from 'react';
import { useB2cContainer } from '.';

const useMoveContinueButton = () => {
  const [b2cContainer] = useB2cContainer();

  // Move continue button next to other buttons
  useEffect(() => {
    if (!b2cContainer) return;

    const continueButton = b2cContainer.querySelector(
      '#continue'
    ) as HTMLElement;

    if (!continueButton) return;

    const continueButtonContainer =
      b2cContainer.querySelector('#continue')?.parentElement;

    if (!continueButtonContainer) return;

    const targetContainer = b2cContainer?.querySelector(
      '#attributeList .buttons'
    );

    if (!targetContainer) return;

    targetContainer.appendChild(continueButton);

    continueButtonContainer.remove();
  }, [b2cContainer]);
};

export default useMoveContinueButton;
