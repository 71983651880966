import { useEffect, useState } from 'react';
import { useB2cContainer } from '.';

const useHasSignupStep = () => {
  const [b2cContainer] = useB2cContainer();
  const [signupStep, setSignupStep] = useState(false);

  useEffect(() => {
    if (!b2cContainer) return;

    setSignupStep(
      () => b2cContainer.querySelector('#checkAccountExistsHeaderText') !== null
    );
  }, [b2cContainer, setSignupStep]);

  return [signupStep];
};
export default useHasSignupStep;
