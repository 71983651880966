import { useEffect } from 'react';
import {
  useB2cContainer,
  useButton,
  useEmptyParagraphRemover,
  useFocusErrorSummaryScrollToTarget,
  useHasAddressStep,
  useHasMfaChallengeStep,
  useHasMfaSetupStep,
  useHasOneQuickThingStep,
  useHasPasscodeStep,
  useHtml5ValidationRemover,
  useMutationObserver,
  usePassword,
  useRemoveAttribute,
  useStyleInputs,
  useMfaOtpCallback,
  useEnterKeydownHandler,
  useUpdatePageTitle,
  useStyleSubText,
  useAddPasswordSecuritySpan,
} from '../../hooks';
import {
  MfaSetup,
  MfaChallenge,
  OneQuickThing,
  Address,
  Security,
} from '../index';
import { DisconnectFunction } from '../../hooks/useMutationObserver';
import './Signup.scss';
import { typedBoolean } from '../../types/tfl-types';

const Signup = () => {
  const [b2cContainer] = useB2cContainer();
  const { hasMfaSetupStep } = useHasMfaSetupStep();
  const [hasOneQuickThingStep] = useHasOneQuickThingStep();
  const [hasAddressStep] = useHasAddressStep();
  const [hasPasscodeStep] = useHasPasscodeStep();
  const [hasMfaChallengeStep] = useHasMfaChallengeStep();
  const [callback] = useMfaOtpCallback();

  // Add Alert Summary
  useEffect(() => {
    if (!b2cContainer || hasMfaSetupStep) return;

    const passwordMismatch = b2cContainer?.querySelector(
      '#passwordEntryMismatch'
    ) as HTMLElement;

    if (passwordMismatch) passwordMismatch.style.display = 'none';

    const errorSummaryHeader = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;

    if (!errorSummaryHeader) return;

    errorSummaryHeader?.classList.add('tfl-alert', 'tfl-alert--error');
    errorSummaryHeader?.setAttribute('aria-labelledby', 'tfl-alert-heading');

    const errorSummaryText = errorSummaryHeader.innerText;
    errorSummaryHeader.innerText = '';

    const alertContainerH2 = document.createElement('H2');
    alertContainerH2?.classList.add(
      'tfl-alert__header',
      'tfl-alert__header--with-description'
    );
    errorSummaryHeader?.prepend(alertContainerH2);
    alertContainerH2.textContent = errorSummaryText;
    alertContainerH2.id = 'tfl-alert-heading';

    // Add UL links
    const alertContent = document.createElement('DIV');
    alertContent.classList.add('tfl-alert__content');

    errorSummaryHeader.prepend(alertContent);
    errorSummaryHeader.insertBefore(alertContainerH2, alertContent);

    const inputInlineErrorContainers = Array.from(
      b2cContainer.querySelectorAll('#attributeList .error.itemLevel')
    );

    const errorSummaryLinks = inputInlineErrorContainers.map((container) => {
      // only interested in container with a sibling INPUT or SELECT element
      const targetControl = Array.from(
        container?.parentNode?.children ?? []
      ).find((c) => c.tagName === 'INPUT' || c.tagName === 'SELECT');

      if (!targetControl?.id) return;

      const link = document.createElement('A');
      link.id = `skip-link-${targetControl?.id}`;
      link.setAttribute('href', `#${targetControl?.id}`);
      link.classList.add('tfl-alert-summary__link-text');
      link.textContent = container.textContent;

      link.addEventListener('click', (event) => {
        event.preventDefault();
        const link = event.target as HTMLElement;
        const targetInputId = link.getAttribute('href');
        if (!targetInputId) return;
        const targetInput = b2cContainer.querySelector(
          targetInputId
        ) as HTMLElement;
        targetInput?.focus();
      });

      const errorSummaryLink = {
        targetId: `${targetControl?.id}`,
        element: link,
      };

      return errorSummaryLink;
    });

    const ulLinks = document.createElement('UL');
    ulLinks.classList.add('tfl-alert-summary');
    alertContent.prepend(ulLinks);

    errorSummaryLinks.filter(typedBoolean).forEach((link) => {
      const listItem = document.createElement('LI');
      listItem.classList.add(`tfl-alert-summary__link`, link?.targetId, 'hide');
      listItem.appendChild(link.element);

      ulLinks.appendChild(listItem);
    });
  }, [b2cContainer]);

  // Add tfl branded styles to dropdown/labels
  useEffect(() => {
    if (!b2cContainer) return;
    const dropdownContainer = Array.from(
      b2cContainer?.querySelectorAll('.DropdownSingleSelect')
    );

    dropdownContainer?.forEach((container) =>
      container?.classList.add('tfl-dropdown')
    );

    const labels = dropdownContainer?.map((container) =>
      container.querySelector('label')
    );
    labels?.forEach((container) =>
      container?.classList.add('tfl-dropdown__label')
    );

    const dropdown = dropdownContainer?.map((container) =>
      container.querySelector('.dropdown_single')
    );

    dropdown?.forEach((container) =>
      container?.classList.add('tfl-dropdown__container')
    );
  }, [b2cContainer]);

  // Move dropdown error containers below dropdown
  useEffect(() => {
    if (!b2cContainer) return;

    const findYourAddressButtonContainer = b2cContainer.querySelector(
      '.tfl-find-your-address-container'
    );

    // Don't do it for address page
    if (findYourAddressButtonContainer) return;

    const dropdownErrorContainers = b2cContainer.querySelectorAll(
      '.DropdownSingleSelect .attrEntry .error'
    );

    dropdownErrorContainers.forEach((container) => {
      const dropdown = container.parentNode?.querySelector('select');
      container.classList.add(`${dropdown?.id}`);
      container.setAttribute(
        'data-summary-link',
        `.tfl-alert-summary__link.${dropdown?.id}`
      );
    });
    dropdownErrorContainers.forEach((container) =>
      container.parentNode?.appendChild(container)
    );
  }, [b2cContainer]);

  // Add tfl branded styles to input containers/inputs/labels
  // Add Tfl branded styles to create password input
  useStyleInputs({ otherInputContainerSelector: '#attributeList .attrEntry' });

  // intro text
  useEffect(() => {
    if (!b2cContainer) return;

    if (hasMfaChallengeStep || hasMfaSetupStep || hasOneQuickThingStep) return;

    const form = b2cContainer?.querySelector('#attributeVerification');

    if (!form) return;

    const headingTextSourceElement = b2cContainer?.querySelector(
      '.Paragraph .attrEntry .textInParagraph'
    ) as HTMLElement;

    if (!headingTextSourceElement) return;

    const headingElement = b2cContainer.querySelector('.heading h1');

    if (!headingElement) return;

    const textIntro = headingTextSourceElement.textContent ?? '';
    headingElement.textContent = textIntro;
    headingElement.setAttribute('data-original-text', textIntro);

    headingTextSourceElement.parentElement?.parentElement?.remove();
  }, [b2cContainer]);

  // Remove html5 validation
  useHtml5ValidationRemover();

  // Remove all empty li Paragraph
  useEmptyParagraphRemover();

  // Handle enter key press on email input field when setting up an email address
  useEnterKeydownHandler({
    inputFieldSelector: '#signInName',
    targetButtonSelector: '#registerEmailVerificationControl_but_send_code',
  });

  // Remove help link texts
  useEffect(() => {
    if (!b2cContainer) return;
    b2cContainer
      .querySelectorAll('.helpLink.tiny')
      .forEach((helpText) => helpText.remove());
  }, [b2cContainer]);

  // Move inline error containers below the input
  useEffect(() => {
    if (!b2cContainer) return;

    const findYourAddressButtonContainer = b2cContainer.querySelector(
      '.tfl-find-your-address-container'
    );

    if (findYourAddressButtonContainer || hasMfaChallengeStep) return;

    const errorContainers = b2cContainer.querySelectorAll(
      '.TextBox .attrEntry .error'
    );

    errorContainers.forEach((container) => {
      const input = container.parentNode?.querySelector('input');
      container.classList.add(`${input?.id}`);
      container.setAttribute(
        'data-summary-link',
        `.tfl-alert-summary__link.${input?.id}`
      );
    });
    errorContainers.forEach((container) =>
      container.parentNode?.appendChild(container)
    );
  }, [b2cContainer]);

  // Move & style error container for create password
  useEffect(() => {
    if (!b2cContainer) return;

    const errorPasswordContainer = b2cContainer.querySelector(
      '.attrEntry.validate .error.itemLevel'
    );

    if (!errorPasswordContainer) return;

    errorPasswordContainer.classList.add(
      'tfl-validation-error',
      'tfl-validation-error--attached'
    );

    const input = errorPasswordContainer?.parentNode?.querySelector('input');
    input?.parentNode?.appendChild(errorPasswordContainer);
  }, [b2cContainer]);

  // Add TfL branded styles to all input/dropdown error containers
  useEffect(() => {
    if (!b2cContainer) return;
    const inputErrorContainers = Array.from(
      b2cContainer.querySelectorAll(
        '.TextBox .attrEntry.validate .error.itemLevel'
      )
    ) as HTMLElement[];

    const dropdownErrorContainers = Array.from(
      b2cContainer.querySelectorAll(
        '.DropdownSingleSelect .attrEntry.validate .error.itemLevel'
      )
    ) as HTMLElement[];

    [...inputErrorContainers, ...dropdownErrorContainers].forEach((c) => {
      c.classList.add('tfl-validation-error', 'tfl-validation-error--attached');
    });
  }, [b2cContainer]);

  // Style terms and conditions paragraph/link
  useEffect(() => {
    if (!b2cContainer) return;

    const firstParagraph = b2cContainer?.querySelector(
      '#termsAndConditionsFirstParagraphText'
    ) as HTMLElement;

    const secondParagraph = b2cContainer?.querySelector(
      '#termsAndConditionsSecondParagraphText'
    ) as HTMLElement;

    const firstParagraphLabel = b2cContainer?.querySelector(
      '#termsAndConditionsFirstParagraphText_label'
    );

    firstParagraphLabel?.remove();

    if (!secondParagraph) return;
    const linkText = secondParagraph.innerText;
    secondParagraph.parentElement?.remove();

    if (!firstParagraph) return;
    firstParagraph.removeAttribute('aria-label');
    //Add 2 hidden span for accessibility
    const hiddenSpan = document.createElement('SPAN');
    hiddenSpan.classList.add('hidden-clip');
    hiddenSpan.textContent = 'Opens in a new tab';

    const termsConditionsLink = document.createElement('A');
    Object.assign(termsConditionsLink, {
      href: 'https://tfl.gov.uk/corporate/terms-and-conditions/',
      target: '_blank',
    });
    termsConditionsLink.innerText = linkText;

    firstParagraph.innerText += ' ';
    firstParagraph.prepend(termsConditionsLink);
    firstParagraph.appendChild(termsConditionsLink);
    termsConditionsLink.appendChild(hiddenSpan);
  }, [b2cContainer]);

  // Style privacy statement
  useEffect(() => {
    if (!b2cContainer) return;

    const secondParagraph = b2cContainer?.querySelector(
      '#privacyStatementSecondParagraphText'
    ) as HTMLElement;

    const thirdParagraph = b2cContainer?.querySelector(
      '#privacyStatementThirdParagraphText'
    ) as HTMLElement;

    const firstParagraphLabel = b2cContainer?.querySelector(
      '#privacyStatementFirstParagraphText_label'
    );

    firstParagraphLabel?.remove();

    //Add 2 hidden span for accessibility
    const hiddenSpan = document.createElement('SPAN');
    hiddenSpan.classList.add('hidden-clip');
    hiddenSpan.textContent = 'Opens in a new tab';

    if (!thirdParagraph) return;
    const linkText = thirdParagraph.innerText;
    thirdParagraph.parentElement?.remove();

    const privacyLink = document.createElement('A');
    Object.assign(privacyLink, {
      href: 'https://tfl.gov.uk/corporate/privacy-and-cookies/?cid=privacy',
      target: '_blank',
    });
    privacyLink.innerText = linkText;
    privacyLink.setAttribute(
      'aria-label',
      'Privacy and cookies pages. Opens in a new tab.'
    );

    if (!secondParagraph) return;
    secondParagraph.removeAttribute('aria-label');
    secondParagraph.innerText += ' ';
    secondParagraph.prepend(privacyLink);
    secondParagraph.appendChild(privacyLink);
    privacyLink.appendChild(hiddenSpan);
  }, [b2cContainer]);

  // Style congratulation you are all set page
  useEffect(() => {
    if (!b2cContainer) return;

    const congratulationParagrahLabel = b2cContainer?.querySelector(
      '#congratulationsParagraphText_label'
    );
    congratulationParagrahLabel?.remove();
  }, [b2cContainer]);

  // Add Tfl branded styles to checkbox
  useEffect(() => {
    if (!b2cContainer) return;

    const checkboxOffersFromTfl = b2cContainer?.querySelector(
      '#termsOfUseConsentChoice_AgreeToReceiveOffersFromTfLYes'
    );
    checkboxOffersFromTfl?.classList.add('tfl-checkbox__input');

    const labelOffersFromtfl = b2cContainer?.querySelector(
      '#AgreeToReceiveOffersFromTfLYes_option'
    );
    labelOffersFromtfl?.classList.add('tfl-checkbox__label');

    const checkboxOffersFromToc = b2cContainer?.querySelector(
      '#termsOfUseConsentChoice_AgreeToReceiveOffersFromTocYes'
    );
    checkboxOffersFromToc?.classList.add('tfl-checkbox__input');

    const labelOffersFromToc = b2cContainer?.querySelector(
      '#AgreeToReceiveOffersFromTocYes_option'
    );
    labelOffersFromToc?.classList.add('tfl-checkbox__label');

    const checkboxMultiSelect = b2cContainer?.querySelector(
      '.CheckboxMultiSelect .attrEntry'
    );

    const tflPromotionContainer = document.createElement('DIV');
    tflPromotionContainer.classList.add('tfl-checkbox');

    const tocPromotionContainer = document.createElement('DIV');
    tocPromotionContainer.classList.add('tfl-checkbox');

    checkboxMultiSelect?.parentNode?.appendChild(tflPromotionContainer);
    checkboxMultiSelect?.parentNode?.appendChild(tocPromotionContainer);

    if (
      !checkboxOffersFromTfl ||
      !checkboxOffersFromToc ||
      !labelOffersFromtfl ||
      !labelOffersFromToc
    )
      return;

    tocPromotionContainer.prepend(labelOffersFromToc);
    tocPromotionContainer.prepend(checkboxOffersFromToc);
    tflPromotionContainer.prepend(labelOffersFromtfl);
    tflPromotionContainer.prepend(checkboxOffersFromTfl);
  }, [b2cContainer]);

  // Get label text for checkboxes
  useEffect(() => {
    if (!b2cContainer) return;

    const textInParagraph = b2cContainer?.querySelector(
      '#offersAndPromotionsHeaderText'
    ) as HTMLElement;

    if (!textInParagraph) return;

    const checkboxLabelParagraph = textInParagraph.innerText;
    textInParagraph.parentElement?.remove();

    const checkboxLabel = b2cContainer?.querySelector(
      '#termsOfUseConsentChoice_label'
    ) as HTMLElement;

    if (!checkboxLabel) return;
    checkboxLabel.innerText = checkboxLabelParagraph;
  }, [b2cContainer]);

  // Unbold (optional) in label
  useStyleSubText({
    selectors: [
      '#contactMobileNumber_label',
      '#contactOtherNumber_label',
      '#termsOfUseConsentChoice_label',
    ],
  });

  // Add send verification code button styles
  useEffect(() => {
    if (!b2cContainer) return;
    const verificationCodeButton = b2cContainer.querySelector(
      '.verificationControlContent .buttons .sendCode'
    );
    verificationCodeButton?.classList.add('tfl-button', 'tfl-button--primary');
  }, [b2cContainer]);

  // Add verify code button styles
  useButton({
    selector: 'registerEmailVerificationControl_but_verify_code',
    type: 'button',
    variant: 'primary',
  });

  // Add send new code button styles
  useButton({
    selector: 'registerEmailVerificationControl_but_send_new_code',
    type: 'button',
    variant: 'secondary',
  });

  // Add continue button styles
  useButton({
    selector: 'continue',
    type: 'button',
    variant: 'primary',
  });

  // Move continue button next to other buttons
  useEffect(() => {
    if (!b2cContainer) return;
    const continueButton = b2cContainer.querySelector(
      '#continue'
    ) as HTMLElement;
    const targetContainer = b2cContainer?.querySelector(
      '#registerEmailVerificationControl_but_send_code'
    )?.parentElement;
    if (!continueButton) return;
    targetContainer?.appendChild(continueButton);
  }, [b2cContainer]);

  // Hide continue button on landing page
  useEffect(() => {
    if (!b2cContainer) return;

    const continueButton = b2cContainer.querySelector(
      '#continue'
    ) as HTMLElement;
    const sendVerificationButton = b2cContainer?.querySelector(
      '#registerEmailVerificationControl_but_send_code'
    );

    if (!sendVerificationButton) return;
    if (continueButton) {
      continueButton.setAttribute('aria-hidden', 'true');
      continueButton.style.display = 'none';
    }
  }, [b2cContainer]);

  // Move and append all buttons to bottom of the card
  useEffect(() => {
    if (!b2cContainer) return;

    const buttonsContainer = b2cContainer.querySelector(
      '#registerEmailVerificationControl_but_send_code'
    )?.parentNode;

    const form = b2cContainer?.querySelector('#attributeVerification');

    if (!buttonsContainer) return;
    form?.appendChild(buttonsContainer);
  }, [b2cContainer]);

  // Add sign in link
  useEffect(() => {
    if (!b2cContainer) return;

    const signInParagraph = b2cContainer?.querySelector(
      '#signInLinkParagraphText'
    );

    if (!signInParagraph) return;

    if (b2cContainer.querySelector('.tfl-signin-div')) return;

    if (hasMfaSetupStep || hasMfaChallengeStep) return;

    const signInSpan = document.createElement('DIV');
    const link = document.createElement('A');
    link.setAttribute('href', `${process.env.REACT_APP_SIGN_IN_LINK}`);
    link.innerText = 'Sign in';
    signInSpan.classList.add('tfl-signin-div');

    const verificationCodeButton = b2cContainer.querySelector('.sendCode');

    signInParagraph.append(link);
    signInSpan.append(signInParagraph);
    verificationCodeButton?.parentNode?.appendChild(signInSpan);
  }, [b2cContainer]);

  // Move verification message above enter code input label
  useEffect(() => {
    if (!b2cContainer) return;
    const verificationSuccessContainer = b2cContainer.querySelector(
      '.verificationSuccessText'
    );
    const enterCodeContainer = b2cContainer.querySelector(
      '#emailVerificationCode_label'
    )?.parentNode;

    if (!verificationSuccessContainer) return;
    enterCodeContainer?.prepend(verificationSuccessContainer);
  }, [b2cContainer]);

  // Remove style-list
  useEffect(() => {
    if (!b2cContainer) return;
    const list = b2cContainer?.querySelectorAll('ul');
    list?.forEach((el) => el.setAttribute('style', 'list-style:none'));
  }, [b2cContainer]);

  // Remove inline style attribute from all list items
  useRemoveAttribute({
    attribute: 'style',
    selector: 'li[style="display: inline;"]',
  });

  // Remove unnecessary elements causing style issues
  useEffect(() => {
    if (!b2cContainer) return;
    b2cContainer
      .querySelector('#registerEmailVerificationControl_label')
      ?.remove();
    b2cContainer
      .querySelector('#checkAccountExistsParagraphText_label')
      ?.remove();

    // Privacy screen
    b2cContainer
      .querySelector('#privacyStatementSecondParagraphText_label')
      ?.remove();

    // Password screen
    b2cContainer.querySelector('#fieldIncorrect')?.remove();

    const intro = b2cContainer.querySelector('.intro p');
    if (!intro) return;
    intro.textContent = '';
  }, [b2cContainer]);

  // Move password error container below the input
  useEffect(() => {
    if (!b2cContainer) return;

    const passwordErrorContainer = b2cContainer.querySelector(
      '.Password .error.itemLevel'
    );

    if (!passwordErrorContainer) return;

    const input = passwordErrorContainer?.parentNode?.querySelector('input');

    passwordErrorContainer?.classList.add(`${input?.id}`);
    passwordErrorContainer?.setAttribute(
      'data-summary-link',
      `.tfl-alert-summary__link.${input?.id}`
    );

    passwordErrorContainer.parentNode?.appendChild(passwordErrorContainer);
  }, [b2cContainer]);

  // Create password validation markup below the input
  useEffect(() => {
    if (!b2cContainer) return;

    const passwordContainer = b2cContainer.querySelector(
      '#attributeList .Password'
    );
    if (!passwordContainer) return;

    const passwordInput = passwordContainer?.querySelector('#newPassword');
    passwordInput?.setAttribute('maxlength', '256');
    if (!passwordInput) return;

    passwordInput.classList.add(
      'has-password-toggle--with-validation-indicator'
    );

    const passwordMatchContainer = b2cContainer?.querySelector(
      '.tfl-password-match'
    );

    if (passwordMatchContainer) return;

    const passwordMatchItems = [
      {
        id: 'tfl-password-length',
        className: 'tfl-password-length',
        text: 'Minimum of eight characters',
      },
      {
        id: 'tfl-password-case',
        className: 'tfl-password-case',
        text: 'Capital and lower case letters',
      },
      {
        id: 'tfl-password-number-special-character',
        className: 'tfl-password-number-special-character',
        text: `At least one number or special character such as ?!@£$%^&*()`,
      },
    ];

    const passwordMatch = document.createElement('li');
    passwordMatch.classList.add('tfl-password-match');

    const a11ySpan = document.createElement('span');
    a11ySpan.classList.add('tfl-password-a11y', 'visuallyhidden');

    passwordMatch.appendChild(a11ySpan);

    passwordMatchItems.forEach(({ id, className, text }) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('tfl-password-match--wrapper');

      const icon = document.createElement('div');
      icon.classList.add(
        className,
        'tfl-password-match-icon',
        'tfl-password-match-icon--default'
      );

      const a11yIds = passwordMatchItems.map((item) => item.id).join(' ');
      passwordInput.setAttribute('aria-describedby', a11yIds);

      const description = document.createElement('div');
      description.id = id;
      description.classList.add('tfl-password-match--description');
      description.textContent = text;
      description.setAttribute('aria-live', 'polite');

      wrapper.appendChild(icon);
      wrapper.appendChild(description);

      passwordMatch.appendChild(wrapper);
    });

    passwordContainer.after(passwordMatch);
  }, [b2cContainer]);

  // Change title drop down placeholder to "Choose your title"
  useEffect(() => {
    if (!b2cContainer) return;

    const titleDropDown = b2cContainer.querySelector(
      '#title'
    ) as HTMLSelectElement;

    if (!titleDropDown) return;

    const titleDropDownOptions = Array.from(titleDropDown.options);

    const placeholderOption = titleDropDownOptions.find(
      (option) => option.hasAttribute('disabled') && option.selected
    );

    if (!placeholderOption) return;

    placeholderOption.textContent = 'Choose your title';
  }, [b2cContainer]);

  // Handle enter key press on verify code input field when verifying email address
  useEnterKeydownHandler({
    inputFieldSelector: '#emailVerificationCode',
    targetButtonSelector: '#registerEmailVerificationControl_but_verify_code',
  });

  // Attach side effects for event handlers/mutation observers below this line
  // Attach event handlers side effects Alert Summary
  useEffect(() => {
    // control input box and dropdown css error states
    if (!b2cContainer) return;

    const findYourAddressButtonContainer = b2cContainer.querySelector(
      '.tfl-find-your-address-container'
    );

    // Don't use this mutation observer for Address page and Mfa
    if (
      findYourAddressButtonContainer ||
      hasMfaSetupStep ||
      hasMfaChallengeStep
    )
      return;

    const errorSummaryContainer = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;

    if (!errorSummaryContainer) return;

    const inputs = Array.from(
      b2cContainer?.querySelectorAll('input')
    ) as HTMLElement[];

    const dropdowns = Array.from(
      b2cContainer?.querySelectorAll('select')
    ) as HTMLElement[];

    const inputErrorContainerSelectors = inputs.map(
      (input) => `.error.itemLevel.${input.id}`
    );

    const dropdownErrorContainerSelectors = dropdowns.map(
      (dropdown) => `.error.itemLevel.${dropdown?.id}`
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';
    const dropdownErrorClassname = 'tfl-dropdown__container--error';
    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    const errorContainerSelectors = [
      ...inputErrorContainerSelectors,
      ...dropdownErrorContainerSelectors,
    ];
    if (!errorContainerSelectors.length) return;

    const inputErrorContainers = errorContainerSelectors.map((selector) =>
      b2cContainer.querySelector(selector)
    );
    if (!inputErrorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const targetErrorContainer = target as Element;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const hasInlineErrorsOnThePage =
        inputErrorContainers.find(
          (container) =>
            container?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
            'false'
        ) !== undefined;

      errorSummaryContainer.setAttribute(
        'aria-hidden',
        hasInlineErrorsOnThePage ? 'false' : 'true'
      );
      errorSummaryContainer.style.display = hasInlineErrorsOnThePage
        ? 'block'
        : 'none';

      const targetSummaryItemLinkSelector =
        targetErrorContainer.attributes.getNamedItem(
          'data-summary-link'
        )?.nodeValue;

      if (!targetSummaryItemLinkSelector) return;

      let targetSummaryItemLink: HTMLElement | null | undefined = undefined;

      targetSummaryItemLink = b2cContainer?.querySelector(
        targetSummaryItemLinkSelector
      ) as HTMLElement;

      if (!targetSummaryItemLink) return;

      const dropdown =
        targetErrorContainer?.parentElement?.querySelector('select');
      const input = targetErrorContainer?.parentElement?.querySelector('input');

      const errorContainerShowingError =
        targetErrorContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      // update alert summary link
      errorContainerShowingError
        ? (targetSummaryItemLink.classList.add('show'),
          targetSummaryItemLink.classList.remove('hide'))
        : (targetSummaryItemLink.classList.add('hide'),
          targetSummaryItemLink.classList.remove('show'));

      targetSummaryItemLink.setAttribute(
        'aria-hidden',
        errorContainerShowingError ? 'false' : 'true'
      );

      const skipLink = targetSummaryItemLink.querySelector('a');
      if (skipLink) {
        skipLink.textContent = errorContainerShowingError
          ? targetErrorContainer?.textContent
          : '';
      }

      if (errorContainerShowingError) {
        dropdown?.classList.add(dropdownErrorClassname);
        input?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          targetErrorContainer?.classList.add(classname)
        );
        return;
      }

      dropdown?.classList.remove(dropdownErrorClassname);
      input?.classList.remove(textInputErrorClassname);

      errorContainerValidationErrorClassnames.forEach((classname) =>
        targetErrorContainer?.classList.remove(classname)
      );
    };

    const disconnects: DisconnectFunction[] = [];

    errorContainerSelectors.forEach((selector) => {
      const { disconnect } = {
        ...useMutationObserver({
          querySelector: selector,
          callback,
        }),
      };

      disconnects.push(disconnect);
    });

    return () => {
      disconnects?.forEach((disconnect) =>
        typeof disconnect === 'function' ? disconnect() : undefined
      );
    };
  }, [b2cContainer, hasMfaSetupStep]);

  // Add mutation observer to show/hide signin link
  useEffect(() => {
    const sendVerificationButtonSelector =
      '#registerEmailVerificationControl_but_send_code';

    const emailVerificationSuccessMessageSelector =
      '#registerEmailVerificationControl_success_message';

    const signInLinkContainerSelector = '.buttons .tfl-signin-div';

    const sendVerificationButton = b2cContainer?.querySelector(
      sendVerificationButtonSelector
    );

    const emailVerificationSuccessMessage = b2cContainer?.querySelector(
      emailVerificationSuccessMessageSelector
    ) as HTMLElement;

    const signInLinkContainer = b2cContainer?.querySelector(
      signInLinkContainerSelector
    ) as HTMLElement;

    if (
      !signInLinkContainer ||
      !sendVerificationButton ||
      !emailVerificationSuccessMessage
    )
      return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const sendVerificationButton = target as Element;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const visible =
        sendVerificationButton?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false' ||
        emailVerificationSuccessMessage?.style.display === 'none';

      // update input styles
      signInLinkContainer.classList.add(visible ? 'show' : 'hide');
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: sendVerificationButtonSelector,
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, []);

  // add loader to email adress input field after user clicks 'send verification code'
  useEffect(() => {
    if (!b2cContainer) return;

    const sendVerificationButtonSelector =
      '#registerEmailVerificationControl_but_send_code';
    const verificationCodeInput = b2cContainer?.querySelector(
      '#emailVerificationCode'
    );
    const verificationCodeInputContainer =
      b2cContainer?.querySelector('.VerificationCode');
    const continueButton = b2cContainer?.querySelector('#continue');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const sendVerificationButton = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const sendVerificationButtonHidden =
        sendVerificationButton?.attributes?.getNamedItem('aria-hidden')
          ?.nodeValue === 'true';
      const claimVerificationServerError = b2cContainer.querySelector(
        '#claimVerificationServerError'
      );

      const claimVerificationServerErrorThrown =
        claimVerificationServerError?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const codeInputVisible =
        verificationCodeInputContainer?.attributes?.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const continueButtonVisible =
        continueButton?.attributes?.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (!sendVerificationButtonHidden) return;

      if (
        continueButtonVisible ||
        codeInputVisible ||
        claimVerificationServerErrorThrown
      ) {
        verificationCodeInput?.classList.remove(
          'tfl-text-input__input--loading'
        );
        return;
      }

      verificationCodeInput?.classList.add('tfl-text-input__input--loading');
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: sendVerificationButtonSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // remove loader from email adress input field when error summary is displayed
  useEffect(() => {
    if (!b2cContainer) return;

    const alertSummarySelector = '#requiredFieldMissing';
    const emailAddressInput = b2cContainer?.querySelector('#signInName');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const alertSummary = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const errorSummaryVisible =
        alertSummary?.attributes?.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (!alertSummary) return;

      if (errorSummaryVisible) {
        emailAddressInput?.classList.remove('tfl-text-input__input--loading');
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: alertSummarySelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, []);

  // Add mutation observer to show continue button & code input box & disable email input loader
  useEffect(() => {
    const otpCodeSuccessMessageContainerSelector =
      '#registerEmailVerificationControl_success_message';
    const verifyCodeButtonSelector =
      '#registerEmailVerificationControl_but_verify_code';

    const verifyCodeButton = b2cContainer?.querySelector(
      verifyCodeButtonSelector
    );

    const mfaVerificationCodeContainer = b2cContainer?.querySelector(
      '.TextBox.VerificationCode'
    ) as HTMLElement;

    if (!mfaVerificationCodeContainer || !verifyCodeButton) return;

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otpCodeSuccessMessageContainerSelector,
        callback: (mutation: MutationRecord) =>
          callback(mutation, '#emailVerificationCode'),
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, []);

  // Take user back to landing page when try to edit email address
  useEffect(() => {
    if (!b2cContainer) return;

    const emailAddressInput = b2cContainer.querySelector('#signInName');
    const continueButton = b2cContainer?.querySelector(
      '#continue'
    ) as HTMLElement;
    const verifyCodeButton = b2cContainer?.querySelector(
      '#registerEmailVerificationControl_but_verify_code'
    ) as HTMLElement;
    const resendCodeButton = b2cContainer?.querySelector(
      '#registerEmailVerificationControl_but_send_new_code'
    ) as HTMLElement;
    const sendVerificationCodeButton = b2cContainer.querySelector(
      '#registerEmailVerificationControl_but_send_code'
    ) as HTMLElement;
    const verficationCodeInputContainer = b2cContainer?.querySelector(
      '.TextBox.VerificationCode'
    ) as HTMLElement;
    const verificationCodeInput = b2cContainer?.querySelector(
      '#emailVerificationCode'
    ) as HTMLElement;

    const handler = (event: Event) => {
      const emailAddressInput = event.target as HTMLElement;
      if (!emailAddressInput) return;

      const userIsAlreadyOnLandingPage =
        window.getComputedStyle(sendVerificationCodeButton, null)?.display ===
        'block';

      if (userIsAlreadyOnLandingPage) return;

      sendVerificationCodeButton.style.display = 'block';
      sendVerificationCodeButton.setAttribute('aria-hidden', 'false');

      verificationCodeInput.removeAttribute('disabled');
      verificationCodeInput.textContent = '';
      verificationCodeInput.classList.remove(
        'tfl-text-input__input--success',
        'tfl-text-input__input--loading'
      );

      [
        verficationCodeInputContainer,
        verifyCodeButton,
        resendCodeButton,
        continueButton,
      ].forEach((element) => {
        element.style.display = 'none';
        element.setAttribute('aria-hidden', 'true');
      });
    };

    emailAddressInput?.addEventListener('input', handler);

    return () => emailAddressInput?.removeEventListener('input', handler);
  }, [b2cContainer]);

  // Remove change button (This button can't be removed from the policy)
  useEffect(() => {
    if (!b2cContainer) return;
    const changeEmailAddressButton = document.querySelector(
      '#registerEmailVerificationControl_but_change_claims'
    );
    changeEmailAddressButton?.remove();
  }, [b2cContainer]);

  // Leverage out the box loading indicator ui element provided by the policy
  useEffect(() => {
    if (!b2cContainer) return;

    const loadingContainerSelector = '.working';
    const verificationCodeInput = b2cContainer.querySelector(
      '#emailVerificationCode'
    );

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const loadingContainer = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const busy =
        loadingContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (!busy) {
        verificationCodeInput?.classList.remove(
          'tfl-text-input__input--loading'
        );
        return;
      }

      verificationCodeInput?.classList.add('tfl-text-input__input--loading');
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: loadingContainerSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Copy other email/verify code error message to inline error container
  useEffect(() => {
    if (!b2cContainer) return;

    const otherErrorMessageContainerSelector =
      '#registerEmailVerificationControl_error_message';
    const otherErrorMessageContainer = b2cContainer.querySelector(
      otherErrorMessageContainerSelector
    );

    if (!otherErrorMessageContainer) return;

    // We can't remove it from DOM so just hide it
    otherErrorMessageContainer?.classList.add('visuallyhidden');

    const emailContainer = b2cContainer.querySelector('.TextBox.signInName');
    const emailInlineErrorContainer =
      emailContainer?.querySelector('.error.itemLevel');

    const verificationCodeContainer = b2cContainer.querySelector(
      '.TextBox.VerificationCode'
    );
    const verificationCodeInlineErrorContainer =
      verificationCodeContainer?.querySelector('.error.itemLevel');

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const otherErrorMessageContainer = target as HTMLElement;

      if (attributeName !== 'aria-hidden') return;

      const otherErrorMessageContainerShowing =
        otherErrorMessageContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      const textContent = otherErrorMessageContainer?.textContent;

      const otherErrorMessageContainerHasTextContent = !!textContent?.length;

      if (
        !otherErrorMessageContainerShowing ||
        !otherErrorMessageContainerHasTextContent
      )
        return;

      const verificationCodeContainerShowing =
        verificationCodeContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      if (
        verificationCodeContainerShowing &&
        verificationCodeInlineErrorContainer
      ) {
        verificationCodeInlineErrorContainer.textContent = textContent;
        verificationCodeInlineErrorContainer.setAttribute(
          'aria-hidden',
          'false'
        );
        return;
      }

      const emailContainerShowing =
        emailContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (emailContainerShowing && emailInlineErrorContainer) {
        emailInlineErrorContainer.textContent = textContent;
        emailInlineErrorContainer.setAttribute('aria-hidden', 'false');
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: otherErrorMessageContainerSelector,
        config: {
          attributes: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  // Add mutation observer for all inputs/dropdown error states
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainersSelector = '.attrEntry .error.itemLevel';
    const errorContainers = Array.from(
      b2cContainer?.querySelectorAll(errorContainersSelector)
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';

    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (
      !errorContainers.length ||
      hasMfaSetupStep ||
      hasMfaChallengeStep ||
      hasAddressStep
    )
      return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target, type } = mutation;
      const errorContainer = target as Element;
      const parentElement = errorContainer?.parentElement;
      const element =
        parentElement?.querySelector('input') ||
        parentElement?.querySelector('select');

      if (!element) return;

      const errorContainerHasTextContent =
        !!errorContainer?.textContent?.length;
      const showingError =
        errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (type === 'childList' && !errorContainerHasTextContent) {
        element?.classList.remove(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.remove(classname)
        );
        return;
      }

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      // remove default error class or ovveride style in css
      element?.classList.remove('invalid');

      element?.classList.remove(textInputErrorClassname);
      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainer?.classList.remove(classname)
      );

      if (showingError && errorContainerHasTextContent) {
        element?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.add(classname)
        );
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: errorContainersSelector,
        config: {
          childList: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer, hasMfaSetupStep]);

  useAddPasswordSecuritySpan();

  // Validate password on input and toggle between ✅,❌ & 🔘 icons
  useEffect(() => {
    const passwordInputSelector = '#newPassword';
    const passwordInput = b2cContainer?.querySelector(
      passwordInputSelector
    ) as HTMLInputElement;

    if (!passwordInput) return;

    const { validate } = usePassword();

    const handler = ({ target }: Event) => validate(target as HTMLInputElement);

    passwordInput?.addEventListener('input', handler);

    return () => passwordInput?.removeEventListener('input', handler);
  }, [b2cContainer]);

  // Toggle show/hide password
  useEffect(() => {
    const passwordInputSelector = '#newPassword';
    const passwordInput = b2cContainer?.querySelector(
      passwordInputSelector
    ) as HTMLInputElement;

    if (!passwordInput) return;

    const toggleDiv = document.createElement('DIV');
    toggleDiv.classList.add('tfl-password-toggle');

    const toggleSpan = document.createElement('SPAN');

    //Add 2 hidden span for accessibility
    const hiddenClickSpan = document.createElement('SPAN');
    hiddenClickSpan.classList.add('hidden-clip');
    hiddenClickSpan.textContent = 'click to';
    const hiddenPasswordSpan = document.createElement('SPAN');
    hiddenPasswordSpan.classList.add('hidden-clip');
    hiddenPasswordSpan.textContent = 'the password';

    toggleSpan.textContent =
      passwordInput.getAttribute('type') === 'password' ? 'Show' : 'Hide';

    // A11y attributes
    toggleDiv.setAttribute('aria-live', 'assertive');
    toggleDiv.setAttribute('aria-atomic', 'true');
    toggleDiv.setAttribute('aria-relevant', 'all');
    toggleDiv.setAttribute('tabindex', '0');

    passwordInput.classList.add('tfl-text-input__input--hide');
    passwordInput.parentNode?.append(toggleDiv);

    toggleDiv.append(toggleSpan);

    toggleSpan.parentNode?.prepend(hiddenClickSpan);
    toggleSpan.parentNode?.append(hiddenPasswordSpan);

    const togglePasswordHandler = () => {
      const type =
        passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
      passwordInput.setAttribute('type', type);
      toggleSpan.innerHTML === 'Show'
        ? (toggleSpan.innerHTML = 'Hide')
        : (toggleSpan.innerHTML = 'Show');
    };
    toggleDiv.addEventListener('click', togglePasswordHandler);

    // A11y toggleDiv
    const keyAccessibilityHandler = (e: { key: string }) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        toggleSpan.click();
      }
    };
    toggleDiv.addEventListener('keydown', keyAccessibilityHandler);

    return () => {
      toggleDiv.removeEventListener('click', togglePasswordHandler);
      toggleDiv.removeEventListener('keydown', keyAccessibilityHandler);
    };
  }, [b2cContainer]);

  // Set default icons class from 🔘 to ❌
  useEffect(() => {
    const continueButtonSelector = '#continue';
    const passwordInputSelector = '#newPassword';
    const continueButton = b2cContainer?.querySelector(
      continueButtonSelector
    ) as HTMLInputElement;
    const passwordInput = b2cContainer?.querySelector(
      passwordInputSelector
    ) as HTMLInputElement;

    if (!continueButton || !passwordInput) return;

    const handler = () => {
      const event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      passwordInput.setAttribute(
        'data-default-icon-class',
        'tfl-password-match-icon--failure'
      );
      passwordInput.dispatchEvent(event);
    };

    continueButton?.addEventListener('click', handler);

    return () => continueButton?.removeEventListener('click', handler);
  }, [b2cContainer]);

  // Set focus to error summary on form submission failure
  useFocusErrorSummaryScrollToTarget({
    buttonSelectors: [
      '#registerEmailVerificationControl_but_verify_code',
      '#registerEmailVerificationControl_but_send_code',
    ],
  });

  // Remove sign in link on mfa step
  useEffect(() => {
    if (!hasMfaSetupStep) return;

    b2cContainer?.querySelector('.tfl-signin-div')?.remove();
  }, [b2cContainer, hasMfaSetupStep]);

  // Move b2c container inside react container
  useEffect(() => {
    if (!b2cContainer) return;

    const container = document.getElementById('b2c-react-container');
    container?.appendChild(b2cContainer);

    b2cContainer.classList.remove('visuallyhidden');
  }, [b2cContainer]);

  useUpdatePageTitle('.heading h1');

  return (
    <>
      {hasAddressStep ? <Address /> : null}
      {hasPasscodeStep ? <Security /> : null}
      {hasOneQuickThingStep ? <OneQuickThing /> : null}
      {hasMfaSetupStep ? <MfaSetup /> : null}
      {hasMfaChallengeStep ? <MfaChallenge /> : null}
    </>
  );

  return <></>;
};

export default Signup;
