import { useEffect } from 'react';
import { useB2cContainer } from '.';

const useIntroText = (
  headingTextSourceSelector = '.Paragraph .attrEntry .textInParagraph'
) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const form = b2cContainer?.querySelector('#attributeVerification');

    if (!form) return;

    const headingTextSourceElement = b2cContainer?.querySelector(
      headingTextSourceSelector
    ) as HTMLElement;

    if (!headingTextSourceElement) return;

    const headingElement = b2cContainer.querySelector('.heading h1');

    if (!headingElement) return;

    headingElement.classList.add('tfl-page-heading');

    const textIntro = headingTextSourceElement.textContent ?? '';
    headingElement.textContent = textIntro;
    headingElement.setAttribute('data-original-text', textIntro);
    // the grandparent <li> has a margin bottom that needs to be removed
    // as well as the original text
    headingTextSourceElement.parentElement?.parentElement?.remove();
  }, [b2cContainer]);
};

export default useIntroText;
