import { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useQuery } from '../hooks';
import { AddressProvider } from './addressContext';
import { CustomClaims, CustomClaimsContext } from './CustomClaimsContext';

export const AppProviders = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [customClaims, setCustomClaims] = useState<CustomClaims>({});

  const query = useQuery();
  const idTokenHint = query.get('id_token_hint') as string;

  useEffect(() => {
    try {
      const decoded = jwt_decode(idTokenHint);
      setCustomClaims(decoded as CustomClaims);
    } catch (error) {
      const { message } = error as Error;
      console.warn(`❌ Could not decode id token => `, message);
    }
  }, []);

  return (
    <>
      <CustomClaimsContext.Provider value={{ customClaims, setCustomClaims }}>
        <AddressProvider>{children}</AddressProvider>
      </CustomClaimsContext.Provider>
    </>
  );
};
