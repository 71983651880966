import { useEffect } from 'react';
import { useB2cContainer, useMutationObserver } from '.';
import { DisconnectFunction } from './useMutationObserver';

const useAddAlertSummaryMutationObserver = () => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const errorSummaryContainer = b2cContainer?.querySelector(
      '#requiredFieldMissing'
    ) as HTMLElement;
    if (!errorSummaryContainer) return;

    const inputs = Array.from(
      b2cContainer?.querySelectorAll('input')
    ) as HTMLElement[];

    const dropdowns = Array.from(
      b2cContainer?.querySelectorAll('select')
    ) as HTMLElement[];

    const inputErrorContainerSelectors = inputs.map(
      (input) => `.error.itemLevel.${input.id}`
    );

    const dropdownErrorContainerSelectors = dropdowns.map(
      (dropdown) => `.error.itemLevel.${dropdown?.id}`
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';
    const dropdownErrorClassname = 'tfl-dropdown__container--error';
    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    const errorContainerSelectors = [
      ...inputErrorContainerSelectors,
      ...dropdownErrorContainerSelectors,
    ];

    if (!errorContainerSelectors.length) return;

    const inputErrorContainers = errorContainerSelectors.map((selector) =>
      b2cContainer.querySelector(selector)
    );

    if (!inputErrorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const targetErrorContainer = target as Element;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      const hasInlineErrorsOnThePage =
        inputErrorContainers.find(
          (container) =>
            container?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
            'false'
        ) !== undefined;

      errorSummaryContainer.setAttribute(
        'aria-hidden',
        hasInlineErrorsOnThePage ? 'false' : 'true'
      );
      errorSummaryContainer.style.display = hasInlineErrorsOnThePage
        ? 'block'
        : 'none';

      const targetSummaryItemLinkSelector =
        targetErrorContainer.attributes.getNamedItem(
          'data-summary-link'
        )?.nodeValue;

      if (!targetSummaryItemLinkSelector) return;

      let targetSummaryItemLink: HTMLElement | null | undefined = undefined;

      targetSummaryItemLink = b2cContainer?.querySelector(
        targetSummaryItemLinkSelector
      ) as HTMLElement;

      if (!targetSummaryItemLink) return;

      const dropdown =
        targetErrorContainer?.parentElement?.querySelector('select');
      const input = targetErrorContainer?.parentElement?.querySelector('input');

      const errorContainerShowingError =
        targetErrorContainer?.attributes.getNamedItem('aria-hidden')
          ?.nodeValue === 'false';

      errorContainerShowingError
        ? (targetSummaryItemLink.classList.add('show'),
          targetSummaryItemLink.classList.remove('hide'))
        : (targetSummaryItemLink.classList.add('hide'),
          targetSummaryItemLink.classList.remove('show'));

      targetSummaryItemLink.setAttribute(
        'aria-hidden',
        errorContainerShowingError ? 'false' : 'true'
      );

      const skipLink = targetSummaryItemLink.querySelector('a');
      if (skipLink) {
        skipLink.textContent = errorContainerShowingError
          ? targetErrorContainer?.textContent
          : '';
      }

      if (errorContainerShowingError) {
        dropdown?.classList.add(dropdownErrorClassname);
        input?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          targetErrorContainer?.classList.add(classname)
        );
        return;
      }

      dropdown?.classList.remove(dropdownErrorClassname);
      input?.classList.remove(textInputErrorClassname);

      errorContainerValidationErrorClassnames.forEach((classname) =>
        targetErrorContainer?.classList.remove(classname)
      );
    };

    const disconnects: DisconnectFunction[] = [];

    errorContainerSelectors.forEach((selector) => {
      const { disconnect } = {
        ...useMutationObserver({
          querySelector: selector,
          callback,
        }),
      };

      disconnects.push(disconnect);
    });

    return () => {
      disconnects?.forEach((disconnect) =>
        typeof disconnect === 'function' ? disconnect() : undefined
      );
    };
  }, [b2cContainer]);
};

export default useAddAlertSummaryMutationObserver;
