import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

const useHtml5ValidationRemover = () => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    // Remove tooltip/validation from input fields
    const form = b2cContainer.querySelector('form');
    form?.setAttribute('novalidate', 'novalidate');
  }, [b2cContainer]);
};

export default useHtml5ValidationRemover;
