import { useB2cContainer } from '.';

const useHasMfaChallengeStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasMfaChallenge =
    b2cContainer?.querySelector('#mfaChallengeVerificationControl') !== null;

  return [hasMfaChallenge];
};

export default useHasMfaChallengeStep;
