import { useEffect } from 'react';
import { useB2cContainer, useMutationObserver } from '.';
import { typedBoolean } from '../types/tfl-types';
import { DisconnectFunction } from './useMutationObserver';

interface WatchStyleDisplayProps {
  selectors: string[];
}

const useWatchStyleDisplayProperty = ({
  selectors,
}: WatchStyleDisplayProps) => {
  const [b2cContainer] = useB2cContainer();

  // Undo `display: inline` property
  useEffect(() => {
    if (!b2cContainer) return;

    if (!selectors?.length) return;

    const contianers = selectors.map((selector) =>
      b2cContainer.querySelector(selector)
    );

    if (!contianers) return;

    const isDisplayInline = (value: string) =>
      ['display: inline', 'display:inline'].some((pattern) =>
        new RegExp(pattern, 'gi').test(value)
      );

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target } = mutation;
      const targetElement = target as HTMLElement;

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'style') return;

      const attributeValues = targetElement
        .getAttribute('style')
        ?.split(';')
        .filter(typedBoolean);

      if (!attributeValues) return;

      if (attributeValues.find(isDisplayInline)) {
        const filteredAttributesValues = attributeValues?.filter(
          (value) => !isDisplayInline(value)
        );

        targetElement.setAttribute('style', filteredAttributesValues.join(';'));

        targetElement.classList.contains('visuallyhidden')
          ? (targetElement.style.display = 'none')
          : null;
      }
    };

    const disconnects = selectors.map((querySelector): DisconnectFunction => {
      const { disconnect } = {
        ...useMutationObserver({ querySelector, callback }),
      };
      return disconnect;
    });

    return () =>
      disconnects?.forEach((disconnect) =>
        typeof disconnect === 'function' ? disconnect() : undefined
      );
  }, [b2cContainer]);
};

export default useWatchStyleDisplayProperty;
