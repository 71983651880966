import { useEffect } from 'react';
import {
  useAddPasscodeSecuritySpan,
  useB2cContainer,
  usePasscode,
} from '../../hooks';
import './PasscodeValidation.scss';

const PasscodeValidation = () => {
  const [b2cContainer] = useB2cContainer();

  // Create passcode validation markup below passcode input
  useEffect(() => {
    if (!b2cContainer) return;

    const passcodeInput = b2cContainer?.querySelector(
      '#securityPasscode_Answer'
    );
    if (!passcodeInput) return;

    passcodeInput.classList.add(
      'has-password-toggle--with-validation-indicator'
    );

    const passcodeContainer = passcodeInput.closest(
      'li.TextBox.securityPasscode_Answer_li'
    );
    if (!passcodeContainer) return;

    const passcodeMatchContainer = b2cContainer?.querySelector(
      '.tfl-passcode-match'
    );

    if (passcodeMatchContainer) return;

    const passcodeMatchItems = [
      {
        id: 'tfl-passcode-length',
        className: 'tfl-passcode-length',
        text: 'Six numbers long',
      },
      {
        id: 'tfl-passcode-number-repetition',
        className: 'tfl-passcode-number-repetition',
        text: 'No numbers three times in a row (for example 555)',
      },
      {
        id: 'tfl-passcode-no-consecutive-number',
        className: 'tfl-passcode-no-consecutive-number',
        text: `No consecutive numbers (for example 123)`,
      },
    ];

    const passcodeMatch = document.createElement('li');
    passcodeMatch.classList.add('tfl-passcode-match');

    const a11ySpan = document.createElement('span');
    const a11ySpanId = 'tfl-passcode-a11y';
    a11ySpan.id = a11ySpanId;
    a11ySpan.classList.add(a11ySpanId, 'visuallyhidden');

    passcodeMatch.appendChild(a11ySpan);

    passcodeMatchItems.forEach(({ id, className, text }) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('tfl-passcode-match--wrapper');

      const icon = document.createElement('div');
      icon.classList.add(
        className,
        'tfl-passcode-match-icon',
        'tfl-passcode-match-icon--default'
      );

      const description = document.createElement('div');
      description.id = id;
      description.classList.add('tfl-passcode-match--description');
      description.textContent = text;

      wrapper.appendChild(icon);
      wrapper.appendChild(description);

      passcodeMatch.appendChild(wrapper);
    });

    passcodeContainer.after(passcodeMatch);
  }, [b2cContainer]);

  useAddPasscodeSecuritySpan();

  // Validate passcode on input and toggle between ✅,❌ & 🔘 icons
  useEffect(() => {
    const passcodeInputSelector = '#securityPasscode_Answer';
    const passcodeInput = b2cContainer?.querySelector(
      passcodeInputSelector
    ) as HTMLInputElement;

    if (!passcodeInput) return;

    const { validate } = usePasscode();

    const handler = ({ target }: Event) => validate(target as HTMLInputElement);

    passcodeInput?.addEventListener('input', handler);

    return () => passcodeInput?.removeEventListener('input', handler);
  }, [b2cContainer]);

  // Toggle show/hide passcode
  useEffect(() => {
    const passcodeInputSelector = '#securityPasscode_Answer';
    const passcodeInput = b2cContainer?.querySelector(
      passcodeInputSelector
    ) as HTMLInputElement;

    if (!passcodeInput) return;

    passcodeInput.setAttribute('type', 'password');
    passcodeInput.classList.add('has-password-toggle');

    const toggleDiv = document.createElement('DIV');
    toggleDiv.classList.add('tfl-passcode-toggle');

    const toggleSpan = document.createElement('SPAN');

    //Add 2 hidden span for accessibility
    const hiddenClickSpan = document.createElement('SPAN');
    hiddenClickSpan.classList.add('hidden-clip');
    hiddenClickSpan.textContent = 'click to';
    const hiddenPasscodeSpan = document.createElement('SPAN');
    hiddenPasscodeSpan.classList.add('hidden-clip');
    hiddenPasscodeSpan.textContent = 'the passcode';

    toggleSpan.textContent =
      passcodeInput.getAttribute('type') === 'password' ? 'Show' : 'Hide';

    // A11y attributes
    toggleDiv.setAttribute('aria-live', 'assertive');
    toggleDiv.setAttribute('aria-atomic', 'true');
    toggleDiv.setAttribute('aria-relevant', 'all');
    toggleDiv.setAttribute('tabindex', '0');

    passcodeInput.classList.add('tfl-text-input__input--hide');
    passcodeInput.parentNode?.append(toggleDiv);

    toggleDiv.append(toggleSpan);

    toggleSpan.parentNode?.prepend(hiddenClickSpan);
    toggleSpan.parentNode?.append(hiddenPasscodeSpan);

    const togglePasscodeHandler = () => {
      const type =
        passcodeInput.getAttribute('type') === 'password' ? 'text' : 'password';
      passcodeInput.setAttribute('type', type);
      toggleSpan.innerHTML === 'Show'
        ? (toggleSpan.innerHTML = 'Hide')
        : (toggleSpan.innerHTML = 'Show');
    };
    toggleDiv.addEventListener('click', togglePasscodeHandler);

    // A11y toggleDiv
    const keyAccessibilityHandler = (e: { key: string }) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        toggleSpan.click();
      }
    };
    toggleDiv.addEventListener('keydown', keyAccessibilityHandler);

    return () => {
      toggleDiv.removeEventListener('click', togglePasscodeHandler);
      toggleDiv.removeEventListener('keydown', keyAccessibilityHandler);
    };
  }, [b2cContainer]);

  // Set default icons class from 🔘 to ❌
  useEffect(() => {
    const continueButtonSelector = '#continue';
    const passcodeInputSelector = '#securityPasscode_Answer';
    const continueButton = b2cContainer?.querySelector(
      continueButtonSelector
    ) as HTMLInputElement;
    const passcodeInput = b2cContainer?.querySelector(
      passcodeInputSelector
    ) as HTMLInputElement;

    if (!continueButton || !passcodeInput) return;

    const handler = () => {
      const event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      passcodeInput.setAttribute(
        'data-default-icon-class',
        'tfl-passcode-match-icon--failure'
      );
      passcodeInput.dispatchEvent(event);
    };

    continueButton?.addEventListener('click', handler);

    return () => continueButton?.removeEventListener('click', handler);
  }, [b2cContainer]);

  return <></>;
};

export default PasscodeValidation;
