import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

const useHelpLinkRemover = () => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    b2cContainer
      .querySelectorAll('.helpLink.tiny')
      .forEach((helpText) => helpText.remove());
  }, [b2cContainer]);
};
export default useHelpLinkRemover;
