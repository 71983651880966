import { useEffect } from 'react';
import {
  useB2cContainer,
  useButton,
  useEmptyParagraphRemover,
  useHasMfaChallengeStep,
  useHasMfaSetupStep,
  useHasOneQuickThingStep,
  useHasSignupStep,
  useHtml5ValidationRemover,
  useLegacyRegisterFogotPasswordLinks,
  useUpdatePageTitle,
} from '../../hooks';
import { MfaSetup, MfaChallenge, Signup } from '..';
import './Signin.scss';
import { OneQuickThing } from '../OneQuickThing';

const Signin = () => {
  const [b2cContainer] = useB2cContainer();
  const { hasMfaSetupStep } = useHasMfaSetupStep();
  const [hasSignupStep] = useHasSignupStep();
  const [hasOneQuickThingStep] = useHasOneQuickThingStep();
  const [hasMfaChallengeStep] = useHasMfaChallengeStep();

  useHtml5ValidationRemover();

  useLegacyRegisterFogotPasswordLinks();

  useEmptyParagraphRemover();

  // Add intro text styles
  useEffect(() => {
    if (!b2cContainer) return;

    const headingElement = b2cContainer.querySelector('.heading h1');

    if (hasMfaChallengeStep || hasOneQuickThingStep) return;

    const title = b2cContainer.querySelector('.intro h2');

    if (!title || !headingElement) return;

    headingElement.textContent = title.textContent;
    title.remove();
  }, [b2cContainer, hasMfaChallengeStep]);

  // Add Alert Summary
  useEffect(() => {
    if (!b2cContainer) return;

    if (document.getElementById('tfl-alert-heading')) return;

    const alertHeaderH2 = document.createElement('H2');
    alertHeaderH2.classList.add(
      'tfl-alert__header',
      'tfl-alert__header--with-description'
    );
    alertHeaderH2.textContent = 'Incorrect details';
    alertHeaderH2.id = 'tfl-alert-heading';

    const errorSummaryContainer =
      b2cContainer?.querySelector('.error.pageLevel');
    if (!errorSummaryContainer) return;

    errorSummaryContainer.prepend(alertHeaderH2);
    errorSummaryContainer?.classList.add('tfl-alert', 'tfl-alert--error');
    errorSummaryContainer.setAttribute('aria-labelledby', 'tfl-alert-heading');

    const children = Array.from(errorSummaryContainer.children);
    const pTag = children.find((c) => c.nodeName === 'P');
    pTag?.classList.add('tfl-alert__content');
  }, [b2cContainer]);

  // Add tfl branded styles to input boxes
  useEffect(() => {
    if (!b2cContainer) return;
    const inputContainers = Array.from(
      b2cContainer?.querySelectorAll('.entry .entry-item')
    );
    inputContainers.forEach((c) => c.classList.add('tfl-text-input'));
    inputContainers
      .map((c) => Array.from(c.children))
      .flat()
      .forEach((el) => {
        if (el.nodeName === 'LABEL') el.classList.add('tfl-text-input__label');
        if (el.nodeName === 'INPUT') el.classList.add('tfl-text-input__input');
      });
  }, [b2cContainer]);

  // Move error container below input
  useEffect(() => {
    if (!b2cContainer) return;
    const errorContainers = Array.from(
      b2cContainer.querySelectorAll('.entry-item .error')
    );
    errorContainers?.forEach((c) => {
      c.classList.add('tfl-validation-error', 'tfl-validation-error--attached');
      c.parentNode?.appendChild(c);
    });
  }, [b2cContainer]);

  // Toggle show/hide password
  useEffect(() => {
    const passwordInputSelector = '#password';
    const passwordInput = b2cContainer?.querySelector(
      passwordInputSelector
    ) as HTMLInputElement;

    if (!passwordInput) return;

    passwordInput.classList.add('has-password-toggle');

    const toggleDiv = document.createElement('DIV');
    toggleDiv.classList.add('tfl-signin-password-toggle');

    const toggleSpan = document.createElement('SPAN');

    //Add 2 hidden span for accessibility
    const hiddenClickSpan = document.createElement('SPAN');
    hiddenClickSpan.classList.add('hidden-clip');
    hiddenClickSpan.textContent = 'click to';
    const hiddenPasswordSpan = document.createElement('SPAN');
    hiddenPasswordSpan.classList.add('hidden-clip');
    hiddenPasswordSpan.textContent = 'the password';

    toggleSpan.textContent =
      passwordInput.getAttribute('type') === 'password' ? 'Show' : 'Hide';

    // A11y attributes
    toggleDiv.setAttribute('aria-live', 'assertive');
    toggleDiv.setAttribute('aria-atomic', 'true');
    toggleDiv.setAttribute('aria-relevant', 'all');
    toggleDiv.setAttribute('tabindex', '0');

    passwordInput.parentNode?.append(toggleDiv);

    toggleDiv.append(toggleSpan);

    toggleSpan.parentNode?.prepend(hiddenClickSpan);
    toggleSpan.parentNode?.append(hiddenPasswordSpan);

    const togglePasscodeHandler = () => {
      const type =
        passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
      passwordInput.setAttribute('type', type);
      toggleSpan.innerHTML === 'Show'
        ? (toggleSpan.innerHTML = 'Hide')
        : (toggleSpan.innerHTML = 'Show');
    };
    toggleDiv.addEventListener('click', togglePasscodeHandler);

    // A11y toggleDiv
    const keyAccessibilityHandler = (e: { key: string }) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        toggleSpan.click();
      }
    };
    toggleDiv.addEventListener('keydown', keyAccessibilityHandler);

    return () => {
      toggleDiv.removeEventListener('click', togglePasscodeHandler);
      toggleDiv.removeEventListener('keydown', keyAccessibilityHandler);
    };
  }, [b2cContainer]);

  // Add Style to Forgot password link, move question mark outside the anchor tag
  useEffect(() => {
    if (!b2cContainer) return;

    const forgotPasswordAnchor = b2cContainer.querySelector('#forgotPassword');
    if (!forgotPasswordAnchor) return;

    const passwordInput = b2cContainer.querySelector('#password');
    if (!passwordInput) return;

    const forgotPasswordQuestionMarkSpan = b2cContainer.querySelector(
      '.forgot-password-question-mark'
    );
    if (forgotPasswordQuestionMarkSpan) return;

    const forgotPasswordSpan = document.createElement('SPAN');
    forgotPasswordSpan.id = 'forgotPasswordSpan';
    forgotPasswordSpan.setAttribute('aria-label', 'Forgot password');

    passwordInput.parentNode?.append(forgotPasswordSpan);
    forgotPasswordSpan.prepend(forgotPasswordAnchor);

    forgotPasswordAnchor.textContent = 'Forgot password';
    forgotPasswordAnchor.outerHTML += `<span class="forgot-password-question-mark">?</span>`;
    forgotPasswordAnchor.setAttribute('tabindex', '0');
  }, [b2cContainer]);

  // Primary button
  useButton({
    selector: 'next',
    type: 'button',
    variant: 'primary',
  });

  // Add create account button link and move it next to sign in button
  useEffect(() => {
    if (!b2cContainer) return;

    const buttonsContainer = b2cContainer?.querySelector('.buttons');
    if (!buttonsContainer) return;

    const createAccountLink = b2cContainer?.querySelector('#createAccount');
    if (!createAccountLink) return;

    const form = b2cContainer?.querySelector('.localAccount');
    if (!form) return;

    createAccountLink?.classList.add(
      'tfl-link-button',
      'tfl-link-button--secondary'
    );

    buttonsContainer.appendChild(createAccountLink);

    b2cContainer.querySelector('.create')?.remove();

    form.appendChild(buttonsContainer);
  }, [b2cContainer]);

  // Add error class to input(s) on validation failure
  useEffect(() => {
    if (!b2cContainer) return;
    const submitButton = b2cContainer.querySelector('#next');
    if (!submitButton) return;

    function handleSubmit() {
      const inlineErrorContainers = Array.from(
        document.querySelectorAll<HTMLElement>('#api .error.itemLevel')
      );

      if (!inlineErrorContainers?.length) return;

      const containersWithError = inlineErrorContainers.filter(
        (c) => window.getComputedStyle(c, null)?.display === 'block'
      );

      const containersWithoutError = inlineErrorContainers.filter(
        (c) => window.getComputedStyle(c, null)?.display !== 'block'
      );

      const textInputErrorClassname = 'tfl-text-input__input--error';

      containersWithError.forEach((c) =>
        c.parentElement
          ?.querySelector('input')
          ?.classList.add(textInputErrorClassname)
      );

      containersWithoutError.forEach((c) =>
        c.parentElement
          ?.querySelector('input')
          ?.classList.remove(textInputErrorClassname)
      );
    }

    submitButton.addEventListener('click', handleSubmit);

    return () => submitButton.removeEventListener('click', handleSubmit);
  }, [b2cContainer]);

  // Set focus to first input with error
  useEffect(() => {
    if (!b2cContainer) return;

    const button = b2cContainer.querySelector('#next');
    if (!button) return;

    const inlineInputErrorContainers = Array.from(
      b2cContainer.querySelectorAll('.error.itemLevel')
    );

    const handler = () => {
      const firstInputWithError = inlineInputErrorContainers.find(
        (container) =>
          container?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
          'false'
      ) as HTMLElement;
      if (!firstInputWithError) return;
      firstInputWithError.parentElement?.querySelector('input')?.focus();
    };

    button?.addEventListener('click', handler);

    return () => button?.removeEventListener('click', handler);
  }, [b2cContainer]);

  // Style You are nearly ready page
  useEffect(() => {
    if (!b2cContainer) return;

    const congratulationParagrahLabel = b2cContainer?.querySelector(
      '#congratulationsParagraphText_label'
    );
    congratulationParagrahLabel?.remove();
  }, [b2cContainer]);

  // Move inside react container
  useEffect(() => {
    if (!b2cContainer) return;

    const container = document.getElementById('b2c-react-container');
    if (!container) return;

    container.appendChild(b2cContainer);

    b2cContainer.classList.remove('visuallyhidden');
  }, [b2cContainer]);

  useUpdatePageTitle('.heading h1');

  return (
    <>
      {hasMfaSetupStep ? <MfaSetup /> : null}
      {hasSignupStep ? <Signup /> : null}
      {hasOneQuickThingStep ? <OneQuickThing /> : null}
      {hasMfaChallengeStep ? <MfaChallenge /> : null}
    </>
  );
};

export default Signin;
