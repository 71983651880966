import { Loader } from '@tfl/component-library';
import { usePolicy } from '../../hooks';
import { Policies } from '../policies';
import {
  MfaSetup,
  PasswordReset,
  ProfileEdit,
  Signin,
  Signup,
  PolicyNotFound,
  PostPasswordResetWorkAround,
} from '../index';

const QueryParamRouter = () => {
  const policy = usePolicy();

  if (
    policy === Policies.SignIn ||
    policy === Policies.SignInB2c ||
    policy === Policies.SignInLegacy
  )
    return <Signin />;
  if (policy === Policies.SignUp) return <Signup />;
  if (policy === Policies.PasswordReset) return <PasswordReset />;
  if (policy === Policies.PasswordResetOnSignInSignUp)
    return <PostPasswordResetWorkAround />;
  if (policy === Policies.Mfa) return <MfaSetup />;
  if (policy === Policies.ProfileEdit) return <ProfileEdit />;
  if (policy === Policies.Unknown)
    return <Loader label="Loading content please wait." />;

  return <PolicyNotFound />;
};
export default QueryParamRouter;
