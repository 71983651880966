import { useEffect } from 'react';
import { PasswordReset } from '..';
import { useB2cContainer, useUpdatePageTitle } from '../../hooks';

const PostPasswordResetWorkAround = () => {
  const [b2cContainer] = useB2cContainer();

  // Remove create account button on post reset password signin screen
  useEffect(() => {
    if (!b2cContainer) return;

    const createAccountLink = b2cContainer.querySelector('#createAccount');

    if (!createAccountLink) return;

    createAccountLink?.remove();
  }, [b2cContainer]);

  // Move inside react container
  useEffect(() => {
    if (!b2cContainer) return;

    const container = document.getElementById('b2c-react-container');
    if (!container) return;

    container.appendChild(b2cContainer);

    b2cContainer.classList.remove('visuallyhidden');
  }, [b2cContainer]);

  useUpdatePageTitle('.heading h1');

  return (
    <>
      <PasswordReset />
    </>
  );
};

export default PostPasswordResetWorkAround;
