import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

const useEmptyParagraphRemover = () => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const paragraphs = b2cContainer.querySelectorAll('p');
    paragraphs.forEach((paragraph) => {
      if (
        !paragraph.childNodes.length &&
        !paragraph.parentElement?.classList.contains('error')
      )
        paragraph.remove();
    });

    const otherParagraphs = b2cContainer.querySelectorAll(
      '#attributeList li.Paragraph'
    );
    otherParagraphs.forEach((paragraph) => {
      if (!paragraph.children.length) paragraph.remove();
    });
  }, [b2cContainer]);
};

export default useEmptyParagraphRemover;
