import { useB2cContainer } from '.';

const useLegacyRegisterFogotPasswordLinks = () => {
  const [b2cContainer] = useB2cContainer();

  const forgotPasswordLink = b2cContainer?.querySelector('#forgotPassword');
  const createAccountButton = b2cContainer?.querySelector('#createAccount');

  if (!forgotPasswordLink || !createAccountButton) return;

  if (process.env.REACT_APP_LEGACY_ENABLED === 'true') {
    forgotPasswordLink?.setAttribute(
      'href',
      `${process.env.REACT_APP_LEGACY_FORGOT_PASSWORD_URL}`
    );
    createAccountButton?.setAttribute(
      'href',
      `${process.env.REACT_APP_LEGACY_CREATE_ACCOUNT_URL}`
    );
  }
};

export default useLegacyRegisterFogotPasswordLinks;
