import { useEffect, useState } from 'react';
import { useB2cContainer } from '.';

const useHasSignInStep = () => {
  const [b2cContainer] = useB2cContainer();
  const [signInStep, setSignInStep] = useState(false);

  useEffect(() => {
    if (!b2cContainer) return;

    setSignInStep(
      () =>
        b2cContainer.querySelector('#signInName') !== null &&
        b2cContainer.querySelector('#password') !== null
    );
  }, [b2cContainer, setSignInStep]);

  return [signInStep];
};

export default useHasSignInStep;
