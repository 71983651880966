import { useB2cContainer } from '.';

const useHasOneQuickThingStep = () => {
  const [b2cContainer] = useB2cContainer();

  const hasOneQuickThingParagraph =
    b2cContainer?.querySelector('#oneQuickThingFirstParagraphText') !== null;

  return [hasOneQuickThingParagraph];
};

export default useHasOneQuickThingStep;
