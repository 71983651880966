import { useEffect } from 'react';
import useB2cContainer from './useB2cContainer';

export interface ButtonProps {
  selector: string;
  type: 'button' | 'linkbutton';
  variant: 'primary' | 'secondary';
}

const useButton = ({
  selector,
  type,
  variant,
}: ButtonProps): HTMLElement | null | undefined => {
  const [b2cContainer] = useB2cContainer();
  const button =
    document.getElementById(selector) || document.querySelector(selector);

  if (!button) {
    console.warn(
      `useButton ~ Could not find button with selector: ${selector}`
    );
    return;
  }

  useEffect(() => {
    if (!b2cContainer) return;

    if (type === 'linkbutton') {
      button?.classList.add(
        'tfl-link-button',
        variant === 'primary'
          ? 'tfl-link-button--primary'
          : 'tfl-link-button--secondary'
      );
      return;
    }

    button?.classList.add('tfl-button');

    if (variant === 'primary') {
      button?.classList.add('tfl-button', 'tfl-button--primary');
      return;
    }

    if (variant === 'secondary') {
      button?.classList.add('tfl-button', 'tfl-button--secondary');
      return;
    }
  }, [b2cContainer]);

  return button;
};

export default useButton;
