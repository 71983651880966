import { useEffect } from 'react';
import {
  useAddAlertSummary,
  useAddAlertSummaryMutationObserver,
  useAddPasswordSecuritySpan,
  useB2cContainer,
  useButton,
  useEmptyParagraphRemover,
  useFocusErrorSummaryScrollToTarget,
  useHtml5ValidationRemover,
  useIntroText,
  useMoveButtons,
  useMoveErrorContainerBelowInput,
  useMutationObserver,
  usePassword,
  useRemoveElement,
  useStyleInputs,
} from '../../hooks';
import { ButtonProps } from '../../hooks/useButton';

const ProfileEditPassword = () => {
  const [b2cContainer] = useB2cContainer();

  useIntroText();

  useEmptyParagraphRemover();

  useHtml5ValidationRemover();

  useAddAlertSummary();

  useMoveButtons('#api > .buttons');

  useRemoveElement({
    selectors: ['#fieldIncorrect', '#mustCompleteAllFieldsParagraphText_label'],
  });

  useStyleInputs({
    otherInputContainerSelector: '#attributeList .Password .attrEntry',
  });

  useMoveErrorContainerBelowInput({
    selectors: ['#attributeList .attrEntry .error.itemLevel'],
  });

  (
    [
      {
        selector: 'continue',
        type: 'button',
        variant: 'primary',
      },
      {
        selector: 'cancel',
        type: 'button',
        variant: 'secondary',
      },
    ] as ButtonProps[]
  ).forEach((button) => useButton(button));

  // Toggle show/hide password
  useEffect(() => {
    const passwordInputSelectors = ['#password', '#newPassword'];
    const passwordInput = b2cContainer?.querySelector(
      passwordInputSelectors[0]
    ) as HTMLInputElement;

    const passwordInputs = passwordInputSelectors.map(
      (selector) => b2cContainer?.querySelector(selector) as HTMLInputElement
    );

    if (!passwordInput) return;

    if (!passwordInputs?.length) return;

    const toggleDiv = document.createElement('DIV');
    toggleDiv.classList.add('tfl-password-toggle');

    const toggleSpan = document.createElement('SPAN');
    toggleSpan.classList.add('tfl-password-toggle-span');

    //Add 2 hidden span for accessibility
    const hiddenClickSpan = document.createElement('SPAN');
    hiddenClickSpan.classList.add('hidden-clip');
    hiddenClickSpan.textContent = 'click to';
    const hiddenPasswordSpan = document.createElement('SPAN');
    hiddenPasswordSpan.classList.add('hidden-clip');
    hiddenPasswordSpan.textContent = 'the password';

    toggleSpan.textContent =
      passwordInput.getAttribute('type') === 'password' ? 'Show' : 'Hide';

    // A11y attributes
    toggleDiv.setAttribute('aria-live', 'assertive');
    toggleDiv.setAttribute('aria-atomic', 'true');
    toggleDiv.setAttribute('aria-relevant', 'all');
    toggleDiv.setAttribute('tabindex', '0');

    toggleDiv.append(toggleSpan);

    toggleSpan.parentNode?.prepend(hiddenClickSpan);
    toggleSpan.parentNode?.append(hiddenPasswordSpan);

    const togglePasswordHandler = (event: Event) => {
      const targetContainer = event.currentTarget as HTMLDivElement;
      const showHideSpan = targetContainer.querySelector(
        '.tfl-password-toggle-span'
      ) as HTMLSpanElement;
      const targetInput = targetContainer.parentElement?.querySelector(
        'input'
      ) as HTMLInputElement;

      targetInput.setAttribute(
        'type',
        targetInput.getAttribute('type') === 'password' ? 'text' : 'password'
      );

      showHideSpan.innerHTML =
        showHideSpan.innerHTML === 'Show' ? 'Hide' : 'Show';
    };

    const keyAccessibilityHandler = (e: KeyboardEvent) => {
      if (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') {
        e.target?.dispatchEvent(new Event('click'));
      }
    };

    passwordInputs.forEach((input) =>
      input.classList.add('has-password-toggle')
    );

    passwordInputs
      .find((input) => input.id === 'password')
      ?.classList.add('has-password-toggle');

    passwordInputs
      .find((input) => input.id === 'newPassword')
      ?.classList.add(
        'tfl-text-input__input--hide',
        'has-password-toggle--with-validation-indicator'
      );

    passwordInputs.forEach((input) => {
      const clone = toggleDiv.cloneNode(true) as HTMLDivElement;
      clone.addEventListener('click', togglePasswordHandler);
      clone.addEventListener('keydown', keyAccessibilityHandler);

      input.id === 'password'
        ? clone.classList.add('tfl-password-toggle--no-hide')
        : null;
      input.parentNode?.append(clone);
    });

    return () => {
      toggleDiv.removeEventListener('click', togglePasswordHandler);
      toggleDiv.removeEventListener('keydown', keyAccessibilityHandler);
    };
  }, [b2cContainer]);

  // Create password validation markup below the input
  useEffect(() => {
    if (!b2cContainer) return;

    const passwordInput = b2cContainer?.querySelector(
      '#attributeList #newPassword'
    );

    if (!passwordInput) return;

    const passwordContainer = passwordInput?.closest('li.Password');

    if (!passwordContainer) return;

    passwordInput?.setAttribute('maxlength', '256');
    if (!passwordInput) return;

    const passwordMatchContainer = b2cContainer?.querySelector(
      '.tfl-password-match'
    );

    if (passwordMatchContainer) return;

    const passwordMatchItems = [
      {
        id: 'tfl-password-length',
        className: 'tfl-password-length',
        text: 'Minimum of eight characters',
      },
      {
        id: 'tfl-password-case',
        className: 'tfl-password-case',
        text: 'Capital and lower case letters',
      },
      {
        id: 'tfl-password-number-special-character',
        className: 'tfl-password-number-special-character',
        text: `At least one number or special character such as ?!@£$%^&*()`,
      },
    ];

    const passwordMatch = document.createElement('li');
    passwordMatch.classList.add('tfl-password-match');

    const a11ySpan = document.createElement('span');
    a11ySpan.classList.add('tfl-password-a11y', 'visuallyhidden');

    passwordMatch.appendChild(a11ySpan);

    passwordMatchItems.forEach(({ id, className, text }) => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('tfl-password-match--wrapper');

      const icon = document.createElement('div');
      icon.classList.add(
        className,
        'tfl-password-match-icon',
        'tfl-password-match-icon--default'
      );

      const a11yIds = passwordMatchItems.map((item) => item.id).join(' ');
      passwordInput.setAttribute(
        'aria-describedby',
        `securityDetails_SecondParagraphText ${a11yIds}`
      );

      const description = document.createElement('div');
      description.id = id;
      description.classList.add('tfl-password-match--description');
      description.textContent = text;
      description.setAttribute('aria-live', 'polite');

      wrapper.appendChild(icon);
      wrapper.appendChild(description);

      passwordMatch.appendChild(wrapper);
    });

    passwordContainer.after(passwordMatch);
  }, [b2cContainer]);

  // Set default icons class from 🔘 to ❌
  useEffect(() => {
    const continueButtonSelector = '#continue';
    const passwordInputSelector = '#newPassword';

    const continueButton = b2cContainer?.querySelector(
      continueButtonSelector
    ) as HTMLInputElement;

    const passwordInput = b2cContainer?.querySelector(
      passwordInputSelector
    ) as HTMLInputElement;

    if (!continueButton || !passwordInput) return;

    const handler = () => {
      const event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      passwordInput.setAttribute(
        'data-default-icon-class',
        'tfl-password-match-icon--failure'
      );
      passwordInput.dispatchEvent(event);
    };

    continueButton?.addEventListener('click', handler);

    return () => continueButton?.removeEventListener('click', handler);
  }, [b2cContainer]);

  useAddPasswordSecuritySpan();

  // Validate password on input and toggle between ✅,❌ & 🔘 icons
  useEffect(() => {
    const passwordInputSelector = '#newPassword';
    const passwordInput = b2cContainer?.querySelector(
      passwordInputSelector
    ) as HTMLInputElement;

    if (!passwordInput) return;

    const { validate } = usePassword();

    const handler = ({ target }: Event) => validate(target as HTMLInputElement);

    passwordInput?.addEventListener('input', handler);

    return () => passwordInput?.removeEventListener('input', handler);
  }, [b2cContainer]);

  useFocusErrorSummaryScrollToTarget({
    buttonSelectors: ['#continue'],
  });

  // Add mutation observers below this line
  useAddAlertSummaryMutationObserver();

  // Add mutation observer for all inputs/dropdown error states
  useEffect(() => {
    if (!b2cContainer) return;

    const errorContainersSelector = '.attrEntry .error.itemLevel';
    const errorContainers = Array.from(
      b2cContainer?.querySelectorAll(errorContainersSelector)
    );

    const textInputErrorClassname = 'tfl-text-input__input--error';

    const errorContainerValidationErrorClassnames: string[] = [
      'tfl-validation-error',
      'tfl-validation-error--attached',
    ];

    if (!errorContainers.length) return;

    const callback = (mutation: MutationRecord) => {
      const { attributeName, target, type } = mutation;
      const errorContainer = target as Element;
      const parentElement = errorContainer?.parentElement;
      const element =
        parentElement?.querySelector('input') ||
        parentElement?.querySelector('select');

      if (!element) return;

      const errorContainerHasTextContent =
        !!errorContainer?.textContent?.length;
      const showingError =
        errorContainer?.attributes.getNamedItem('aria-hidden')?.nodeValue ===
        'false';

      if (type === 'childList' && !errorContainerHasTextContent) {
        element?.classList.remove(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.remove(classname)
        );
        return;
      }

      // ignore any other attribute mutation and bail out of this side effect
      if (attributeName !== 'aria-hidden') return;

      // remove default error class or ovveride style in css
      element?.classList.remove('invalid');

      element?.classList.remove(textInputErrorClassname);
      errorContainerValidationErrorClassnames.forEach((classname) =>
        errorContainer?.classList.remove(classname)
      );

      if (showingError && errorContainerHasTextContent) {
        element?.classList.add(textInputErrorClassname);
        errorContainerValidationErrorClassnames.forEach((classname) =>
          errorContainer?.classList.add(classname)
        );
        return;
      }
    };

    const { disconnect } = {
      ...useMutationObserver({
        querySelector: errorContainersSelector,
        config: {
          childList: true,
        },
        callback,
      }),
    };

    return () => (typeof disconnect === 'function' ? disconnect() : undefined);
  }, [b2cContainer]);

  return <></>;
};

export default ProfileEditPassword;
