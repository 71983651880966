const regex = {
  atLeastOneNumber: /^(?=.*[0-9]).+$/,
  consecutiveNumbersUpToSix:
    /(012|123|234|345|456|567|678|789|0123|1234|2345|3456|4567|5678|6789|7890|01234|12345|23456|34567|45678|56789|67890|0123456|123456|234567|345678|456789|567890|098765|987654|876543|765432|654321|6543210|09876|98765|87654|76543|65432|54321|43210|0987|9876|8765|7654|6543|5432|4321|3210|987|876|765|654|543|432|321|210)+/,
  lowercase: /^(?=.*[a-z]).+$/,
  number: /^[0-9]+$/,
  numberRepeatedThreeTimesInaRow: /(\d)\1{2,}/,
  postcode:
    /^(([gG][iI][rR] ?0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) ?[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/,
  anyValueExceptWhiteSpacesOnly: /^.*\S.*/,
  special: /^(?=.*[@£"&.#$%^*\-_+=[\]{}|\\:',?/`~();!]).+$/,
  uppercase: /^(?=.*[A-Z]).+$/,
  phoneNumber: /^(?:[0-9][\x20-]?){6,14}[0-9]$/,
};

export default regex;
