import { useEffect } from 'react';
import { useB2cContainer } from '.';

export interface HideContinueButtonProps {
  verificationCodeButtonSelector: string;
}

// Hide continue button on landing page
const useHideContinueButton = ({
  verificationCodeButtonSelector,
}: HideContinueButtonProps) => {
  const [b2cContainer] = useB2cContainer();

  useEffect(() => {
    if (!b2cContainer) return;

    const continueButton = b2cContainer.querySelector(
      '#continue'
    ) as HTMLElement;

    if (!continueButton) return;

    const sendVerificationButton = b2cContainer?.querySelector(
      verificationCodeButtonSelector
    );

    if (!sendVerificationButton) return;

    continueButton.setAttribute('aria-hidden', 'true');
    continueButton.style.display = 'none';
  }, [b2cContainer]);
};

export default useHideContinueButton;
